import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getWarehouseCustomerLookup = async (customerId, searchText) => {
  try {
    const response = await httpClient.get(
      `/Warehousecustomer/Lookup/Name/Customer/${customerId}?searchText=${''}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getKitLineItemLookup = async (warehouseCustomerId, searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/Kit/KitLineItem/ItemLookup/${
        warehouseCustomerId ?? 0
      }?searchText=${''}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createKitlineItem = async (payload) => {
  try {
    const response = await httpClient.post(
      `/Item/Kit/KitLineItem/Create`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editKitlineItem = async (payload) => {
  try {
    const response = await httpClient.post(
      `/Item/Kit/KitLineItem/Edit`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteKitlineItem = async (payload) => {
  try {
    const response = await httpClient.post(
      `/Item/Kit/KitLineItem/Delete`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getStatusLookup = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/Status/lookup?searchText=${''}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getInventoryTypes = async () => {
  try {
    const response = await httpClient.get(`/Item/InventoryTypes`);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getClassificationLookup = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/classification/lookup?searchText=${''}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getAllocationMethodTypeLookup = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/AllocationMethod/lookup?searchText=${''}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPrimaryInventoryUOMLookup = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/inventoryUOM/lookup?searchText=${''}`,
    );
    //   T /api/item/allocationMethod/lookup?searchText={searchText
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPackagingUOMLookup = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/packagingUOM/lookup?searchText=${''}`,
    );
    //   T /api/item/allocationMethod/lookup?searchText={searchText
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLicensePlateTypeLookup = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/licensePlateType/lookup?searchText=${''}`,
    );
    //   T /api/item/allocationMethod/lookup?searchText={searchText
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getItemDetail = async (customerId, customerFacilityId, itemId) => {
  try {
    const response = await httpClient.get(
      `Item/Detail/v2/${customerId}/${customerFacilityId}/${itemId}`,
    );
    //   T /api/item/allocationMethod/lookup?searchText={searchText
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createItemDetail = async (payload) => {
  try {
    const response = await httpClient.post(`/Item/Create/v2`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateItemDetail = async (payload) => {
  try {
    const response = await httpClient.post(`/Item/Edit/`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// --------- Import Inventory Item By sheetName
export const createListImportItemDetail = async (
  sheetName,
  customerId,
  payload,
) => {
  try {
    const response = await httpClient.post(
      `/item/import?sheetName=${sheetName}&customerId=${customerId}`,
      payload,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateImportItemDetail = async (
  payload,
  sheetName,
  customerId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.post(
      `/item/JsonImport?sheetName=${sheetName}&customerId=${customerId}&customerFacilityId=${customerFacilityId}`,
      payload,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
