import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { CompleteOrderManuallyModel } from 'common/models/orders/completeordermanually';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import {
  AutoCompleteOptionType,
  CarrierType,
  CartonCodeDDLType,
  useSalesOrderContext,
} from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GetPackageForOrderDDL } from 'services/api/cartoncodes/cartoncodes.api';
import {
  getOrderCarriers,
  getOrderCarrierservices,
} from 'services/api/integrations/shipment/shipment.api';
import { isPackagesInPackAndShipInProgress } from 'services/api/packandship/packandship.api';
import { CompleteOrderManually } from 'services/api/salesorders/salesorders-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Button,
  InputAdornment,
  TextField,
  Grid as MUIGrid,
  CircularProgress,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const palletShipmentOptions = [
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
];
export enum pageEnum {
  OrderPage = 1,
  PackAndShipApprove = 2,
}

interface CompleteOrderProps {
  page: pageEnum;
}

export const CompleteOrderManuallyModal = (param: CompleteOrderProps) => {
  const navigate = useNavigate();

  const { viewLoadData } = useSalesOrderContext();

  const {
    setCompleteOrderManuallyPayload,
    completeOrderManuallyModal: model,
    setIsConfirmPackingRemoveModalOpen,
    setCompleteOrderManuallyModal: setModel,
  } = useContext(GlobalContext);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [optionCarriers, setOptionCarriers] = useState<
    CarrierType[] | null | []
  >([]);
  const [optionCarrierServices, setOptionCarrierServices] = useState<
    CarrierType[] | null | []
  >([]);

  const [optionPackageCartonCodes, setOptionPackageCartonCodes] = useState<
    CartonCodeDDLType[] | null | []
  >([]);

  const onLoadOrderCarriers = async (warehouseCustomerId: number) => {
    try {
      const carriersFromApi = await getOrderCarriers(warehouseCustomerId);
      return carriersFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadPackagesForOrder = async (warehouseCustomerId, carrierId) => {
    try {
      const result = await GetPackageForOrderDDL(
        warehouseCustomerId,
        carrierId,
      );
      return result;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderCarrierServices = async (carrierId: any) => {
    try {
      if (carrierId === 4) {
        setOptionCarrierServices(
          [
            { text: 'Cheapest', value: 'Cheapest' },
            { text: 'Fastest', value: 'Fastest' },
          ].map((c: any) => ({
            label: c.text,
            value: c.text,
            isShippingRate: true,
          })),
        );
      } else if (carrierId === 5) {
        setOptionCarrierServices(
          [
            { text: 'LTL or Truckload', value: 'LTL or Truckload' },
            { text: 'Will Call', value: 'Will Call' },
            { text: 'Pack and Hold', value: 'Pack and Hold' },
          ].map((c: any) => ({
            label: c.text,
            value: c.text,
            isShippingRate: true,
          })),
        );
      } else {
        const carriersFromApi = await getOrderCarrierservices(carrierId);

        setOptionCarrierServices(
          carriersFromApi.map((c: any) => ({
            label: c.text,
            value: c.serviceCode,
            isShippingRate: c.isShippingRate,
            serviceCode: c.serviceCode,
          })),
        );
      }

      return null;
    } catch (err) {
      return err;
    }
  };

  const handleSave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();

    const response = isPackagesInPackAndShipInProgress(model.orderId);
    response.then((res) => {
      const PAYLOAD = {
        orderId: model.orderId,
        isShippingRate: model.carrier?.isShippingRate,
        carrierId: model.carrier?.value,
        carrier: model.carrier?.label,
        carrierService: model.service?.value,
        trackingNo: model.trackingNo,
        shippingCost: model.shippingCost,
        isPalletShipment: model.isPalletShipment.value !== 1,
        width: model.width,
        height: model.height,
        length: model.length,
        weight: model.palletWeight,
        totalWeight: model.totalWeight,
        itemAsPackageId: model.package ? model.package.value : null,
        carrierPredefinedPackage: '',
      } as CompleteOrderManuallyModel;

      if (model?.package && model?.package?.isExternalPackage) {
        PAYLOAD.itemAsPackageId = null;
        PAYLOAD.carrierPredefinedPackage = model?.package?.label;
      } else {
        PAYLOAD.carrierPredefinedPackage = null;
      }

      if (res.data === true) {
        setIsConfirmPackingRemoveModalOpen(true);
        setModel(null);
        setCompleteOrderManuallyPayload(PAYLOAD);
      } else {
        setIsBusy(true);

        CompleteOrderManually(PAYLOAD)
          .then(() => {
            // eslint-disable-next-line react/destructuring-assignment
            if (param.page === pageEnum.OrderPage) {
              viewLoadData();
            } else {
              snackActions.success('Approve successfully.');
              navigate(`/pack-and-ship/find-pick-ticket`);
            }
            setModel(null);
          })
          .catch((err) => {
            snackActions.error(err);
          })
          .finally(() => {
            setIsBusy(false);
          });
      }
    });
  };

  useEffect(() => {
    if (model?.warehousecustomerId !== undefined) {
      const optCarriers = onLoadOrderCarriers(model.warehousecustomerId);
      optCarriers.then((opt) => {
        setOptionCarriers(
          opt.map((c: any) => ({
            label: c.text,
            value: c.value,
            isShippingRate: c.isShippingRate,
          })),
        );
      });
    }
  }, [model?.warehousecustomerId]);

  useEffect(() => {
    if (model?.carrier?.value) {
      onLoadOrderCarrierServices(model.carrier.value);

      if (model?.warehousecustomerId) {
        onLoadPackagesForOrder(
          model?.warehousecustomerId,
          model?.carrier?.isShippingRate === false ? model?.carrier.value : 0,
        ).then((resCarrier) => {
          const optCartonCodesCarrier = resCarrier.map((c: any) => ({
            label: c.text,
            value: c.value,
            dimensions: c.dimensions,
            isExternalPackage: c.isExternalPackage,
            serviceCode: c.serviceCode,
            acceptsVoidFill: c.acceptsVoidFill,
            packageType: c.packageType,
          }));

          setOptionPackageCartonCodes(optCartonCodesCarrier);
        });
      }
    }
  }, [model?.carrier?.value]);

  useEffect(() => {
    if (optionCarrierServices.length > 0 && model?.service) {
      setModel((e) => ({
        ...e,
        service: optionCarrierServices.find(
          (c: CarrierType) =>
            c?.value === model?.service?.value ||
            c?.label === model?.service?.value ||
            c?.serviceCode === model?.service?.value,
        ),
      }));
    }
  }, [optionCarrierServices]);

  return (
    <Modal open={model !== null}>
      <MUIContainer sx={{ maxWidth: '850px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Complete Order Manually
        </Typography>

        <MUIContent sx={{ padding: '10px 20px !important ' }}>
          <Box autoComplete="off" component="form">
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={12}>
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={2.5}>
                    <Autocomplete
                      disableClearable
                      filterOptions={filterOptions}
                      onChange={(event: any, newValue: any) => {
                        if (newValue !== null) {
                          if (newValue.label === 'No') {
                            setModel((prevState) => ({
                              ...prevState,
                              isPalletShipment: newValue,
                              length: null,
                              height: null,
                              width: null,
                              palletWeight: null,
                            }));
                          } else {
                            setModel((prevState) => ({
                              ...prevState,
                              isPalletShipment: newValue,
                            }));
                          }
                        } else {
                          setModel((prevState) => ({
                            ...prevState,
                            isPalletShipment: null,
                          }));
                        }
                      }}
                      id="controllable-states"
                      options={palletShipmentOptions}
                      value={
                        model?.isPalletShipment
                          ? model?.isPalletShipment
                          : { value: 1, label: 'No' }
                      }
                      getOptionLabel={(option: AutoCompleteOptionType) =>
                        option.label
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                          id="outlined"
                          label="Pallet Shipment?"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </MUIGrid>

                  {model?.isPalletShipment?.label === 'No' ? null : (
                    <>
                      <MUIGrid item xs={2.4}>
                        <TextField
                          onChange={(event: any) => {
                            if (event.target.value !== '') {
                              event.preventDefault();
                              const validateDecimal = decimalValidationHelper(
                                event.target.value,
                              );

                              setModel((prev) => ({
                                ...prev,
                                length: Number(validateDecimal),
                              }));
                            } else {
                              setModel((prev) => ({
                                ...prev,
                                length: null,
                              }));
                            }
                          }}
                          value={model?.length || null}
                          id="outlined"
                          label="Length (in)"
                          size="small"
                          type="number"
                          sx={{ width: '100%' }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={2.3}>
                        <TextField
                          onChange={(event: any) => {
                            if (event.target.value !== '') {
                              event.preventDefault();
                              const validateDecimal = decimalValidationHelper(
                                event.target.value,
                              );

                              setModel((prev) => ({
                                ...prev,
                                width: Number(validateDecimal),
                              }));
                            } else {
                              setModel((prev) => ({
                                ...prev,
                                width: null,
                              }));
                            }
                          }}
                          value={model?.width || null}
                          id="outlined"
                          label="Width (in)"
                          size="small"
                          type="number"
                          sx={{ width: '100%' }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={2.3}>
                        <TextField
                          onChange={(event: any) => {
                            if (event.target.value !== '') {
                              event.preventDefault();
                              const validateDecimal = decimalValidationHelper(
                                event.target.value,
                              );

                              setModel((prev) => ({
                                ...prev,
                                height: Number(validateDecimal),
                              }));
                            } else {
                              setModel((prev) => ({
                                ...prev,
                                height: null,
                              }));
                            }
                          }}
                          value={model?.height || null}
                          id="outlined"
                          label="Height (in)"
                          size="small"
                          type="number"
                          sx={{ width: '100%' }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={2.5}>
                        <TextField
                          onChange={(event: any) => {
                            if (event.target.value !== '') {
                              event.preventDefault();
                              const validateDecimal = decimalValidationHelper(
                                event.target.value,
                              );

                              setModel((prev) => ({
                                ...prev,
                                palletWeight: Number(validateDecimal),
                              }));
                            } else {
                              setModel((prev) => ({
                                ...prev,
                                palletWeight: null,
                              }));
                            }
                          }}
                          value={model?.palletWeight || null}
                          id="outlined"
                          label="Weight (lbs)"
                          size="small"
                          type="number"
                          sx={{ width: '100%' }}
                        />
                      </MUIGrid>
                    </>
                  )}
                </MUIGrid>
              </MUIGrid>

              <MUIGrid item xs={4}>
                <Autocomplete
                  sx={{ width: '100%' }}
                  onChange={(event, newValue: CarrierType) => {
                    if (newValue !== null) {
                      setModel((prev) => ({
                        ...prev,
                        carrier: newValue,
                        service: null,
                        package: null,
                      }));
                      onLoadOrderCarrierServices(newValue.value);
                    } else {
                      setModel((prev) => ({
                        ...prev,
                        carrier: null,
                        service: null,
                        package: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionCarriers}
                  getOptionLabel={(option: CarrierType) => option.label}
                  value={model?.carrier}
                  disabled={isBusy}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="carrierType" {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      id="outlined"
                      size="small"
                      sx={{ width: '100%', background: '#ffffff' }}
                      label="Carrier"
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Autocomplete
                  onChange={(event, newValue: CarrierType) => {
                    if (newValue !== null) {
                      setModel((prev) => ({
                        ...prev,
                        service: newValue,
                      }));
                    } else {
                      setModel((prev) => ({
                        ...prev,
                        service: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionCarrierServices}
                  getOptionLabel={(option: CarrierType) => option.label}
                  value={model?.service}
                  disabled={isBusy}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="carrierType" {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      id="outlined"
                      size="small"
                      sx={{ width: '100%', background: '#ffffff' }}
                      label="Service"
                    />
                  )}
                />
              </MUIGrid>

              <MUIGrid item xs={4}>
                <Autocomplete
                  id="grouped-demo"
                  onChange={(event: any, newValue: CartonCodeDDLType) => {
                    if (newValue !== null) {
                      setModel((prev) => ({
                        ...prev,
                        package: newValue,
                      }));
                    } else {
                      setModel((prev) => ({
                        ...prev,
                        package: null,
                      }));
                    }
                  }}
                  getOptionLabel={(option: CartonCodeDDLType) => option.label}
                  value={model?.package}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  options={optionPackageCartonCodes}
                  groupBy={(option) => option.packageType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        endadornment: null,
                      }}
                      autoComplete="off"
                      id="outlined"
                      size="small"
                      sx={{ width: '100%', background: '#ffffff' }}
                      label="Package"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Box display="flex" width="150%">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.label}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {option.dimensions}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  renderGroup={(params) => (
                    <Box key={params.key} display="flex" flexDirection="column">
                      <Typography fontWeight="bold" p={1}>
                        {params.group}
                      </Typography>
                      {params.children}
                    </Box>
                  )}
                />
              </MUIGrid>

              <MUIGrid item xs={12} sm={12} md={12}>
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={4}>
                    <Input
                      placeholder="Tracking No."
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={model?.trackingNo}
                      disabled={isBusy}
                      onChange={(e) => {
                        setModel((prev) => ({
                          ...prev,
                          trackingNo: e.target.value,
                        }));
                      }}
                      type="text"
                      InputProps={{
                        autoComplete: 'off',
                      }}
                      error={model?.trackingNoError}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={4}>
                    <Input
                      placeholder="Shipping Cost"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      type="number"
                      value={model?.shippingCost}
                      disabled={isBusy}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        const validateDecimal = decimalValidationHelper(
                          e.target.value,
                        );

                        setModel((prev) => ({
                          ...prev,
                          shippingCost: Number(validateDecimal),
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      {...(model?.shippingCostError && {
                        error: true,
                        helperText: model?.shippingCostError,
                      })}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={4}>
                    <TextField
                      onChange={(event: any) => {
                        if (event.target.value !== '') {
                          event.preventDefault();
                          const validateDecimal = decimalValidationHelper(
                            event.target.value,
                          );

                          setModel((prev) => ({
                            ...prev,
                            totalWeight: Number(validateDecimal),
                          }));
                        } else {
                          setModel((prev) => ({
                            ...prev,
                            totalWeight: null,
                          }));
                        }
                      }}
                      value={model?.totalWeight || null}
                      id="outlined"
                      label="Total Weight (lbs)"
                      size="small"
                      type="number"
                      sx={{ width: '100%' }}
                      disabled={isBusy}
                    />
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={isBusy ? 6 : 4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                disabled={isBusy}
                onClick={() => {
                  setModel(null);
                  setIsBusy(false);
                }}
              >
                CANCEL
              </Button>

              {!isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleSave(e)}
                >
                  SAVE
                </Button>
              )}
              {isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Saving</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
