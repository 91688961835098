/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { UserFilterValueTypeEnum } from 'common/enums/UserFilterValueTypeEnum';
import Card from 'components/card';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import Input from 'components/input';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import moment from 'moment';
import InventoryErrorMessageModal from 'pages/inventory/item/inventory-error-message-modal';
import { SinglePrintLicensePlate } from 'pages/licenseplates/lp-print';
import FileInputModal from 'pages/shared/addfilemodal';
import AdvanceSearchFilterModal from 'pages/shared/advancesearchfiltermodal';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import CustomerItemModal from 'pages/shared/customeritemmodal';
import EditExpirationDateModal from 'pages/shared/editexpirationdatemodal';
import EditItemThresholdModal from 'pages/shared/edititemthreshold';
import ImportInventoryModal from 'pages/shared/importinventorymodal';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { getCustomerLocationsById } from 'services/api/customerlocations/customerlocations.api';
import { getItemInventoryInfo } from 'services/api/item/item.api';
import { getLicensePlateToPrint } from 'services/api/licensePlates/licensePlates-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { ContainerFlat, DatagridPremium } from 'styles';

import { Search } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';
import {
  Chip,
  Tooltip,
  Typography,
  TextField,
  Grid as MUIGrid,
  Box,
  Autocomplete,
  Link,
  Switch,
  Button,
  InputAdornment,
  Divider,
  Fade,
  Checkbox,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  GridInitialState,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiRef,
  GridActionsCellItem,
  GridValidRowModel,
  GridColumns,
  GridColumnsInitialState,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

import { MainContainer, ContentContainer } from '../styles';
import {
  useInventoryListPageContext,
  InventoryListPageContextProvider,
} from './context';

export const InternalToggleButton = styled(ToggleButton)({
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: 'rgb(51, 117, 224)',
  },
  '&.Mui-selected:hover': {
    color: 'white',
    backgroundColor: 'rgb(51, 117, 224)',
  },
  '&.MuiToggleButton-root, &.right-side, &.Mui-selected': {
    borderTopRightRadius: '20px',
    width: '100px',
    padding: '0 60px',
    borderBottomRightRadius: '20px',
  },
  '&.MuiToggleButton-root, &.left-side, &.Mui-selected': {
    borderRadius: '20px',
    width: '100px',
    padding: '0 60px',
  },
});

type Flag = {
  isKit: boolean;
  isComponent: boolean;
};

interface AutoCompleteOptionType {
  value?: number;
  label?: string;
}

const KitSmallChip = ({ isKit, isComponent }: Flag) => (
  <>
    {isKit && (
      <Tooltip title="Kit" placement="right">
        <Chip
          label="K"
          size="small"
          sx={{
            marginLeft: 1,
            fontWeight: 'bold',
            width: 30,
            color: 'white',
            height: 20,
          }}
          style={{ backgroundColor: '#FFA500' }}
        />
      </Tooltip>
    )}
    {isComponent && (
      <Tooltip title="Component" placement="right">
        <Chip
          label="C"
          size="small"
          sx={{
            marginLeft: 1,
            fontWeight: 'bold',
            width: 30,
            color: 'white',
            height: 20,
          }}
          style={{ backgroundColor: '#FFA500' }}
        />
      </Tooltip>
    )}
  </>
);

const InventoryFilterSearchPanel = () => {
  const {
    onOpenAdvancedSearchInventoryModal,
    setIsDeleteFilterModalOpen,
    setFilterValueEnum,
  } = useContext(GlobalContext);

  const {
    filterSearchList,
    customerInventory,
    filterSearchOptions,
    optionCustomerSupplier,
    setInventoryFilterListLocal,
    advanceSearchValueInventory,
    initialFormFilterSearchValue,
    setViewByItemChecked,
    setFilteredInventory,
    OnClickUserFilterSearchApply,
    setAdvanceSearchValueInventory,
  } = useInventoryListPageContext();

  const [checked, setChecked] = React.useState(false);
  const [searchFieldValue, setSearchFieldValue] = React.useState('');

  const handleSearchFieldChange = (newValue) => {
    const filteredList = customerInventory.filter(
      (x) =>
        x.itemSKU.toLowerCase().includes(newValue.toLowerCase()) ||
        x.description.toLowerCase().includes(newValue.toLowerCase()),
    );
    setFilteredInventory(filteredList);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const clearSearchValues = () => {
    const clearItem = initialFormFilterSearchValue;
    setAdvanceSearchValueInventory(clearItem);
  };

  const onLoadFilterSearchValueByOptionValue = async (id: number) => {
    const getItem = [...filterSearchList];
    const newValue = getItem.find((r) => r.userFilterSearchValueId === id);

    setAdvanceSearchValueInventory((prev) => ({
      ...prev,
      userFilterSearchValueId: newValue.userFilterSearchValueId,
      skuOrDescription:
        newValue.userFilterSearhValueInventoryModelData.skuOrDescription,
      customerName:
        newValue.userFilterSearhValueInventoryModelData.customerName,
      pointOfContactName:
        newValue.userFilterSearhValueInventoryModelData.pointOfContactName,
      receiptDate: newValue.userFilterSearhValueInventoryModelData.receiptDate
        ? moment(
            newValue.userFilterSearhValueInventoryModelData.receiptDate,
          ).format('MM/DD/YY')
        : null,
      lotNo: newValue.userFilterSearhValueInventoryModelData.lotNo,
      location: newValue.userFilterSearhValueInventoryModelData.location,
      isKit: newValue.userFilterSearhValueInventoryModelData.isKit,
      isComponent: newValue.userFilterSearhValueInventoryModelData.isComponent,
      isShowZeroInventory:
        newValue.userFilterSearhValueInventoryModelData.isShowZeroInventory,
      supplierId: newValue.supplierName ? newValue.supplierName.value : null,
      supplierName: newValue.supplierName ? newValue.supplierName.label : null,
    }));

    setInventoryFilterListLocal(newValue.userFilterSearchValueId);
  };
  useEffect(() => {
    if (advanceSearchValueInventory.userFilterSearchValueId) {
      OnClickUserFilterSearchApply(null, true);
    }
  }, [advanceSearchValueInventory.userFilterSearchValueId]);

  const [gridView, setGridView] = useState('item');

  const handleGridViewChange = (event, newValue) => {
    setGridView(newValue);
    setViewByItemChecked(newValue === 'item');
  };

  return (
    <div style={{ width: '100%', padding: '10px' }}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Fade in={checked}>
          <Box
            sx={{
              position: 'fixed',
              right: '1px',
              width: '30%',
              height: '88.3vh !important',
              bgcolor: 'rgb(255 255 255)',
              padding: '10px',
              bottom: '0',
              zIndex: '100',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              overflowY: 'scroll',
            }}
          >
            {/* HEADER -------------------------------------------------- */}
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '20px !important',
              }}
            >
              <MUIGrid item xs={6}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ float: 'left' }}
                >
                  Advanced Search
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6} sx={{ paddingTop: '8px !important' }}>
                <Autocomplete
                  options={filterSearchOptions}
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    option.label
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  value={
                    advanceSearchValueInventory.filterSearchParameter || null
                  }
                  onChange={(
                    e: any,
                    newValue: AutoCompleteOptionType | null,
                  ) => {
                    if (newValue === null) {
                      setAdvanceSearchValueInventory((prev) => ({
                        ...prev,
                        filterSearchParameter: null,
                        setFilterName: null,
                        userFilterSearchValueId: null,
                      }));
                      setInventoryFilterListLocal(null);
                    } else {
                      setAdvanceSearchValueInventory((prev) => ({
                        ...prev,
                        filterSearchParameter: {
                          value: newValue.value,
                          label: newValue.label,
                        },
                        userFilterSearchValueId: newValue.value,
                        setFilterName: newValue.label,
                      }));
                      onLoadFilterSearchValueByOptionValue(newValue.value);
                    }
                  }}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                        placeholder="Filters"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <FilterListIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{ boxShadow: 'none !important' }}
                        fullWidth
                        size="small"
                      />
                    </div>
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider />
            {/* INVENTORY ------------------------------------------------- */}

            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ padding: '20px', paddingBottom: '0px' }}
            >
              <MUIGrid item>
                <Typography variant="body1" fontWeight="bold">
                  Inventory
                </Typography>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '20px !important',
              }}
            >
              <MUIGrid item xs={6}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Item Name"
                  value={advanceSearchValueInventory.skuOrDescription}
                  onChange={(e) => {
                    setAdvanceSearchValueInventory((prev) => ({
                      ...prev,
                      skuOrDescription: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <DatePickerInput
                  label="Receipt Date"
                  inputFormat="MM/dd/yyyy"
                  value={advanceSearchValueInventory.receiptDate}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAdvanceSearchValueInventory((prevState) => ({
                        ...prevState,
                        receiptDate: e,
                      }));
                    } else {
                      setAdvanceSearchValueInventory((prevState) => ({
                        ...prevState,
                        receiptDate: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={false} size="small" />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Lot #"
                  value={advanceSearchValueInventory.lotNo}
                  onChange={(e) => {
                    setAdvanceSearchValueInventory((prev) => ({
                      ...prev,
                      lotNo: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Location"
                  value={advanceSearchValueInventory.location}
                  onChange={(e) => {
                    setAdvanceSearchValueInventory((prev) => ({
                      ...prev,
                      location: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Checkbox
                  color="primary"
                  size="small"
                  checked={advanceSearchValueInventory.isKit || false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdvanceSearchValueInventory((prev) => ({
                      ...prev,
                      isKit: event.target.checked,
                    }));
                  }}
                />
                Is Kit?
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Checkbox
                  color="primary"
                  size="small"
                  checked={advanceSearchValueInventory.isComponent || false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdvanceSearchValueInventory((prev) => ({
                      ...prev,
                      isComponent: event.target.checked,
                    }));
                  }}
                />
                Is Component?
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Switch
                  checked={advanceSearchValueInventory.isShowZeroInventory}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdvanceSearchValueInventory((prev) => ({
                      ...prev,
                      isShowZeroInventory: event.target.checked,
                    }));
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                Show Zero Inventory
              </MUIGrid>
            </MUIGrid>
            <Divider />
            {/* CUSTOMER ------------------------------------------------- */}
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ padding: '20px', paddingBottom: '0px' }}
            >
              <MUIGrid item>
                <Typography variant="body1" fontWeight="bold">
                  Client
                </Typography>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '20px !important',
              }}
            >
              <MUIGrid item xs={6}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Client"
                  value={advanceSearchValueInventory.customerName}
                  onChange={(e) => {
                    setAdvanceSearchValueInventory((prev) => ({
                      ...prev,
                      customerName: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Point Of Contact Name"
                  value={advanceSearchValueInventory.pointOfContactName}
                  onChange={(e) => {
                    setAdvanceSearchValueInventory((prev) => ({
                      ...prev,
                      pointOfContactName: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
            </MUIGrid>
            <Divider />
            {/* SUPPLIERS ------------------------------------------------- */}
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ padding: '20px', paddingBottom: '0px' }}
            >
              <MUIGrid item>
                <Typography variant="body1" fontWeight="bold">
                  Supplier
                </Typography>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '20px !important',
              }}
            >
              <MUIGrid item xs={12}>
                <Autocomplete
                  options={optionCustomerSupplier}
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    option.label
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  value={advanceSearchValueInventory.supplierName || null}
                  onChange={(
                    event: any,
                    newValue: AutoCompleteOptionType | null,
                  ) => {
                    if (newValue === null) {
                      setAdvanceSearchValueInventory((prev) => ({
                        ...prev,
                        supplierName: null,
                      }));
                    } else {
                      setAdvanceSearchValueInventory((prev) => ({
                        ...prev,
                        supplierName: {
                          value: newValue.value,
                          label: newValue.label,
                        },
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                        label="Supplier Name"
                        variant="outlined"
                        sx={{ boxShadow: 'none !important' }}
                        fullWidth
                        size="small"
                      />
                    </div>
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            {/* BUTTONS ------------------------------------------------- */}
            <Box
              sx={{
                position: 'fixed',
                right: '1px',
                width: '30%',
                height: '8vh !important',
                bgcolor: 'rgb(255 255 255)',
                padding: '10px',
                bottom: '0',
                zIndex: '100',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                display: 'flex',
                alignContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      marginRight: '10px',
                      boxShadow: 'none !important',
                    }}
                    onClick={(e) => {
                      handleChange();
                    }}
                  >
                    Close
                  </Button>

                  <Button
                    variant="contained"
                    size="small"
                    className="buttonFloatRight"
                    onClick={(e) => {
                      OnClickUserFilterSearchApply(e, true);
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    className="buttonFloatRight"
                    onClick={() => onOpenAdvancedSearchInventoryModal()}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className="buttonFloatRight"
                    onClick={(e) => {
                      clearSearchValues();
                      setInventoryFilterListLocal(null);
                      OnClickUserFilterSearchApply(e);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className="buttonFloatRight"
                    sx={{ borderColor: '#ff6969', color: '#ff6969' }}
                    onClick={() => {
                      setFilterValueEnum(UserFilterValueTypeEnum.Inventory);
                      setIsDeleteFilterModalOpen(true);
                    }}
                    disabled={
                      !advanceSearchValueInventory?.userFilterSearchValueId
                    }
                  >
                    Delete
                  </Button>
                </MUIGrid>
              </MUIGrid>
            </Box>
          </Box>
        </Fade>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ justifyContent: 'right' }}
        >
          <MUIGrid
            item
            sx={{
              marginRight: '0',
            }}
          >
            <ToggleButtonGroup
              value={gridView}
              exclusive
              onChange={(e, newValue) => {
                handleGridViewChange(e, newValue);
              }}
              aria-label="View"
              color="primary"
              size="small"
              sx={{
                float: 'right',
                height: '40px',
              }}
            >
              <Typography sx={{ padding: '10px', color: 'rgb(51, 117, 224)' }}>
                VIEW BY
              </Typography>
              <InternalToggleButton
                value="item"
                aria-label="left"
                sx={{ width: 100 }}
                className="left-side"
              >
                Item
              </InternalToggleButton>
              <InternalToggleButton
                value="location"
                aria-label="left"
                sx={{ width: 100 }}
                className="right-side"
              >
                Location
              </InternalToggleButton>
            </ToggleButtonGroup>
          </MUIGrid>
          <MUIGrid item>
            <Input
              sx={{ maxWidth: '100%' }}
              placeholder="Search"
              value={searchFieldValue}
              onChange={(e) => setSearchFieldValue(e.target.value)}
              rightIcon={<Search />}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearchFieldChange(e.target.value);
                }
              }}
            />
          </MUIGrid>
          <MUIGrid item xs={0.5} sx={{ paddingLeft: '20px !important' }}>
            <IconButton
              color="primary"
              sx={{ padding: '0px' }}
              onClick={() => {
                handleChange();
              }}
            >
              <ManageSearchIcon
                sx={{
                  width: '42px',
                  height: '1%',
                  margin: '-2px',
                  color: 'rgb(51, 117, 224)',
                }}
              />
            </IconButton>
          </MUIGrid>
        </MUIGrid>
      </Box>
      <AdvanceSearchFilterModal />
    </div>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          flex: '1',
          width: '100% !important',
        }}
      >
        <InventoryFilterSearchPanel />
      </Box>
    </GridToolbarContainer>
  );
}

function SaveAndRestoreStateInitialState() {
  const theme = useTheme();
  const {
    loading,
    updateData,
    updateLoading,
    onOpenConfirmDeleteDialog,
    onOpenPrintFormTemplateModal,
    onCloseInputFileInventoryModal,
    onCloseUpdateInputFileInventoryModal,
  } = useContext(GlobalContext);
  const {
    currentUser,
    isInventoryViewer,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
    roleWarehouseCustomerPermission,
  } = useContext(AuthContext);

  const {
    tilesInventory,
    customerInventory,
    filteredInventory,
    viewByItemChecked,
    advanceSearchValueInventory,
    setTilesInventory,
    setFilteredInventory,
    onLoadCustomerInventory,
    OnClickUserFilterSearchApply,
  } = useInventoryListPageContext();

  const [searchParams, setSearchParams] = useState({
    searchSKU: '',
    searchCustomer: '',
    searchUOM: '',
    locationName: '',
    searchQty: '',
    searchTotalExpected: '',
    searchDesc: '',
    searchLicensePlate: '',
    receiptDate: '',
    lotNo: '',
    expirationDate: '',
  });
  const componentSinglePrintRef = useRef(null);
  const buttonSinglePrintRef = useRef(null);

  const [customerLocations, setCustomerLocations] = useState([]);
  const [dataSelected, setDataSelected] = useState({
    customerId: '',
    sku: '',
    description: '',
    uom: '',
  });
  const onLoadCustomerLocations = async () => {
    try {
      const customerLocationsFromApi = await getCustomerLocationsById(
        currentUser.Claim_CustomerId,
      );

      setCustomerLocations(customerLocationsFromApi);
      return true;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    updateLoading(true);
    setDataSelected({
      customerId: '',
      sku: '',
      description: '',
      uom: '',
    });
    onLoadCustomerLocations();
  }, [loading, updateData]);

  const handleDeleteItem = (rowData) => {
    setDataSelected(rowData);
    onOpenConfirmDeleteDialog();
  };

  // type InventoryRow = typeof customerInventory[number];

  const mapInventoryTitleAndComponent = async (data) => {
    onLoadCustomerInventory();
    onCloseInputFileInventoryModal();
    onCloseUpdateInputFileInventoryModal();
  };

  type ImportedTitleAndComponent = {
    primaryInventoryUOM: number;
    useSecondaryInventoryUOM: boolean;
    status: number;
    description: string;
    sku: string;
  };

  const provideCsvTemplate = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const obj: ImportedTitleAndComponent = {
      primaryInventoryUOM: 0,
      useSecondaryInventoryUOM: false,
      status: 0,
      description: '',
      sku: '',
    };

    const rows = Object.getOwnPropertyNames(obj);

    const csvContent = `data:text/csv;charset=utf-8,${rows}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'inventory_template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const provideCsvTemplateFoorUpdateInventory = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const rows = [
      'TYPE (PALLET/EACH)',
      'SKU',
      'UOM',
      'AREA',
      'LOCATION',
      'QTY',
      'LOT EXPIRATION',
      'REF#',
      'RECEIVED',
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'inventory_template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const inventoryImportHandler = () => {
    OnClickUserFilterSearchApply(null, true);
  };

  const handleOnClickSinglePrintTrigger = useReactToPrint({
    content: () => componentSinglePrintRef.current,
  });

  const [printOfLPItem, setPrintOfLPItem] = useState<any>('');
  const [lpIdToPrint, setLPIdToPrint] = useState(null);

  const handleOnClickPrint = async (e: any, id: any) => {
    e.preventDefault();
    try {
      setLPIdToPrint(id);
      onOpenPrintFormTemplateModal();
    } catch (err) {
      console.log(err);
    }
  };

  const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const getInventoryColumns = (): GridColumns<GridValidRowModel> => {
    const inventoryColumns: GridColumns = [
      {
        field: 'actions',
        type: 'actions',
        hideable: false,
        hide: isInventoryViewer || isWarehouseCustomerAccount,
        width: 34,
        // eslint-disable-next-line react/no-unstable-nested-components
        getActions: (params) => [
          <GridActionsCellItem
            label="Delete Item"
            disabled={params.row.qtyOnHand !== 0 || isWarehouseCustomerAccount}
            onClick={() => handleDeleteItem(params.row)}
            showInMenu
          />,
        ],
      },
      {
        field: 'itemSKU',
        width: 200,
        headerName: 'Item SKU',
        renderCell: (params) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {!isWarehouseCustomerAccount && (
              <Link
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: '#303538',
                  textDecoration: 'none',
                }}
                href={
                  isInventoryViewer
                    ? `/customer-inventory/item/${params.row.itemSKU}`
                    : `/inventory/item-details?form=view&id=${params.row.itemId}`
                }
              >
                <Typography
                  sx={{
                    cursor: 'pointer',
                    color: [theme.palette.primary.main],
                  }}
                >
                  {params.row.itemSKU}
                </Typography>
              </Link>
            )}
            {isWarehouseCustomerAccount &&
              roleWarehouseCustomerPermission.Inventory &&
              roleWarehouseCustomerPermission.InventoryDetail && (
                <Link
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#303538',
                    textDecoration: 'none',
                  }}
                  href={`/warehouse-customer-inventory/item-details?form=view&id=${params.row.itemId}`}
                >
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: [theme.palette.primary.main],
                    }}
                  >
                    {params.row.itemSKU}
                  </Typography>
                </Link>
              )}
            {isWarehouseCustomerAccount &&
              roleWarehouseCustomerPermission.Inventory &&
              !roleWarehouseCustomerPermission.InventoryDetail && (
                <Typography>{params.row.itemSKU}</Typography>
              )}

            <KitSmallChip
              isKit={params.row.isKit}
              isComponent={params.row.isComponent}
            />
          </Box>
        ),
      },
      {
        field: 'locationName',
        width: 200,
        headerName: 'Location',
        renderCell: (params) => (
          <Typography>{params.row.locationName}</Typography>
        ),
      },
      {
        field: 'receiptDate',
        width: 200,
        headerName: 'Receipt Date',
        type: 'date',
        renderCell: (params) => (
          <Typography>
            {params.row.receiptDate != null
              ? moment(params.row.receiptDate).format('MM/DD/YYYY')
              : ''}
          </Typography>
        ),
      },
      {
        field: 'licensePlate',
        width: 200,
        headerName: 'License Plate',
        renderCell: (params) => (
          <Button
            onClick={(e) => handleOnClickPrint(e, params.row.licensePlateId)}
            variant="text"
            sx={{ textTransform: 'none' }}
          >
            <Typography>{params.row.licensePlate}</Typography>
          </Button>
        ),
      },
      {
        field: 'purchaseOrderNo',
        width: 200,
        headerName: 'PO No',
        hide: viewByItemChecked === null || viewByItemChecked,
        renderCell: (params) => (
          <Typography>{params.row.purchaseOrderNo}</Typography>
        ),
      },
      {
        field: 'qtyOnHand',
        width: 200,
        headerName: 'Qty On Hand',
        renderCell: (params) => (
          <Typography>{params.row.qtyOnHand?.toLocaleString()}</Typography>
        ),
      },
      {
        field: 'allocated',
        width: 200,
        headerName: 'Allocated',
        renderCell: (params) => (
          <Typography>{numberWithCommas(params.row.allocated)}</Typography>
        ),
      },
      {
        field: 'onHold',
        width: 200,
        headerName: 'On Hold',
        renderCell: (params) => (
          <Typography>{numberWithCommas(params.row.onHold)}</Typography>
        ),
      },
      {
        field: 'available',
        width: 200,
        headerName: 'Available',
        renderCell: (params) => (
          <Typography>{numberWithCommas(params.row.available)}</Typography>
        ),
      },
      {
        field: 'qtyPallets',
        width: 200,
        headerName: 'Qty Pallets',
        renderCell: (params) => (
          <Typography>{numberWithCommas(params.row.qtyPallets)}</Typography>
        ),
      },
      {
        field: 'totalExpected',
        width: 200,
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        headerName: 'Expected Qty',
        renderCell: (params) => (
          <Typography>{params.row.totalExpected?.toLocaleString()}</Typography>
        ),
      },
      {
        field: 'itemUOM',
        width: 200,
        headerName: 'Primary UOM',
        renderCell: (params) => <Typography>{params.row.itemUOM}</Typography>,
      },
      {
        field: 'lotNo',
        width: 200,
        headerName: 'Lot No',
        renderCell: (params) => <Typography>{params.row.lotNo}</Typography>,
      },
      {
        field: 'expirationDate',
        width: 200,
        headerName: 'Expiration Date',
        type: 'date',
        renderCell: (params) => (
          <Typography>
            {params.row.expirationDate != null
              ? moment(params.row.expirationDate).format('MM/DD/YYYY')
              : ''}
          </Typography>
        ),
      },
      {
        field: 'description',
        width: 200,
        headerName: 'Description',
        renderCell: (params) => (
          <Typography>{params.row.description}</Typography>
        ),
      },
      {
        field: 'customer',
        width: 200,
        hide: isWarehouseCustomerAccount,
        headerName: 'Client',
        renderCell: (params) => <Typography>{params.row.customer}</Typography>,
      },
    ];
    if (viewByItemChecked === null || viewByItemChecked) {
      inventoryColumns.splice(
        inventoryColumns.findIndex((x) => x.field === 'locationName'),
        1,
      );

      inventoryColumns.splice(
        inventoryColumns.findIndex((x) => x.field === 'licensePlate'),
        1,
      );
    } else {
      inventoryColumns.splice(
        inventoryColumns.findIndex((x) => x.field === 'allocated'),
        1,
      );

      inventoryColumns.splice(
        inventoryColumns.findIndex((x) => x.field === 'available'),
        1,
      );
      inventoryColumns.splice(
        inventoryColumns.findIndex((x) => x.field === 'onHold'),
        1,
      );
      inventoryColumns.splice(
        inventoryColumns.findIndex((x) => x.field === 'qtyPallets'),
        1,
      );
    }

    return inventoryColumns;
  };

  const onLoadTopTiles = async (
    showZeroInventoryParam: boolean,
    warehouseCustomer: string,
  ) => {
    try {
      const customerInventoryFromApi = await getItemInventoryInfo({
        customerId: currentUser.Claim_CustomerId,
        customerFacilityId: currentLocationAndFacility.customerFacilityId,
        showZeroInventory: showZeroInventoryParam,
        warehouseCustomer,
      });
      setTilesInventory((e) => ({
        ...e,
        totalPallets: customerInventoryFromApi.totalPallets,
        totalUniqueSkus: customerInventoryFromApi.totalUniqueSkus,
        totalCustomers: customerInventoryFromApi.totalCustomers,
        totalKits: customerInventoryFromApi.totalKits,
      }));

      return true;
    } catch (error) {
      return error;
    }
  };

  const filterArray = (gridValue, filterValue) => {
    const search = gridValue !== undefined ? gridValue : '';

    return search.toLowerCase().includes(filterValue.toLowerCase());
  };

  const filterInventory = () => {
    const searchSKUResult = customerInventory.filter((c) =>
      filterArray(c.itemSKU, searchParams.searchSKU),
    );

    const searchLicensePlateResult = searchSKUResult.filter((c) =>
      filterArray(c.customer, searchParams.searchCustomer),
    );

    const searchUOMResult = searchLicensePlateResult.filter((c) =>
      filterArray(c.itemUOM, searchParams.searchUOM),
    );

    const searchBinLocationsResult = searchUOMResult.filter((c) =>
      filterArray(c.locationName, searchParams.locationName),
    );

    const searchQtyResult = searchBinLocationsResult.filter((c) =>
      filterArray(c.qtyOnHand, searchParams.searchQty),
    );

    const searchTotalExpectedResult = searchQtyResult.filter((c) =>
      filterArray(c.totalExpected, searchParams.searchTotalExpected),
    );

    const searchDescResult = searchTotalExpectedResult.filter((c) =>
      filterArray(c.description, searchParams.searchDesc),
    );

    const searchReceiptDate = searchDescResult.filter((c) =>
      moment(c.receiptDate)
        .format('MM/DD/YYYY')
        .includes(searchParams.receiptDate),
    );
    const searchExpirationDate = searchReceiptDate.filter((c) =>
      moment(c.expirationDate)
        .format('MM/DD/YYYY')
        .includes(searchParams.expirationDate),
    );

    const searchLotNo = searchExpirationDate.filter((c) =>
      filterArray(c.lotNo, searchParams.lotNo),
    );

    const finalResult = searchLotNo.filter((c) =>
      filterArray(c.licensePlate, searchParams.searchLicensePlate),
    );

    setFilteredInventory(finalResult);
  };

  useEffect(() => {
    if (customerInventory) {
      filterInventory();

      if (
        searchParams.searchCustomer !== '' ||
        searchParams.searchCustomer !== null
      ) {
        onLoadTopTiles(
          advanceSearchValueInventory.isShowZeroInventory,
          searchParams.searchCustomer,
        );
      }
    }
  }, [searchParams]);

  // inventory state -----------------------------------------------
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] =
    React.useState<GridInitialStatePremium>();

  const [savedState, setSavedState] = React.useState<{
    initialState: GridInitialStatePremium;
  }>({ initialState: {} });

  // COLUMN MODEL
  const [savedColumnsState, setSavedColumnsState] = React.useState<{
    columnVisibility: GridColumnVisibilityModel;
  }>({ columnVisibility: {} });

  const saveSnapshot = React.useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem(
        'inventoryDataGridState',
        JSON.stringify(currentState),
      );
    }
  }, []);

  React.useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem(
      'inventoryDataGridState',
    );
    setInitialState(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {},
    );

    // COLUMN VISIBILITY
    const columnStateFromLocalStorage = localStorage?.getItem(
      'inventoryDataGridStateColumnVisibility',
    );
    setSavedColumnsState((prev) => ({
      ...prev,
      columnVisibility: columnStateFromLocalStorage
        ? JSON.parse(columnStateFromLocalStorage)
        : {},
    }));

    setSavedState((prev) => ({
      ...prev,
      initialState: JSON.parse(stateFromLocalStorage),
    }));
  }, [currentUser.Claim_CustomerId]);

  useEffect(() => {
    if (savedState?.initialState !== null && apiRef?.current?.restoreState) {
      apiRef?.current?.restoreState(savedState?.initialState);
      apiRef.current.setColumnVisibilityModel(
        savedColumnsState.columnVisibility,
      );
    }
  }, [getInventoryColumns]);

  if (!initialState) {
    return (
      <div style={{ textAlign: 'center', paddingTop: '20%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {lpIdToPrint !== null && lpIdToPrint > 0 && (
        <Printformtemplate
          preview={false}
          dynamicId={lpIdToPrint}
          formtemplateTypeId={FormTemplateTypeEnum.PalletLabels}
          forPalletLabelsIsUseLicense
        />
      )}
      <div style={{ display: 'none' }}>
        <FileInputModal
          callBack={mapInventoryTitleAndComponent}
          csvTemplate={provideCsvTemplate}
          csvTemplateForUpdate={provideCsvTemplateFoorUpdateInventory}
        />

        <EditItemThresholdModal item={dataSelected} />
        <EditExpirationDateModal item={dataSelected} />
        <ImportInventoryModal />
        <InventoryErrorMessageModal />
        <CustomerItemModal item={dataSelected} />
        <ConfirmationDialog itemData={dataSelected} deleteInventoryItem />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            marginBottom: '16px',
          }}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '192px',
            }}
          >
            <Typography
              sx={{ color: [theme.palette.primary.main] }}
              variant="h3"
            >
              {tilesInventory && tilesInventory.totalPallets}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Total Pallets
            </Typography>
          </Card>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '192px',
            }}
          >
            <Typography
              sx={{ color: [theme.palette.primary.main] }}
              variant="h3"
            >
              {tilesInventory && tilesInventory.totalUniqueSkus}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Total SKUs
            </Typography>
          </Card>
          {tilesInventory && tilesInventory.totalCustomers > 0 && (
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '192px',
              }}
            >
              <Typography
                sx={{ color: [theme.palette.primary.main] }}
                variant="h3"
              >
                {tilesInventory.totalCustomers}
              </Typography>
              <Typography variant="caption" fontWeight="bold">
                Total Clients
              </Typography>
            </Card>
          )}
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '192px',
            }}
          >
            <Typography
              sx={{ color: [theme.palette.primary.main] }}
              variant="h3"
            >
              {tilesInventory && tilesInventory.totalKits}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Total Kits
            </Typography>
          </Card>
        </Box>
        <SinglePrintLicensePlate
          ref={componentSinglePrintRef}
          data={printOfLPItem}
        />
        <button
          type="button"
          ref={buttonSinglePrintRef}
          onClick={handleOnClickSinglePrintTrigger}
        >
          This one is trigger on single print receipt license plate item
        </button>
      </div>
      <ContainerFlat>
        <DatagridPremium
          autoHeight
          disableColumnMenu
          rows={filteredInventory}
          columns={getInventoryColumns()}
          apiRef={apiRef}
          hideFooterSelectedRowCount
          getRowId={(row) => row.identifier}
          rowGroupingColumnMode="single"
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          onColumnOrderChange={() => {
            saveSnapshot();
          }}
          onColumnWidthChange={() => {
            saveSnapshot();
          }}
          onColumnVisibilityModelChange={(e) => {
            localStorage.setItem(
              'inventoryDataGridStateColumnVisibility',
              JSON.stringify(e),
            );
          }}
          initialState={{ pagination: { pageSize: 100 } }}
          rowsPerPageOptions={[100, 250, 500]}
          pagination
        />
      </ContainerFlat>
    </>
  );
}

function InventoriesView() {
  return (
    <MainContainer>
      <InventoryListPageContextProvider>
        <Header />
        <ContentContainer>
          <SaveAndRestoreStateInitialState />
        </ContentContainer>
      </InventoryListPageContextProvider>
    </MainContainer>
  );
}

export default React.memo(InventoriesView);
