import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { OrderVoidTypeEnum } from 'common/enums';
import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Typography, Link, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';

import { useSalesOrderContext, OrderStatus } from '../context';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

type FlagStatusToEnableRefreshInventory =
  | OrderStatus.New
  | OrderStatus.Open
  | OrderStatus.Partial
  | OrderStatus.ReadyForPickup;

type FlagStatusToEnableCreatePickTicket =
  | OrderStatus.New
  | OrderStatus.Open
  | OrderStatus.Shipment
  | OrderStatus.Partial
  | OrderStatus.ReadyForPickup;

const OrderStatusDropdown = () => {
  const navigate = useNavigate();

  const {
    pageViewer,
    salesOrderForm,
    salesOrderFlagStatus,
    queryStringItemOrders,
    setTemporaryOrderId,
    changeStatusSalesOrderOnClick,
    createOrderPickTicketNewOnClick,
    refreshSalesOrderInventoryOnClick,
    setQueryStringItemOrders,
    setVoidType,
    setIsVoidThePackModal,
  } = useSalesOrderContext();

  const {
    isWarehouseCustomerAccount,
    isSupplierAccount,
    roleWarehouseCustomerPermission,
    handleUserPermissionAllow,
  } = useContext(AuthContext);

  const { setIsCreateReturnOverrideModal, setIsOrderCompleteModal } =
    useContext(GlobalContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Void
  const [voidAnchorEl, setVoidAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const voidOpen = Boolean(voidAnchorEl);
  const voidHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setVoidAnchorEl(event.currentTarget);
  };
  const voidHandleClose = () => {
    setVoidAnchorEl(null);
  };

  // //Enable Refresh Inventory Button
  const orderStatuses: FlagStatusToEnableRefreshInventory[] = [
    OrderStatus.New,
    OrderStatus.Open,
    OrderStatus.Partial,
    OrderStatus.ReadyForPickup,
  ];

  const orderStatusesCreatePickTicket: FlagStatusToEnableCreatePickTicket[] = [
    OrderStatus.New,
    OrderStatus.Open,
    OrderStatus.Shipment,
    OrderStatus.Partial,
    OrderStatus.ReadyForPickup,
  ];

  return (
    pageViewer.isViewOrEdit() && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!isWarehouseCustomerAccount &&
          salesOrderForm?.isReturn !== true &&
          salesOrderForm.createReturnButtonVisible &&
          salesOrderFlagStatus.status === OrderStatus.Complete &&
          handleUserPermissionAllow(UserPermissionEnum.Returns_AddReturns) && (
            <Button
              className="headerbuttonSecondary"
              variant="outlined"
              size="small"
              onClick={() => {
                if (salesOrderForm.returnOverrideMaximumReturnDays) {
                  setIsCreateReturnOverrideModal(true);
                } else {
                  setTemporaryOrderId(queryStringItemOrders.id);
                  setQueryStringItemOrders((prevState) => ({
                    ...prevState,
                    form: 'new',
                    id: null,
                    isReturn: true,
                  }));
                  navigate('/orders/sales-orders?form=new&isReturn=true');
                }
              }}
              sx={{ textTransform: 'none !important' }}
            >
              Create Return
            </Button>
          )}
        {orderStatuses.includes(salesOrderFlagStatus.status) &&
          !isWarehouseCustomerAccount &&
          !isSupplierAccount && (
            <Button
              className="headerbuttonSecondary"
              variant="outlined"
              size="small"
              sx={{ marginLeft: '10px !important' }}
            >
              <Link
                sx={{ textDecoration: 'none', fontFamily: 'inherit' }}
                href={`/order/invoice-details?orderId=${
                  queryStringItemOrders.id
                }${
                  salesOrderForm.invoiceId
                    ? `&invoiceId=${salesOrderForm.invoiceId}`
                    : ``
                }`}
                target="_blank"
              >
                <Typography
                  sx={{ cursor: 'pointer', textTransform: 'none !important' }}
                >
                  Generate Invoice
                </Typography>
              </Link>
            </Button>
          )}
        {orderStatuses.includes(salesOrderFlagStatus.status) &&
          (!isWarehouseCustomerAccount ||
            (isWarehouseCustomerAccount &&
              !roleWarehouseCustomerPermission.OrderViewOnly)) && (
            <Button
              className="headerbuttonSecondary"
              variant="outlined"
              size="small"
              onClick={refreshSalesOrderInventoryOnClick}
              sx={{
                textTransform: 'none !important',
                marginLeft: '10px !important',
                padding: '4.2px',
              }}
            >
              Refresh Inventory
            </Button>
          )}
        {orderStatusesCreatePickTicket.includes(salesOrderFlagStatus.status) &&
          !isWarehouseCustomerAccount &&
          handleUserPermissionAllow(UserPermissionEnum.Orders_Edit) && (
            <Button
              className="headerbuttonSecondary"
              variant="outlined"
              size="small"
              onClick={(e) => {
                handleClose();
                createOrderPickTicketNewOnClick(e);
              }}
              sx={{
                textTransform: 'none !important',
                marginLeft: '10px !important',
                padding: '4.2px',
              }}
            >
              Create Pick Ticket
            </Button>
          )}

        {(salesOrderFlagStatus.status === OrderStatus.Complete ||
          salesOrderFlagStatus.status === OrderStatus.Partial ||
          salesOrderFlagStatus.status === OrderStatus.ReadyForPickup) &&
          handleUserPermissionAllow(UserPermissionEnum.Orders_Edit) && (
            <>
              <Button
                id="customized-button"
                aria-controls={voidOpen ? 'customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={voidOpen ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={voidHandleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ marginLeft: '10px !important' }}
              >
                Void the Pack
              </Button>

              <StyledMenu
                id="customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'customized-button',
                }}
                anchorEl={voidAnchorEl}
                open={voidOpen}
                onClose={voidHandleClose}
              >
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    voidHandleClose();
                    setVoidType(OrderVoidTypeEnum.VoidThePack);
                    setIsVoidThePackModal(true);
                  }}
                >
                  Void the Pack
                </MenuItem>

                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    voidHandleClose();
                    setVoidType(OrderVoidTypeEnum.VoidTheLabel);
                    setIsVoidThePackModal(true);
                  }}
                  disableRipple
                >
                  Void the Label
                </MenuItem>
              </StyledMenu>
            </>
          )}

        {salesOrderFlagStatus.status !== OrderStatus.Complete &&
          !isWarehouseCustomerAccount && (
            <Button
              id="customized-button"
              aria-controls={open ? 'customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="outlined"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ marginLeft: '10px !important' }}
            >
              {salesOrderFlagStatus.statusStr}
            </Button>
          )}
        {isWarehouseCustomerAccount &&
          roleWarehouseCustomerPermission.OrderViewOnly && (
            <Button
              id="customized-button"
              aria-controls={open ? 'customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="outlined"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {salesOrderFlagStatus.statusStr}
            </Button>
          )}

        {salesOrderFlagStatus.status === OrderStatus.Complete &&
          !isWarehouseCustomerAccount && (
            <Button
              id="customized-button"
              aria-controls={open ? 'customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="outlined"
              disableElevation
              sx={{ marginLeft: '10px !important' }}
              disabled={salesOrderFlagStatus.status === OrderStatus.Complete}
            >
              {salesOrderFlagStatus.statusStr}
            </Button>
          )}

        {salesOrderForm?.isReturn !== true && (
          <StyledMenu
            id="customized-menu"
            MenuListProps={{
              'aria-labelledby': 'customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              {...((salesOrderFlagStatus.status === OrderStatus.New ||
                salesOrderFlagStatus.status === OrderStatus.Open ||
                salesOrderFlagStatus.status === OrderStatus.Shipment ||
                salesOrderFlagStatus.status === OrderStatus.Partial ||
                salesOrderFlagStatus.status === OrderStatus.Cancelled ||
                salesOrderFlagStatus.status === OrderStatus.ReadyForPickup) && {
                sx: { display: 'none' },
              })}
              onClick={(e) => {
                handleClose();
                changeStatusSalesOrderOnClick(e, OrderStatus.New);
              }}
              disableRipple
            >
              New
            </MenuItem>

            <MenuItem
              {...((salesOrderFlagStatus.status === OrderStatus.Open ||
                salesOrderFlagStatus.status === OrderStatus.Complete) && {
                sx: { display: 'none' },
              })}
              {...(isWarehouseCustomerAccount && {
                sx: { display: 'none' },
              })}
              onClick={(e) => {
                handleClose();

                if (
                  salesOrderForm.isOrderLineItemQtyMatchPickTicketLineItemQty
                ) {
                  changeStatusSalesOrderOnClick(e, OrderStatus.Open);
                } else {
                  createOrderPickTicketNewOnClick(e);
                }
              }}
              disableRipple
            >
              Open
            </MenuItem>
            <MenuItem
              {...((salesOrderFlagStatus.status === OrderStatus.Shipment ||
                salesOrderForm.isAllPickTicketComplete === false) && {
                sx: { display: 'none' },
              })}
              {...(isWarehouseCustomerAccount && {
                sx: { display: 'none' },
              })}
              onClick={(e) => {
                handleClose();
                changeStatusSalesOrderOnClick(e, OrderStatus.Shipment);
              }}
              disableRipple
            >
              Shipment
            </MenuItem>
            <MenuItem
              {...((salesOrderFlagStatus.status === OrderStatus.Partial ||
                salesOrderForm.isAllPickTicketComplete === false) && {
                sx: { display: 'none' },
              })}
              {...(isWarehouseCustomerAccount && {
                sx: { display: 'none' },
              })}
              onClick={(e) => {
                handleClose();
                changeStatusSalesOrderOnClick(e, OrderStatus.Partial);
              }}
              disableRipple
            >
              Partial
            </MenuItem>
            <MenuItem
              {...((salesOrderFlagStatus.status ===
                OrderStatus.ReadyForPickup ||
                salesOrderForm.isAllPickTicketComplete === false) && {
                sx: { display: 'none' },
              })}
              {...(isWarehouseCustomerAccount && {
                sx: { display: 'none' },
              })}
              onClick={(e) => {
                handleClose();
                changeStatusSalesOrderOnClick(e, OrderStatus.ReadyForPickup);
              }}
              disableRipple
            >
              Ready for Pickup
            </MenuItem>
            <MenuItem
              {...((salesOrderFlagStatus.status === OrderStatus.Complete ||
                salesOrderForm.isAllPickTicketComplete === false) && {
                sx: { display: 'none' },
              })}
              {...(isWarehouseCustomerAccount && {
                sx: { display: 'none' },
              })}
              onClick={(e) => {
                handleClose();

                e.preventDefault();
                setIsOrderCompleteModal(true);
              }}
              disableRipple
            >
              Complete
            </MenuItem>
            <MenuItem
              {...((salesOrderFlagStatus.status === OrderStatus.Cancelled ||
                salesOrderFlagStatus.status === OrderStatus.Complete) && {
                sx: { display: 'none' },
              })}
              onClick={(e) => {
                handleClose();
                changeStatusSalesOrderOnClick(e, OrderStatus.Cancelled);
              }}
              disableRipple
            >
              Cancelled
            </MenuItem>
          </StyledMenu>
        )}
      </Box>
    )
  );
};

export default React.memo(OrderStatusDropdown);
