import React, { useContext, useEffect, useState } from 'react';

import Button from 'components/button';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import {
  getUsersRoleWarehouseCustomer,
  getUsersRoleWarehouseCustomerByUserId,
} from 'services/api/user/users.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Checkbox,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

import { useWarehouseCustomerContext } from '..';

interface AutoCompleteOptionType {
  id: number;
  label: string;
}
type RoleWarehouseCustomerType = AutoCompleteOptionType;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default React.memo(() => {
  const { isCustomerPortalPermissionModalOpen } = useContext(GlobalContext);

  const {
    handleOnCreateCustomerPortalAccount,
    formErrors,
    roleWarehouseCustomerValue,
    userInfo,
    setForm,
    form,
    setRoleWarehouseCustomerValue,
    handleCloseCustomerPortalAccountModal,
  } = useWarehouseCustomerContext();

  const [optionRoleWarehouseCustomers, setOptionRoleWarehouseCustomers] =
    useState<RoleWarehouseCustomerType[]>([]);

  const onLoadWarehouseCustomerModules = async () => {
    try {
      return await getUsersRoleWarehouseCustomer();
    } catch (err: any) {
      return err;
    }
  };
  const onLoadWarehouseCustomerModulesByUserId = async (userID) => {
    try {
      return await getUsersRoleWarehouseCustomerByUserId(userID);
    } catch (err: any) {
      return err;
    }
  };

  useEffect(() => {
    if (isCustomerPortalPermissionModalOpen) {
      const optionsRoleWarehouseCustomer = onLoadWarehouseCustomerModules();
      optionsRoleWarehouseCustomer
        .then((opt) => {
          setOptionRoleWarehouseCustomers(
            opt.map((c: any) => ({
              label: c.name,
              value: c.id,
            })),
          );
        })
        .catch((err) => console.log(err));

      if (userInfo?.userId) {
        const loadData = onLoadWarehouseCustomerModulesByUserId(
          userInfo.userId,
        );
        loadData
          .then((opt) => {
            setRoleWarehouseCustomerValue(
              opt.map((c: any) => ({
                label: c.name,
                value: c.id,
              })),
            );
          })
          .catch((err) => console.log(err));
      }
    }
  }, [isCustomerPortalPermissionModalOpen]);

  return (
    <Modal open={isCustomerPortalPermissionModalOpen}>
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Permissions
          </Typography>
          <Input
            placeholder="Email Address"
            size="small"
            sx={{
              backgroundColor: '#ffffff !important',
              width: '100%',
            }}
            value={form.customerEmailPortal}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                customerEmailPortal: e.target.value,
              }));
            }}
            error={formErrors?.customerEmailPortal}
            required
            disabled={userInfo?.userId}
          />

          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={optionRoleWarehouseCustomers}
            value={roleWarehouseCustomerValue}
            disableCloseOnSelect
            getOptionLabel={(option: AutoCompleteOptionType) => option.label}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            onChange={(event: any, newValue) => {
              if (newValue !== null) {
                setRoleWarehouseCustomerValue(newValue);
              } else {
                setRoleWarehouseCustomerValue(null);
              }
            }}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Permission"
                {...(formErrors?.warehouseCustomerPermissions && {
                  error: true,
                  helperText: formErrors?.warehouseCustomerPermissions,
                })}
                placeholder="Permission"
                required
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '8px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="text"
              size="medium"
              onClick={() => handleCloseCustomerPortalAccountModal()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={(e) => handleOnCreateCustomerPortalAccount(e)}
            >
              Submit
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
