import React, { useContext, useState, useEffect, useRef } from 'react';

import { PrintContainer } from 'components/styles';
import {
  getPickTicketDetailsFormTemplate,
  getPurchaseOrderDetailsFormTemplate,
  GetPackingListDetailsFormTemplate,
  GetBillofLadingDetailsFormTemplate,
  GetLPNFormTemplate,
  GetPrintAllLPNFormTemplate,
  GetBulkPrintPickTicketDetailsFormTemplate,
} from 'services/api/printoutforms/printoutform.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Box, Button, CircularProgress, Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

export enum FormTemplateTypeEnum {
  PickTicket = 1,
  BillofLading = 2,
  PackingList = 3,
  PurchaseOrder = 4,
  Invoice = 5,
  PalletLabels = 6,
}

interface PrintFormTemplateProps {
  callBack?: () => void;
  dynamicId?: any;
  preview?: boolean;
  previewId?: any;
  formtemplateTypeId?: number;
  forPalletLabelsIsUseLicense?: boolean;
  isPrintAllLpn?: boolean;
  isComingFromOrder?: boolean;
  isBulkPrintPickTicket?: boolean;
}

export default React.memo(
  ({
    callBack,
    dynamicId,
    preview,
    previewId,
    formtemplateTypeId,
    forPalletLabelsIsUseLicense,
    isPrintAllLpn,
    isComingFromOrder,
    isBulkPrintPickTicket,
  }: PrintFormTemplateProps) => {
    const [htmlLoadData, setHtmlLoadData] = useState('');
    const [formtemplateTypeInputId, setformtemplateTypeInputId] =
      useState(formtemplateTypeId);

    const {
      isPrintFormTemplateOpen,
      setIdToPrintLpnFormTemplate,
      setIdsToPrintLpnFormTemplate,
      setIsPrintFormTemplateLoading,
      onClosePrintFormTemplateModal,
    } = useContext(GlobalContext);

    const buttonPrinter = useRef<HTMLButtonElement>(undefined);

    const handleClosePrintFormTemplateModal = () => {
      setHtmlLoadData('');
      onClosePrintFormTemplateModal();
    };

    const handlePrintPO = async () => {
      window.print();
    };

    const onLoadWarehouseCustomerLookup = async () => {
      try {
        if (preview) {
          const result = await getPickTicketDetailsFormTemplate(
            previewId,
            preview,
          );
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(
            result.getPickTicketDetailsModelString,
            'text/html',
          );
          return setHtmlLoadData(htmlDoc.body.innerText);
        }

        if (
          formtemplateTypeInputId !== 0 &&
          formtemplateTypeInputId !== undefined &&
          formtemplateTypeInputId !== null
        ) {
          if (formtemplateTypeInputId === FormTemplateTypeEnum.PickTicket) {
            if (isBulkPrintPickTicket) {
              const result = await GetBulkPrintPickTicketDetailsFormTemplate(
                dynamicId,
              );
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.htmlString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            } else {
              const result = await getPickTicketDetailsFormTemplate(
                dynamicId,
                preview,
              );
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.getPickTicketDetailsModelString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            }
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.PurchaseOrder
          ) {
            const result = await getPurchaseOrderDetailsFormTemplate(dynamicId);
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(
              result.getPurchaseOrderDetailsModelString,
              'text/html',
            );
            setHtmlLoadData(htmlDoc.body.innerText);
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.PackingList &&
            forPalletLabelsIsUseLicense !== true
          ) {
            const result = await GetPackingListDetailsFormTemplate(
              dynamicId,
              isComingFromOrder || false,
            );
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(
              result.getPackingListDetailsModelString,
              'text/html',
            );
            setHtmlLoadData(htmlDoc.body.innerText);
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.BillofLading
          ) {
            const result = await GetBillofLadingDetailsFormTemplate(dynamicId);
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(
              result.getBillofLadingDetailsModelString,
              'text/html',
            );
            setHtmlLoadData(htmlDoc.body.innerText);
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.PalletLabels
          ) {
            if (isPrintAllLpn) {
              const result = await GetPrintAllLPNFormTemplate(dynamicId);
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.htmlString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            } else {
              const result = await GetLPNFormTemplate(
                dynamicId,
                forPalletLabelsIsUseLicense,
              );
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.htmlString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            }
          }
        }

        return true;
      } catch (err) {
        return err;
      }
    };
    useEffect(() => {
      onLoadWarehouseCustomerLookup();

      const timeoutId = setTimeout(() => {
        if (formtemplateTypeInputId === FormTemplateTypeEnum.PurchaseOrder) {
          if (isPrintFormTemplateOpen) {
            setIsPrintFormTemplateLoading(false);
            setIdsToPrintLpnFormTemplate(null);
            setIdToPrintLpnFormTemplate(null);
            if (buttonPrinter.current) {
              handleClosePrintFormTemplateModal();
              buttonPrinter.current.click();
            }
          }
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }, [isPrintFormTemplateOpen]);

    if (!isPrintFormTemplateOpen) return null;

    return (
      <Dialog
        sx={{
          height: '90% !important',
        }}
        maxWidth="lg"
        fullWidth
        className="printable"
        open={isPrintFormTemplateOpen}
        onClose={() => handleClosePrintFormTemplateModal()}
      >
        <DialogContent>
          {!htmlLoadData ? (
            <div style={{ textAlignLast: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                margin: '0px',
                padding: '0px',
                width: '100% !important',
              }}
            >
              <style>
                {`  @media print {
                 ${
                   formtemplateTypeInputId ===
                     FormTemplateTypeEnum.PalletLabels &&
                   '@page {size: letter;}'
                 }
                    html, body {
                      height: 100vh !important;
                      margin: 0 !important;
                      padding: 0 !important;
                      overflow: hidden;
                       ${
                         formtemplateTypeInputId ===
                           FormTemplateTypeEnum.PackingList &&
                         `-webkit-print-color-adjust: exact;
                           background-color: #f5f5f5 !important;`
                       }
                    }
                    body * {
                      visibility: hidden;
                    }

                    .printable * {
                      visibility: visible !important;
                      top: 0;
                      left: 0;
                      padding:
                       ${
                         formtemplateTypeInputId ===
                         FormTemplateTypeEnum.PackingList
                           ? `5`
                           : `0`
                       }
                      px;
                      margin: 0px;
                    }
                  
                    .buttonHide{
                     display: none;
                    }
                  }
              `}
              </style>
              <Button
                ref={buttonPrinter}
                sx={{
                  float: 'right',
                  textTransform: 'capitalize !important',
                  display:
                    formtemplateTypeInputId ===
                    FormTemplateTypeEnum.PurchaseOrder
                      ? 'none'
                      : 'block',
                }}
                className="buttonHide"
                variant="contained"
                size="large"
                type="button"
                onClick={handlePrintPO}
              >
                Print
              </Button>
              <div
                className="printable"
                dangerouslySetInnerHTML={{
                  __html: htmlLoadData,
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  },
);
