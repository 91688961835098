export enum UserPermissionEnum {
  PurchaseOrder = 1,
  PurchaseOrder_View = 2,
  PurchaseOrder_Edit = 3,
  PurchaseOrder_DocumentUpload = 4,
  Inventory = 5,
  Inventory_View = 6,
  Inventory_Edit = 7,
  Inventory_AddNew = 8,
  Inventory_AddPackage = 9,
  Inventory_AddKitOnDemand = 10,
  Orders = 11,
  Orders_View = 12,
  Orders_Edit = 13,
  Orders_AddOrder = 14,
  Orders_DocumentUpload = 15,
  Orders_EditNotes = 16,
  Orders_EditChecklist = 17,
  Dashboard = 18,
  Dashboard_View = 19,
  Returns = 20,
  Returns_View = 21,
  Returns_AddReturns = 22,
  Kitting = 23,
  Kitting_View = 24,
  Kitting_Edit = 25,
  Kitting_AddNew = 26,
  Locations = 27,
  Locations_View = 28,
  Locations_Add = 29,
  Locations_Edit = 30,
  Suppliers = 31,
  Suppliers_View = 32,
  Suppliers_Add = 33,
  Suppliers_Edit = 34,
  CycleCount = 35,
  CycleCount_View = 36,
  CycleCount_Add = 37,
  CycleCount_Edit = 38,
  Receiving = 39,
  Receiving_View = 40,
  Receiving_AddNew = 41,
  Receiving_Edit = 42,
  Receiving_ConfirmQC = 43,
  Receiving_Putaway = 44,
  Picking = 45,
  Picking_View = 46,
  Picking_Edit = 47,
  Picking_CompletePick = 48,
  PackAndShip = 49,
  PackAndShip_View = 50,
  PackAndShip_CompletePack = 51,
  Client = 52,
  Client_View = 53,
  Client_Edit = 54,
  Client_AddNew = 55,
  Client_Billing = 56,
  Client_Billing_View = 57,
  Client_Billing_Edit = 58,
  Client_Integration = 59,
  Client_Integration_View = 60,
  Client_Integration_Edit = 61,
  Client_CustomerPortal = 62,
  Client_CustomerPortal_View = 63,
  Client_CustomerPortal_Edit = 64,
  Client_ShippingAccounts = 65,
  Client_ShippingAccounts_View = 66,
  Client_ShippingAccounts_Edit = 67,
  Client_Rules = 68,
  Client_Rules_View = 69,
  Client_Rules_Edit = 70,
  Reports = 71,
  Reports_View = 72,
  Administrative = 73,
  Administrative_Settings = 74,
  Administrative_UserManagement = 75,
  Administrative_Devices = 76,
  Administrative_Facilities = 77,
  Administrative_Forms = 78,
  PackAndShip_Edit = 79,
  Client_Billing_View_Charge_Rate = 80,
}
