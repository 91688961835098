import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getShopifyIntegrations = async () => {
  try {
    const response = await httpClient.get(`/shopifyintegration/list/all`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const requestShopifyAuthorizationUrl = async (payload) => {
  try {
    const response = await httpClient.post(
      '/shopifyintegration/getAuthorizationUrl',
      payload,
    );
    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const createShopifyIntegrationWithAccessToken = async (payload) => {
  try {
    const response = await httpClient.post(
      '/shopifyintegration/createShopifyIntegration',
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const disableShopifyIntegration = async (id, disconnect) => {
  try {
    const response = await httpClient.put(
      `/shopifyintegration/disable/${id}/${disconnect}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const enableShopifyIntegration = async (id) => {
  try {
    const response = await httpClient.put(`/shopifyintegration/enable/${id}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getShopifyIntegrationByCustomerId = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/shopifyintegration/${customerId || 0}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const connectWithXero = async (warehouseCustomerId = null) => {
  try {
    const response = await httpClient.get(
      `/xero/generateAccessToken/${warehouseCustomerId ?? 0}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const checkXeroConnectionExists = async (warehouseCustomerId = null) => {
  try {
    const response = await httpClient.get(
      `/xero/checkExistingConnection/${warehouseCustomerId ?? 0}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const checkQuickBooksConnectionExists = async (
  warehouseCustomerId = null,
) => {
  try {
    const response = await httpClient.get(
      `/quickBooks/checkExistingConnection/${warehouseCustomerId ?? 0}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const connectWithQuickBooks = async (warehouseCustomerId = null) => {
  try {
    const response = await httpClient.get(
      `/quickbooks/getAuthorizationUrl/${warehouseCustomerId ?? 0}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getListOfInvoicesToBeSynced = async () => {
  try {
    const response = await httpClient.get(`/quickbooks/invoicesToBeSynced`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const pushInvoices = async () => {
  try {
    const response = await httpClient.get(`/quickbooks/pushInvoices`, {
      params: { showLoading: true },
    });

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const pushIndividualInvoiceApi = async (invoiceId) => {
  try {
    const response = await httpClient.get(
      `/quickbooks/pushInvoices/${invoiceId}`,
      { params: { showLoading: true } },
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getExternalInvoiceById = async (externalInvoiceId) => {
  try {
    const response = await httpClient.get(
      `/quickbooks/getInvoice/${externalInvoiceId}`,
      { responseType: 'arraybuffer' },
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const inventorySyncUp = async () => {
  try {
    const response = await httpClient.get(`/quickbooks/createItems/`, {
      params: { showLoading: true },
    });

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const loadIntegrationLogs = async () => {
  try {
    const response = await httpClient.get(`/quickbooks/integrationLogs`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const shopifyInventorySyncUp = async (
  integrationId,
  fullInventory = false,
) => {
  try {
    const response = await httpClient.get(
      `/shopifyintegration/${integrationId}/product/syncfromshopify/${fullInventory}`,
      {
        params: { showLoading: true },
      },
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const shopifyOrdersSyncUp = async (integrationId, dateFrom) => {
  try {
    const response = await httpClient.get(
      `/shopifyintegration/syncShopifyOrders/${integrationId}/${dateFrom}`,
      {
        params: { showLoading: true },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getShopifyLocations = async (integrationId) => {
  try {
    const response = await httpClient.get(
      `/shopifyintegration/locations/${integrationId}/list`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getShopifyIntegrationDetail = async (integrationId) => {
  try {
    const response = await httpClient.get(
      `/shopifyintegration/details/${integrationId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateShopifyIntegration = async (integrationId, payload) => {
  try {
    const response = await httpClient.put(
      `/shopifyintegration/${integrationId}`,
      payload,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getShopifyLogsList = async (
  warehouseCustomerId,
  logType,
  filterDays,
) => {
  try {
    const response = await httpClient.get(
      `/shopifyintegration/viewLogs/${warehouseCustomerId}/${logType}/${filterDays}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const pushInventoryQty = async (integrationId) => {
  try {
    const response = await httpClient.get(
      `/shopifyintegration/pushInventory/${integrationId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const enableAutoSync = async (id) => {
  try {
    const response = await httpClient.put(
      `/shopifyintegration/enableAutoSync/${id}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const disableAutoSync = async (id) => {
  try {
    const response = await httpClient.put(
      `/shopifyintegration/disableAutoSync/${id}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const itemEcommerceSyncQty = async (id) => {
  try {
    const response = await httpClient.get(`/shopifyintegration/pushItem/${id}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getAlereLogsList = async (logType, filterDays) => {
  try {
    const response = await httpClient.get(
      `/alere/viewLogs/${logType}/${filterDays}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const triggerAlereJobs = async () => {
  try {
    const response = await httpClient.get(`/alere/triggerJobs`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
