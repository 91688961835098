import { IPayloadLPBase, IPayloadLPUpdate } from 'common/payloads';
import { ResponseStandard } from 'common/response';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getAndScanPickTicket = async (customerFacilityId, searchText) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/GetPickTicketForApproval/${customerFacilityId}?searchText=${searchText}`,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getAndScanPickTicketV2 = async (
  customerLocationId,
  customerFacilityId,
  searchText,
) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/PackAndShip/PickTicketForApproval/${customerLocationId}/${customerFacilityId}?searchText=${searchText}`,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
// https://localhost:5001/api/pickticket/packandship/pickticketdetail/9
export const getTheScannedPickTicketDetails = async (pickTicketId) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/LineItem/${pickTicketId}`,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getTheScannedPickTicketDetailsV2 = async (
  pickTicketId,
  isWeightAutoCompute?: boolean,
) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/PackAndShip/PickTicketDetail/${pickTicketId}/${
        isWeightAutoCompute ?? false
      }`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getThePickTicketForApprovedOrApprovedAndPackDetails = async (
  pickTicketId,
) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/LineItem/ApprovedOrApprovedAndPack/${pickTicketId}`,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getThePickTicketCarrierDetails = async (orderId) => {
  try {
    const response = await httpClient.get(
      `/Order/PickTicket/Carrier/${orderId}`,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getThePickTicketShipToBillToDetails = async (orderId) => {
  try {
    const response = await httpClient.get(
      `/Order/PickTicket/ShipToBillTo/${orderId}`,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const createThePickTicketPackageLineItem = async (data) => {
  try {
    const response = await httpClient.post(
      `PickTicket/PackAndShip/Package/CreatePackageLineItem`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const printShippingLabelByPickTicketId = (pickTicketId) => {
  window.open(
    `${httpClient.defaults.baseURL}/PickTicket/shippingLabels/pickTicket/print/${pickTicketId}`,
    '_blank',
  );
};

export const printShippingLabelByOrderId = async (orderId) => {
  const response = await httpClient.get(
    `/PickTicket/shippingLabels/order/print/${orderId}`,
    { responseType: 'arraybuffer', params: { showLoading: true } },
  );

  return response;
};

export const updatePackagePackageLineItemQty = async (data) => {
  try {
    const response = await httpClient.post(
      `PickTicket/PackAndShip/Package/UpdatePackageLineItemQty`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createPackageProperty = async (data) => {
  try {
    const response = await httpClient.post(
      `PickTicket/PackAndShip/Package/CreatePackageProperty`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const updateThePickTicketCarrierDetails = async (data) => {
  try {
    const response = await httpClient.post(
      `Order/PickTicket/Carrier/Update`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const updateThePickTicketShipToBillToDetails = async (data) => {
  try {
    const response = await httpClient.post(
      `Order/PickTicket/ShipToBillTo/Update`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const approveOrApproveAndPack = async (data) => {
  try {
    const response = await httpClient.post(
      `PickTicket/LineItem/ApprovePickTicket/`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const approveOrApproveAndPackV2 = async (data) => {
  try {
    const response = await httpClient.post(
      `PickTicket/PackAndShip/PickTicketApproveAndPack/`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const deletePackageLineItemQTY = async (data) => {
  try {
    const response = await httpClient.post(
      `PickTicket/PackAndShip/package/deletepackagelineitemqty`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPackAndShipCompletedList = async (
  customerFacilityId: number,
) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/PackAndShip/GetPackAndShipCompletedList/${customerFacilityId}`,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updatePickTicketAssignedTo = async (data) => {
  try {
    const response = await httpClient.post(
      `order/pickticket/updatepickticketassignedto`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createPackageLabel = async (
  pickTicketId,
  packageId,
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.post(
      `/PickTicket/PackAndShip/CreateLabel/${pickTicketId}/${packageId}/${warehouseCustomerId}`,
      null,
      {
        params: {
          showLoading: true,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const CompleteOrdersAfterPickApproval = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/completeordersafterpickapproval`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createPackAndShipPackageLineItemScan = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/CreatePackAndShipPackageLineItemScan`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateCartonCodeFromPackage = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/package/updatecartoncode`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createPackage = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/package/createpackage`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateWeightPound = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/package/updateweightpound`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createLicensePlate = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/createlicenseplate`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deletePackageOnPackAndShip = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/package/deletepackage`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updatePackageToComplete = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/update/complete`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const PressBShipAndComplete = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/update/ShipAndComplete`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updatePackageToShipAndComplete = async (data) => {
  try {
    const response = await httpClient.post(
      `pickticket/packandship/update/shipandcomplete`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetScanPackAndShipPickTicketLineItem = async (
  pickTicketId,
  barcode,
) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/packandship/scanpackandshippickticketlineitem/${pickTicketId}?barcode=${barcode}`,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createPackAndShipPackageLineitemSerialNo = async (data) => {
  try {
    const response = await httpClient.post(
      `/pickticket/packandship/createpackAndshippackagelineitemSerialNo`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updatePackageLicensePlate = async (payload: IPayloadLPUpdate) => {
  try {
    const response = await httpClient.post<ResponseStandard>(
      `pickticket/packandship/updateLicensePlate`,
      payload,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deletePackageLicensePlate = async (payload: IPayloadLPBase) => {
  try {
    const response = await httpClient.post<ResponseStandard>(
      `pickticket/packandship/deleteLicensePlate`,
      payload,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const verifyAddressForPackAndShip = async (pickTicketId) => {
  try {
    const response = await httpClient.get(
      `PickTicket/PackAndShip/VerifyAddress/${pickTicketId}`,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateOrderStatusToReadyForPickup = async (data) => {
  try {
    const response = await httpClient.post(
      `PickTicket/PackAndShip/UpdateOrderStatusToReadyForPickup`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/pickticket/packandship/ddl/voidfill?warehouseCustomerId={warehouseCustomerId}
export const GetPackAndShipVoidFillDDL = async (
  warehouseCustomerId?: number,
) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/PackAndShip/DDL/VoidFill?warehouseCustomerId=${warehouseCustomerId}`,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const isPackagesInPackAndShipInProgress = async (orderId) => {
  try {
    const response = await httpClient.get(
      `PickTicket/PackAndShip/IsPackagesInPackAndShipInProgress/${orderId}`,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deletePackageInProcess = async (pickTicketId) => {
  try {
    const response = await httpClient.get(
      `PickTicket/PackAndShip/DeletePackageInProcess/${pickTicketId}`,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
