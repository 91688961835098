import React, { useEffect, useContext, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { importPurchaseOrder } from 'services/api/purchaseOrders/purchaseOrders.api';
import { getCustomersLookup } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as XLSX from 'xlsx';

import {
  Box,
  Modal,
  Typography,
  Grid as MUIGrid,
  Link,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';

import { CustomerType } from '../context';

export default React.memo(() => {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const {
    isImportPurchaseOrderModalOpen,
    setIsImportPurchaseOrderModalOpen,
    setImportGlobalErrorMessageList,
    setIsImportGlobalErrorModalOpen,
    handleUpdateData,
  } = useContext(GlobalContext);

  const [customerValue, setCustomerValue] = useState<CustomerType>(null);
  const [optionCustomers, setOptionCustomers] = useState<
    CustomerType[] | null | []
  >([]);
  const [importPurchaseOrderFormErrors, setImportPurchaseOrderFormErrors] =
    useState({
      customer: '',
    });

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const buttonImportFileRef = React.useRef<HTMLInputElement>(null);

  const [formValue, setformValue] = useState({
    warehouseCustomerId: null,
    customerLocationId: null,
    customerFacilityId: null,
  });

  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (isImportPurchaseOrderModalOpen) {
      const optCustomers = onLoadCustomersLookup();

      optCustomers
        .then((opt) => {
          setOptionCustomers(
            opt.map((c: any) => ({
              label: c.name,
              value: c.warehouseCustomerId,
            })),
          );
        })
        .catch((err) => console.log(err));
    }
  }, [isImportPurchaseOrderModalOpen]);

  const csvTemplate = (e) => {
    e.preventDefault();

    const rows = [
      'PO No',
      'Item No/SKU',
      'Quantity',
      'UOM',
      'EA Per Case',
      'IsPallet (Yes/No)',
      'No of Pallets',
      'Weight',
      'LOT No',
      'LOT Exp Date',
      'SerialNo',
      'Issue Date',
      'Expected Arrival Date',
      'Ship Date',
      'Estimated Bulk Ready Date',
      'Supplier No',
      'Payment Status',
      'ShipTo Warehouse',
      'ShipVia',
      'Unit Cost',
      'Shipping Cost',
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'purchase-order_template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const handleOnImportOnSave = async (e: any) => {
    e.preventDefault();

    const fieldValues = customerValue;
    const temp: any = {};
    temp.customer = fieldValues !== null ? '' : 'This field is required';

    setImportPurchaseOrderFormErrors({
      ...temp,
    });
    const bool = Object.values(temp).every((x) => x === '');

    if (bool) {
      try {
        setformValue((prev) => ({
          ...prev,
          warehouseCustomerId: fieldValues.value,
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          customerLocationId: currentLocationAndFacility.customerLocationId,
        }));
        setIsSubmitLoading(true);
        buttonImportFileRef.current.click();
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const changeHandler = (event) => {
    const [file] = event.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary', cellDates: true });

      wb.SheetNames.forEach(async (sheetName) => {
        const Heading = [
          'PurchaseOrderNo',
          'ItemNo',
          'QtyStr',
          'UOM',
          'EaCaseStr',
          'BinItemType',
          'PalletCountStr',
          'WeightStr',
          'LotNo',
          'LotExpirationDateStr',
          'SerialNo',
          'IssueDateStr',
          'ExpectedArrivalDateStr',
          'ShipDateStr',
          'EstimatedBulkReadyDateStr',
          'SupplierNo',
          'PaymentStatus',
          'ShipToWarehouse',
          'ShipVia',
          'UnitCostStr',
          'ShippingCostStr',
        ];

        const rowObject = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
          header: Heading,
          raw: false,
        });
        const payload = {
          warehouseCustomerId: formValue.warehouseCustomerId,
          customerLocationId: formValue.customerLocationId,
          customerFacilityId: formValue.customerFacilityId,
          purchaseOrderImports: JSON.parse(
            JSON.stringify(rowObject.slice(1), (k, v) =>
              v && typeof v === 'object' ? v : `${v}`,
            ),
          ),
        };
        const errorlist = [];

        try {
          const response = await importPurchaseOrder(payload);
          const { status, data } = response;

          if (status === 200) {
            if (data?.errorMessages && data?.errorMessages.length > 0) {
              setImportGlobalErrorMessageList(data?.errorMessages);
              setIsImportGlobalErrorModalOpen(true);
              setIsSubmitLoading(false);
              setIsImportPurchaseOrderModalOpen(false);
              return;
            }
            handleUpdateData();
            snackActions.success('Successfully imported Purchase Order.');
          } else {
            errorlist.push(data);
            setImportGlobalErrorMessageList([...errorlist]);
            setIsImportGlobalErrorModalOpen(true);
          }
        } catch (err) {
          errorlist.push(err);
          setImportGlobalErrorMessageList(errorlist);
          setIsImportGlobalErrorModalOpen(true);
        }

        setIsSubmitLoading(false);
        setIsImportPurchaseOrderModalOpen(false);
      });
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Modal open={isImportPurchaseOrderModalOpen}>
      <MUIContainer sx={{ maxWidth: '500px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Import Purchase Order
        </Typography>
        <MUIContent sx={{ padding: '10px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <Typography variant="body2">
                    Use this import feature to add purchase order in bulk using
                    the CSV upload
                  </Typography>
                </Box>
                <Box sx={{ paddingBottom: '12px' }}>
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link href="#" onClick={csvTemplate}>
                      CSV Template
                    </Link>
                  }
                </Box>
                <Autocomplete
                  onChange={(event: any, newValue: CustomerType) => {
                    setCustomerValue(newValue);
                  }}
                  id="controllable-states"
                  options={optionCustomers}
                  getOptionLabel={(option: CustomerType) => option.label}
                  value={customerValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li
                      data-name="warehouseCustomerId"
                      {...props}
                      key={option.value}
                    >
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      {...(importPurchaseOrderFormErrors.customer && {
                        error: true,
                        helperText: importPurchaseOrderFormErrors.customer,
                      })}
                      id="outlined-required"
                      label="Client"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item md={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  width: '100%',
                }}
              >
                <Button
                  onClick={() => {
                    setCustomerValue(null);
                    setImportPurchaseOrderFormErrors((temp) => ({
                      ...temp,
                      customer: '',
                    }));
                    setIsSubmitLoading(false);
                    setIsImportPurchaseOrderModalOpen(false);
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '60%' }}
                >
                  CANCEL
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{ padding: 1, width: isSubmitLoading ? '100%' : '80%' }}
                  onClick={(e) => {
                    handleOnImportOnSave(e);
                  }}
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading && <CircularProgress />}
                  {isSubmitLoading ? 'Please Wait' : 'IMPORT'}
                </Button>
                <input
                  type="file"
                  name="file"
                  accept=".xlsx,.csv"
                  ref={buttonImportFileRef}
                  onChange={changeHandler}
                  hidden
                />
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
