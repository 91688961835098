import React, { useState, useContext, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/button';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import ResetPasswordModal from 'pages/shared/resetpasswordmodal';
import {
  LoginContainer,
  LoginBackground,
  FormContainer,
  PackemLogo,
  PackTextLogo,
  LoginRedBar,
} from 'pages/styles';
import {
  login as apiLogin,
  getDefaultLocationAndFacility,
} from 'services/api/auth/auth.api';
import {
  GetAccountDetailForActivationSupplier,
  updateSupplierPortalAccountActivation,
} from 'services/api/customer/customer.api';
import { AuthContext } from 'store/contexts/AuthContext';

import { Box, Typography } from '@mui/material';

function SupplierPortal() {
  const {
    login,
    setFacilityAndLocation,
    isAuth,
    currentUser,
    handleSetAuthorized,
  } = useContext(AuthContext);

  const navigate = useNavigate();
  const query = useQuery();

  const initialStateOfQueryParams = {
    key: query.get('key'),
  };
  const [queryString] = useState(initialStateOfQueryParams);

  const [formErrors, setFormErrors] = useState({
    key: '',
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    confirmpassword: '',
    email: '',
  });

  const [form, setForm] = useState({
    key: '',
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    confirmpassword: '',
    email: '',
  });

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
    onForm(key, event.target.value);

  const validateOnlick = (fieldValues = form) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const temp: any = {};

    if ('username' in fieldValues) {
      temp.username = fieldValues.username ? '' : 'This field is required';
    }
    if ('password' in fieldValues) {
      temp.password = fieldValues.password ? '' : 'This field is required';
    }
    if ('firstname' in fieldValues) {
      temp.firstname = fieldValues.firstname ? '' : 'This field is required';
    }
    if ('lastname' in fieldValues) {
      temp.lastname = fieldValues.lastname ? '' : 'This field is required';
    }
    if ('confirmpassword' in fieldValues) {
      if (
        fieldValues.confirmpassword !== '' ||
        fieldValues.confirmpassword !== null ||
        fieldValues.confirmpassword !== undefined
      ) {
        temp.confirmpassword =
          fieldValues.password === fieldValues.confirmpassword
            ? ''
            : 'Confirm Password and Password do not match.';
      } else {
        temp.confirmpassword = fieldValues.confirmpassword
          ? ''
          : 'This field is required';
      }
    }

    setFormErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const onAccountValidate = async () => {
    if (!validateOnlick()) {
      snackActions.error('Please complete required fields');
      return;
    }

    try {
      const result = await updateSupplierPortalAccountActivation(form);
      const LoginCreditial = {
        username: result.username,
        password: form.password,
      };
      const user = await apiLogin(LoginCreditial);
      login(user.token);
      const locationAndFacility = await getDefaultLocationAndFacility(
        user.userData,
      );
      setFacilityAndLocation(locationAndFacility);
      handleSetAuthorized();
      navigate(`/login`);
      snackActions.success('on supplier portal');
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  const viewLoadData = async () => {
    try {
      const response = await GetAccountDetailForActivationSupplier(
        queryString.key,
      );
      setForm(() => ({
        key: response.key,
        username: response.username,
        password: response.password,
        firstname: response.firstname,
        lastname: response.lastname,
        confirmpassword: response.confirmpassword,
        email: response.email,
      }));
      return true;
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    viewLoadData();

    if (isAuth) {
      switch (currentUser.Claim_UserRole) {
        default:
          navigate(`/dashboard`);
      }
    }
  }, [currentUser]);

  return (
    <LoginContainer>
      <ResetPasswordModal />
      <LoginRedBar />
      <LoginBackground>
        <PackemLogo />
      </LoginBackground>
      <FormContainer>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '328px',
            width: '100%',
            alignItems: 'flex-start',
          }}
        >
          <PackTextLogo />
        </Box>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '328px',
            width: '100%',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{ marginBottom: '24px', fontWeight: 'bold' }}
            variant="h6"
          >
            Supplier Account Activation
          </Typography>
        </Box>
        <Box
          component="form"
          autoComplete="off"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '328px',
            width: '100%',
            gap: '16px',
          }}
        >
          <Input
            sx={{ width: '100%' }}
            placeholder="Email*"
            value={form.email}
            error={formErrors.email}
            disabled
            onChange={(value) => inputHandler('email', value)}
          />
          <Input
            sx={{ width: '100%' }}
            placeholder="First Name*"
            value={form.firstname}
            error={formErrors.firstname}
            InputProps={{ 'data-state': 'firstname', autoComplete: 'off' }}
            onChange={(value) => inputHandler('firstname', value)}
          />
          <Input
            sx={{ width: '100%' }}
            placeholder="Last Name*"
            value={form.lastname}
            error={formErrors.lastname}
            InputProps={{ 'data-state': 'lastname', autoComplete: 'off' }}
            onChange={(value) => inputHandler('lastname', value)}
          />
          <Input
            sx={{ width: '100%' }}
            placeholder="Username*"
            value={form.username}
            error={formErrors.username}
            InputProps={{ 'data-state': 'username', autoComplete: 'off' }}
            onChange={(value) => inputHandler('username', value)}
          />
          <Input
            sx={{ width: '100%' }}
            placeholder="Password*"
            value={form.password}
            error={formErrors.password}
            InputProps={{
              'data-state': 'password',
              autoComplete: 'new-password',
            }}
            type="password"
            onChange={(value) => inputHandler('password', value)}
          />
          <Input
            sx={{ width: '100%' }}
            placeholder="Confirm Password*"
            value={form.confirmpassword}
            error={formErrors.confirmpassword}
            InputProps={{
              'data-state': 'confirmpassword',
              autoComplete: 'new-password',
            }}
            type="password"
            onChange={(value) => inputHandler('confirmpassword', value)}
          />
          <Button
            variant="contained"
            size="large"
            onClick={() => onAccountValidate()}
          >
            ACTIVATE ACCOUNT
          </Button>
        </Box>
      </FormContainer>
    </LoginContainer>
  );
}

export default React.memo(SupplierPortal);
