import React, { useEffect, useContext, useState, useRef } from 'react';

import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import {
  importOrder,
  getCustomersLookup,
} from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as XLSX from 'xlsx';

import AddIcon from '@mui/icons-material/Add';
import {
  Modal,
  Box,
  Link,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  Autocomplete,
  Button,
  CircularProgress,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

import { useSalesOrderDataTableViewContext } from '../context-provider';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
  }
`;

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type CustomerType = AutocompleteBase;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const {
    isImportOrderModalOpen,
    onCloseImportOrderModal,
    setImportGlobalErrorMessageList,
    setIsImportGlobalErrorModalOpen,
    setIsImportGlobalSuccessModalOpen,
    setImportGlobalSuccessMessageList,
  } = useContext(GlobalContext);

  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const { OnClickUserFilterSearchApply, setSearchParams } =
    useSalesOrderDataTableViewContext();

  const [importOrderFormErrors, setImportOrderFormErrors] = useState({
    customer: '',
  });
  const [customerValue, setCustomerValue] = useState<CustomerType>(null);
  const [optionCustomers, setOptionCustomers] = useState<
    CustomerType[] | null | []
  >([]);
  const [formValue, setformValue] = useState({
    warehouseCustomerId: null,
    customerLocationId: null,
    customerFacilityId: null,
  });

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const buttonImportFileRef = React.useRef<HTMLInputElement>(null);

  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const csvTemplate = (e) => {
    e.preventDefault();

    const rows = [
      'ORDERRANK',
      'REFERENCENO',
      'HOLD',
      'PURCHASEORDER',
      'CARRIERNAME',
      'CARRIERSERVICE',
      'SHIPBYDATE',
      'SHIPTONAME',
      'SHIPTOCOMPANY',
      'SHIPTOADDRESS1',
      'SHIPTOADDRESS2',
      'SHIPTOCOUNTRY',
      'SHIPTOSTATE',
      'SHIPTOCITY',
      'SHIPTOZIP',
      'SHIPTOEMAIL',
      'SHIPTOPHONE',
      'BILLINGNAME',
      'BILLINGCOMPANY',
      'BILLINGADDRESS1',
      'BILLINGADDRESS2',
      'BILLINGCOUNTRY',
      'BILLINGSTATE',
      'BILLINGCITY',
      'BILLINGZIP',
      'BILLINGEMAIL',
      'BILLINGPHONE',
      'SKU',
      'QTYORDERED',
      'QTYUOM',
      'SELLPRICE',
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'order_template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const handleOnImportOnSave = async (e: any) => {
    e.preventDefault();

    const fieldValues = customerValue;
    const temp: any = {};
    temp.customer = fieldValues !== null ? '' : 'This field is required';

    setImportOrderFormErrors({
      ...temp,
    });
    const bool = Object.values(temp).every((x) => x === '');

    if (bool) {
      try {
        setformValue((prev) => ({
          ...prev,
          warehouseCustomerId: fieldValues.value,
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          customerLocationId: currentLocationAndFacility.customerLocationId,
        }));
        setIsSubmitLoading(true);
        buttonImportFileRef.current.click();
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const changeHandler = (event) => {
    const [file] = event.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });

      wb.SheetNames.forEach(async (sheetName) => {
        const rowObject = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
        // finalObject[sheetName] = rowObject;
        // console.log(rowObject, 'rowObject');
        // console.log(
        //   JSON.stringify(
        //     JSON.parse(
        //       JSON.stringify(rowObject, (k, v) =>
        //         v && typeof v === 'object' ? v : `${v}`,
        //       ),
        //     ),
        //   ),
        //   'JSON.stringify',
        // );

        const payload = {
          warehouseCustomerId: formValue.warehouseCustomerId,
          customerLocationId: formValue.customerLocationId,
          customerFacilityId: formValue.customerFacilityId,
          jsonString: JSON.parse(
            JSON.stringify(rowObject, (k, v) =>
              v && typeof v === 'object' ? v : `${v}`,
            ),
          ),
        };

        try {
          const response = await importOrder(payload);
          const { status, data } = response;

          if (status === 200) {
            if (data?.errorMessages && data?.errorMessages?.length > 0) {
              setImportGlobalErrorMessageList(data?.errorMessages);
              setIsImportGlobalErrorModalOpen(true);
              setIsSubmitLoading(false);
              onCloseImportOrderModal();
              return;
            }
            setImportGlobalSuccessMessageList(data?.successMessage);
            setIsImportGlobalSuccessModalOpen(true);
          } else {
            snackActions.error(`${data} `);
          }
        } catch (err) {
          snackActions.error(err);
        }

        setIsSubmitLoading(false);
        onCloseImportOrderModal();

        const getStoredWarehouseValue = JSON.parse(
          localStorage.getItem('storeWarehouseCustomer'),
        );

        setSearchParams((prev) => ({
          ...prev,
          status: 'open',
          warehouseCustomerId: getStoredWarehouseValue
            ? getStoredWarehouseValue.id
            : null,
        }));
      });
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    const optCustomers = onLoadCustomersLookup();

    optCustomers
      .then((opt) => {
        setOptionCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Modal open={isImportOrderModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Import Order
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <Typography variant="body2">
                    Use this import feature to add order in bulk using the CSV
                    upload
                  </Typography>
                </Box>
                <Box sx={{ paddingBottom: '12px' }}>
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link href="#" onClick={csvTemplate}>
                      CSV Template
                    </Link>
                  }
                </Box>
                <Autocomplete
                  onChange={(event: any, newValue: CustomerType) => {
                    setCustomerValue(newValue);
                  }}
                  id="controllable-states"
                  options={optionCustomers}
                  getOptionLabel={(option: CustomerType) => option.label}
                  value={customerValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li
                      data-name="warehouseCustomerId"
                      {...props}
                      key={option.value}
                    >
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      {...(importOrderFormErrors.customer && {
                        error: true,
                        helperText: importOrderFormErrors.customer,
                      })}
                      id="outlined-required"
                      label="Client"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      setCustomerValue(null);
                      setImportOrderFormErrors((temp) => ({
                        ...temp,
                        customer: '',
                      }));
                      onCloseImportOrderModal();
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{ padding: 1, width: isSubmitLoading ? '100%' : '80%' }}
                    onClick={(e) => {
                      handleOnImportOnSave(e);
                    }}
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading && <CircularProgress />}
                    {isSubmitLoading ? 'Please Wait' : 'IMPORT'}
                  </Button>
                  <input
                    type="file"
                    name="file"
                    accept=".xlsx,.csv"
                    ref={buttonImportFileRef}
                    onChange={changeHandler}
                    hidden
                  />
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
