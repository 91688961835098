import { CreateOrderLineLPNValidationRequestModel } from 'common/models/orderlineitems/CreateOrderLineLPNValidation';
import { CompleteOrderManuallyModel } from 'common/models/orders/completeordermanually';
import {
  OrderChecklistCreateModel,
  OrderChecklistDeleteModel,
  OrderChecklistEditModel,
} from 'common/models/orders/orderchecklists';
import { OrderVoidThePackModel } from 'common/models/orders/voidthepack';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getOrdersDatable = async (
  customerId,
  customerLocationId,
  customerFacilityId,
  searchSingleParam,
  searchParams,
) => {
  try {
    const response = await httpClient.post(
      `/Order/List/${customerId}/${customerLocationId}/${customerFacilityId}?searchParam=${searchSingleParam}`,
      searchParams,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrdersDatableFiltered = async (modelSearch) => {
  try {
    const response = await httpClient.post(`/Order/ListFiltered`, modelSearch);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCustomersLookup = async (
  customerId,
  searchText,
  activeOnly = false,
) => {
  try {
    const response = await httpClient.get(
      `/Warehousecustomer/Lookup/Name/Customer/${customerId}?searchText=${''}&activeOnly=${activeOnly}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getFacilitiesWithLocationByCustomerId = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Customerfacility/FacilitiesWithLocation/Customer/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getGenerateOrderNo = async () => {
  try {
    const response = await httpClient.get(`/Order/Generate`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCountriesLookup = async () => {
  try {
    const response = await httpClient.get(`/Country/Lookup?searchText=${''}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getSkuLookup = async (customerId, searchText) => {
  try {
    const response = await httpClient.get(
      `Item/Lookup/bysku/${customerId}?searchText=${''}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getSkuLookupWithwarehouseCustomer = async (
  customerId,
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `Item/Lookup/getitemlookupbyskuinventory/${customerId}/${warehouseCustomerId}?searchText=${''}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderBillingsLookup = async () => {
  try {
    const response = await httpClient.get(
      `Order/billingType/lookup?searchText=${''}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPrevAddressesLookup = async (
  customerFacilityId,
  warehouseCustomerId,
  contact?: string,
) => {
  try {
    const response = await httpClient.get(
      `/Order/${
        contact === 'shipTo' ? 'ShipTo' : 'BillTo'
      }/lookup/${customerFacilityId}/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getWarehouseCustomerShippingAccountsLookup = async (
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `Warehousecustomershippingaccount/List/${warehouseCustomerId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getUOM = async (uomId) => {
  try {
    const response = await httpClient.get(`Item/InventoryUOM/${uomId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderContactDetail = async (orderContactDetailId) => {
  try {
    const response = await httpClient.get(
      `Order/OrderContactDetail/${orderContactDetailId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderDetail = async (orderId) => {
  try {
    const response = await httpClient.get(`/Order/${orderId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderLineItem = async (orderLineItemId) => {
  try {
    const response = await httpClient.get(`/OrderLineItem/${orderLineItemId}`);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderPickTicketLineItemListNew = async (orderId) => {
  try {
    const response = await httpClient.get(
      `/Order/PickTicket/LineItem/List/New/${orderId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPickTicketDetail = async (pickTicketId) => {
  try {
    const response = await httpClient.get(
      `/Order/PickTicket/Detail/${pickTicketId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getShipmentLabels = async (orderId) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/shipment/labels/${orderId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const duplicateOrderLineItem = async (payload) => {
  try {
    const response = await httpClient.post(`/OrderLineItem/Duplicate`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteOrderLineItem = async (payload) => {
  try {
    const response = await httpClient.post(`/OrderLineItem/Delete`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const cancelOrderLineItem = async (payload) => {
  try {
    const response = await httpClient.post(`/OrderLineItem/Cancel`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createOrderLineItem = async (payload) => {
  try {
    const response = await httpClient.post(`/OrderLineItem/Create`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateOrderLineItem = async (payload) => {
  try {
    const response = await httpClient.post(`/OrderLineItem/Edit`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const backOrderForExisting = async (payload) => {
  try {
    const response = await httpClient.post(
      `/Orderlineitem/BackOrder/Order`,
      payload,
    );
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const backOrderForNew = async (payload) => {
  try {
    const response = await httpClient.post(`/Orderlineitem/BackOrder`, payload);
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const changeStatusSalesOrder = async (payload) => {
  try {
    const response = await httpClient.post(`/Order/ChangeStatus`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/order/completeordermanually
export const CompleteOrderManually = async (
  payload: CompleteOrderManuallyModel,
) => {
  try {
    const response = await httpClient.post(
      `/Order/CompleteOrderManually`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createSalesOrder = async (payload) => {
  try {
    const response = await httpClient.post(`/Order/Create`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const createOrderPickTicket = async (payload) => {
  try {
    const response = await httpClient.post(`/Order/PickTicket/Create`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateOrderPickTicket = async (payload) => {
  try {
    const response = await httpClient.post(`/Order/PickTicket/edit`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const refreshSalesOrderInventory = async (payload) => {
  try {
    const response = await httpClient.post(`/Order/RefreshInventory`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const updateSalesOrder = async (payload) => {
  try {
    const response = await httpClient.post(`/Order/Edit`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const deleteSalesOrder = async (payload) => {
  try {
    const response = await httpClient.post(`/Order/Delete`, payload);
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteOrderPickTicketItem = async (payload) => {
  try {
    const response = await httpClient.post(`/Order/PickTicket/Delete`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const importOrder = async (model) => {
  try {
    const response = await httpClient.post(`/Order/ImportOrder`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const orderMultipleUpdateStatus = async (model) => {
  try {
    const response = await httpClient.post(
      `/order/orderbulkupdatestatus`,
      model,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const OrderAssignTo = async (model) => {
  try {
    const response = await httpClient.post(`/order/orderassignto`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderBatchList = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `order/orderbatchlist/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const UpdateBatchNumber = async (model) => {
  try {
    const response = await httpClient.post(`order/updatebatchnumber`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetOrderTagList = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `order/ordertaglist/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const UpdateTagOrders = async (model) => {
  try {
    const response = await httpClient.post(`order/updatetags`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrdersQuantities = async (
  customerId,
  customerLocationId,
  customerFacilityId,
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `/Order/orderquantities/${customerId}/${customerLocationId}/${customerFacilityId}`,
      {
        params: {
          warehouseCustomerId,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const uploadOrderDocument = async (orderId, file) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const response = await httpClient.post(
      `order/documents/${orderId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createOrderNote = async (orderId, model) => {
  try {
    const response = await httpClient.post(`order/notes/${orderId}`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderDocuments = async (orderId) => {
  try {
    const response = await httpClient.get(`order/documents/${orderId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderNotes = async (orderId) => {
  try {
    const response = await httpClient.get(`order/notes/${orderId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteOrderNote = async (orderNoteId) => {
  try {
    const response = await httpClient.delete(`order/notes/${orderNoteId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteOrderDocument = async (orderDocumentId) => {
  try {
    const response = await httpClient.delete(
      `order/documents/${orderDocumentId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderDocumentFile = async (orderDocumentId) => {
  try {
    const response = await httpClient.get(
      `order/documents/file/${orderDocumentId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderSourceList = async (warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `order/orderSource/list/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/order/checklist/create
export const createOrderChecklist = async (
  model: OrderChecklistCreateModel,
) => {
  try {
    const response = await httpClient.post(`order/checklist/create`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/order/checklist/edit
export const editOrderChecklist = async (model: OrderChecklistEditModel) => {
  try {
    const response = await httpClient.post(`order/checklist/edit`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/order/checklist/delete
export const deleteOrderChecklist = async (
  model: OrderChecklistDeleteModel,
) => {
  try {
    const response = await httpClient.post(`order/checklist/delete`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/order/voidthepack
export const VoidThePack = async (model: OrderVoidThePackModel) => {
  try {
    const response = await httpClient.post(`order/voidthepack`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/orderlineitem/validation/create/lpn
export const CreateOrderLineLPNValidation = async (
  payload: CreateOrderLineLPNValidationRequestModel,
) => {
  try {
    const response = await httpClient.post(
      `/OrderLineItem/Validation/Create/LPN`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
