import React, { useEffect, useContext, useState, useRef } from 'react';

import { snackActions } from 'config/snackbar.js';
import { useInventoryListPageContext } from 'pages/inventory/context';
import { importInventory } from 'services/api/item/item.api';
import { getCustomersLookup } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as XLSX from 'xlsx';

import {
  Modal,
  Box,
  Link,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  Autocomplete,
  CircularProgress,
  Button,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
  }
`;

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type CustomerType = AutocompleteBase;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const {
    isImportInventoryModalOpen,
    onCloseImportInventoryModal,
    setIsErrorInventoryImportModalOpen,
    setIsSuccessInventoryImportModalOpen,
    setErrorMessageInventoryImport,
  } = useContext(GlobalContext);

  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const { OnClickUserFilterSearchApply } = useInventoryListPageContext();

  const [importInventoryFormErrors, setImportInventoryFormErrors] = useState({
    customer: '',
  });
  const [customerValue, setCustomerValue] = useState<CustomerType>(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [optionCustomers, setOptionCustomers] = useState<
    CustomerType[] | null | []
  >([]);
  const [formValue, setformValue] = useState({
    warehouseCustomerId: null,
    customerLocationId: null,
    customerFacilityId: null,
  });

  const buttonImportFileRef = React.useRef<HTMLInputElement>(null);

  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const csvTemplate = (e) => {
    e.preventDefault();

    const rows = [
      'IS PALLET (Yes/No)',
      'PALLETID',
      'SKU',
      'DESCRIPTION',
      'UOM',
      'UOM_LENGTH',
      'UOM_WIDTH',
      'UOM_HEIGHT',
      'UOM_WEIGHT',
      'BARCODE (UPC)',
      'ALLOCATION_METHOD',
      'INVENTORY_TYPE',
      'AREA',
      'LOCATION',
      'QTY',
      'SUPPLIER_NO',
      'SUPPLIER_COST',
      'SUPPLIER_SHIPPING',
      'SUPPLIER_PARTNUM',
      'LOT',
      'EXPIRATION (MM/DD/YYYY)',
      'PICK_LOCATION',
      'LOT_TRACKING (Yes/No)',
      'SERIAL_TRACKING (Yes/No)',
      'UOM_1',
      'UOM_1_COUNT',
      'UOM_1_LENGTH',
      'UOM_1_WIDTH',
      'UOM_1_HEIGHT',
      'UOM_1_WEIGHT',
      'UOM_1_BARCODE',
      'UOM_1_NAME',
      'UOM_2',
      'UOM_2_COUNT',
      'UOM_2_LENGTH',
      'UOM_2_WIDTH',
      'UOM_2_HEIGHT',
      'UOM_2_WEIGHT',
      'UOM_2_BARCODE',
      'UOM_2_NAME',
      'UOM_3',
      'UOM_3_COUNT',
      'UOM_3_LENGTH',
      'UOM_3_WIDTH',
      'UOM_3_HEIGHT',
      'UOM_3_WEIGHT',
      'UOM_3_BARCODE',
      'UOM_3_NAME',
      'PALLET_TYPE',
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'inventory_template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const handleOnImportOnSave = async (e: any) => {
    e.preventDefault();

    const fieldValues = customerValue;
    const temp: any = {};
    temp.customer = fieldValues !== null ? '' : 'This field is required';

    setImportInventoryFormErrors({
      ...temp,
    });
    const bool = Object.values(temp).every((x) => x === '');

    if (bool) {
      try {
        setformValue((prev) => ({
          ...prev,
          warehouseCustomerId: fieldValues.value,
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          customerLocationId: currentLocationAndFacility.customerLocationId,
        }));
        setIsSubmitLoading(true);
        buttonImportFileRef.current.click();
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const changeHandler = (event) => {
    const [file] = event.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary', cellDates: true });

      wb.SheetNames.forEach(async (sheetName) => {
        const Heading = [
          'Type',
          'PalletIdStr',
          'SKU',
          'Description',
          'UOM',
          'UomLengthStr',
          'UomWidthStr',
          'UomHeightStr',
          'UomWeightStr',
          'UPC',
          'AllocationMethod',
          'InventoryType',
          'Area',
          'Location',
          'QtyStr',
          'SupplierNoStr',
          'SupplierCostStr',
          'SupplierShippingStr',
          'SupplierPartNum',
          'Lot',
          'ExpirationStr',
          'PickLocation',
          'LotTracking',
          'SerialTracking',
          'Uom1',
          'Uom1CountStr',
          'Uom1LengthStr',
          'Uom1WidthStr',
          'Uom1HeightStr',
          'Uom1WeightStr',
          'Uom1Barcode',
          'Uom1_Name',
          'Uom2',
          'Uom2CountStr',
          'Uom2LengthStr',
          'Uom2WidthStr',
          'Uom2HeightStr',
          'Uom2WeightStr',
          'Uom2Barcode',
          'Uom2_Name',
          'Uom3',
          'Uom3CountStr',
          'Uom3LengthStr',
          'Uom3WidthStr',
          'Uom3HeightStr',
          'Uom3WeightStr',
          'Uom3Barcode',
          'Uom3_Name',
          'PalletType',
        ];

        const rowObject = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
          header: Heading,
          raw: false,
        });
        const payload = {
          warehouseCustomerId: formValue.warehouseCustomerId,
          customerLocationId: formValue.customerLocationId,
          customerFacilityId: formValue.customerFacilityId,
          itemImportModels: JSON.parse(
            JSON.stringify(rowObject.slice(1), (k, v) =>
              v && typeof v === 'object' ? v : `${v}`,
            ),
          ),
        };

        try {
          const response = await importInventory(payload);
          const { status, data } = response;

          if (status === 200) {
            if (data?.errorMessages && data?.errorMessages.length > 0) {
              setErrorMessageInventoryImport(data?.errorMessages);
              setIsErrorInventoryImportModalOpen(true);
              setIsSubmitLoading(false);
              onCloseImportInventoryModal();
              return;
            }
            setIsSuccessInventoryImportModalOpen(true);
            setErrorMessageInventoryImport(data?.successMessage);
          } else {
            snackActions.error(`${data} `);
          }
        } catch (err) {
          snackActions.error(err);
        }

        setIsSubmitLoading(false);
        onCloseImportInventoryModal();
        OnClickUserFilterSearchApply(null, true);
      });
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    const optCustomers = onLoadCustomersLookup();

    optCustomers
      .then((opt) => {
        setOptionCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Modal open={isImportInventoryModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Import Inventory
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <Typography variant="body2">
                    Use this import feature to add inventory in bulk using the
                    CSV upload
                  </Typography>
                </Box>
                <Box sx={{ paddingBottom: '12px' }}>
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link href="#" onClick={csvTemplate}>
                      CSV Template
                    </Link>
                  }
                </Box>
                <Autocomplete
                  onChange={(event: any, newValue: CustomerType) => {
                    setCustomerValue(newValue);
                  }}
                  id="controllable-states"
                  options={optionCustomers}
                  getOptionLabel={(option: CustomerType) => option.label}
                  value={customerValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li
                      data-name="warehouseCustomerId"
                      {...props}
                      key={option.value}
                    >
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      {...(importInventoryFormErrors.customer && {
                        error: true,
                        helperText: importInventoryFormErrors.customer,
                      })}
                      id="outlined-required"
                      label="Client"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item md={9}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      setCustomerValue(null);
                      setImportInventoryFormErrors((temp) => ({
                        ...temp,
                        customer: '',
                      }));
                      setIsSubmitLoading(false);
                      onCloseImportInventoryModal();
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1, width: '60%' }}
                  >
                    CANCEL
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{ padding: 1, width: isSubmitLoading ? '100%' : '80%' }}
                    onClick={(e) => {
                      handleOnImportOnSave(e);
                    }}
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading && <CircularProgress />}
                    {isSubmitLoading ? 'Please Wait' : 'IMPORT'}
                  </Button>
                  <input
                    type="file"
                    name="file"
                    accept=".xlsx,.csv"
                    ref={buttonImportFileRef}
                    onChange={changeHandler}
                    hidden
                  />
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
