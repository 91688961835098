import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Card from 'components/card';
import Header from 'components/header';
import Input from 'components/input/Input';
import { ContentContainer, MainContainer } from 'pages/styles';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium } from 'styles';

import { Search } from '@mui/icons-material';
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid as MUIGrid,
  Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridColumns,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';
import { PackAndShipContextProvider, usePackAndShipContext } from '../context';
import CancelledModal from '../modal/findpickticketwarning';

const PackAndShipFilterSearchPanel = () => {
  const { packAndShipListRows, setFilteredPackAndShipListRows } =
    usePackAndShipContext();
  const [searchFieldValue, setSearchFieldValue] = React.useState('');
  const handleSearchFieldChange = (newValue) => {
    if (newValue) {
      const filteredList = packAndShipListRows
        ? packAndShipListRows.filter(
            (x) =>
              x?.referenceNo?.toLowerCase().includes(newValue?.toLowerCase()) ||
              x?.orderNo?.toLowerCase().includes(newValue?.toLowerCase()),
          )
        : null;
      setFilteredPackAndShipListRows(filteredList);
    } else {
      setFilteredPackAndShipListRows(packAndShipListRows);
    }
  };

  return (
    <div style={{ width: '100%', padding: '10px' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'right !important',
        }}
      >
        <Input
          sx={{ maxWidth: '100%' }}
          placeholder="Search"
          value={searchFieldValue}
          onChange={(e) => setSearchFieldValue(e.target.value)}
          rightIcon={<Search />}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchFieldChange(e.target.value);
            }
          }}
        />
      </Box>
    </div>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          flex: '1',
          width: '100% !important',
        }}
      >
        <PackAndShipFilterSearchPanel />
      </Box>
    </GridToolbarContainer>
  );
}

const PackAndShipFindPickTicketContainer = () => {
  const navigate = useNavigate();

  const { onOpenPackAndShipFindPickTicketWarningModal } =
    useContext(GlobalContext);

  const {
    searchTerm,
    setSearchTerm,
    findPickTicketForm,
    findPickTicketFormObj,
    findPickTicketFormOnChange,
    setQueryStringItemPackAndShip,
  } = usePackAndShipContext();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm && searchTerm.length > 1) {
        if (
          findPickTicketFormObj?.isCancelled ||
          findPickTicketFormObj?.isHold
        ) {
          onOpenPackAndShipFindPickTicketWarningModal();
        }

        if (
          findPickTicketFormObj.pickTicketId !== -1 &&
          findPickTicketFormObj.pickTicketId !== undefined
        ) {
          navigate(
            `/pack-and-ship/pick-ticket?form=view&id=${findPickTicketFormObj.pickTicketId}&pickId=${findPickTicketFormObj.pickId}`,
          );

          setQueryStringItemPackAndShip((prev) => ({
            ...prev,
            id: findPickTicketFormObj.pickTicketId,
            pickId: findPickTicketForm.pickTicketId,
            form: 'view',
          }));
        }
      }

      setSearchTerm(null);
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        '& .MuiPaper-root': {
          width: '100vh',
        },
      }}
    >
      <Typography variant="h6" fontWeight="bold" mb={2}>
        Find pick ticket
      </Typography>
      <MUIGrid
        sx={{ alignItems: 'center' }}
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <MUIGrid item xs={6}>
          <TextField
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Scan or enter a pick ticket number"
            size="small"
            name="pickTicketId"
            onChange={findPickTicketFormOnChange}
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            autoFocus
          />
        </MUIGrid>
        <MUIGrid item xs={6}>
          <Button
            sx={{ padding: 1 }}
            variant="contained"
            size="small"
            onClick={() => {
              if (
                findPickTicketFormObj?.isCancelled ||
                findPickTicketFormObj?.isHold
              ) {
                onOpenPackAndShipFindPickTicketWarningModal();
              }

              if (
                findPickTicketFormObj.pickTicketId !== -1 &&
                findPickTicketFormObj.pickTicketId !== undefined
              ) {
                navigate(
                  `/pack-and-ship/pick-ticket?form=view&id=${findPickTicketFormObj.pickTicketId}&pickId=${findPickTicketFormObj.pickId}`,
                );

                setQueryStringItemPackAndShip((prev) => ({
                  ...prev,
                  id: findPickTicketFormObj.pickTicketId,
                  pickId: findPickTicketForm.pickTicketId,
                  form: 'view',
                }));
              }
            }}
          >
            Start
          </Button>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const PackAndShipCompleted = () => {
  const theme = useTheme();

  const {
    packAndShipListRows,
    filteredPackAndShipListRows,
    onLoadPackAndShipCompletedList,
    setPackAndShipListRows,
    setFilteredPackAndShipListRows,
  } = usePackAndShipContext();

  const { currentLocationAndFacility } = useContext(AuthContext);

  type dataGridRows = typeof packAndShipListRows[number];
  const columnsDataGrid: GridColumns<dataGridRows> = [
    {
      field: 'pickTicketNo',
      minWidth: 180,
      flex: 1,
      headerName: 'Pick Ticket No',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/pack-and-ship/pick-ticket?form=view&id=${params.row.pickTicketId}&pickId=${params.row.pickTicketNo}`}
          underline="none"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.pickTicketNo}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'orderNo',
      minWidth: 180,
      flex: 1,
      headerName: 'Order No',
    },
    {
      field: 'orderStatus',
      minWidth: 180,
      flex: 1,
      headerName: 'Order Status',
    },
    {
      field: 'customerName',
      minWidth: 180,
      flex: 1,
      headerName: 'Client',
    },
    {
      field: 'carrier',
      minWidth: 200,
      flex: 1,
      headerName: 'Carrier',
    },
    {
      field: 'tote',
      minWidth: 180,
      flex: 1,
      headerName: 'Tote',
    },
    {
      field: 'referenceNo',
      minWidth: 180,
      flex: 1,
      headerName: 'Reference No',
    },
    {
      field: 'orderDate',
      minWidth: 180,
      flex: 1,
      headerName: 'Order Date',
    },
    {
      field: 'orderSource',
      minWidth: 180,
      flex: 1,
      headerName: 'Order Source',
    },
  ];

  useEffect(() => {
    const result = onLoadPackAndShipCompletedList(
      currentLocationAndFacility.customerFacilityId,
    );
    result.then((x) => {
      const dataList = x.data.map((c: any) => ({
        pickTicketId: c.pickTicketId,
        pickTicketNo: c.pickTicketNo,
        orderStatus: c.orderStatus,
        orderNo: c.orderNo,
        customerName: c.customerName,
        tote: c.tote ?? '',
        referenceNo: c.referenceNo,
        orderDate: c.orderDate,
        orderSource: c.orderSource,
        carrier: c.carrier,
      }));

      setPackAndShipListRows(dataList);
      setFilteredPackAndShipListRows(dataList);
    });
  }, [currentLocationAndFacility]);

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        '& .MuiPaper-root': {
          width: '100vh',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '8px',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Completed Pick Tickets
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DatagridPremium
          autoHeight
          density="compact"
          rows={filteredPackAndShipListRows}
          columns={columnsDataGrid}
          rowsPerPageOptions={[5, 15, 100]}
          getRowId={(row) => row.pickTicketId}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>
    </Card>
  );
};

const PackAndShipFindPickTicket = () => (
  <MainContainer>
    <PackAndShipContextProvider>
      <Header />
      <CancelledModal />
      <ContentContainer>
        <PackAndShipFindPickTicketContainer />
        <PackAndShipCompleted />
      </ContentContainer>
    </PackAndShipContextProvider>
  </MainContainer>
);

export default React.memo(PackAndShipFindPickTicket);
