/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';

import { UserPermissionEnum } from 'common/enums';
import { DropdownListItem } from 'common/models';
import Card from 'components/card';
import OrderRulesIcon from 'components/icons/OrderRulesIcon';
import Input from 'components/input';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { OrderProcessingRulesDetailsWithoutHeader } from 'pages/sales/orderprocessingrules';
import {
  AutoCompletePaper,
  AutoCompletePopper,
} from 'pages/shared/editreceiptmodal';
import { CountryType } from 'pages/sales/salesorder/context';
import { useOrderSourceRuleSettingsContext } from 'pages/settings/context-order-source-rule';
import { checkedIcon, icon } from 'pages/settings/tabviews/InboundSection';
import { getCustomerBinsByFacilityId } from 'services/api/customerfacilities/customerfacilities.api';
import { getItemsByCustomerId } from 'services/api/cyclecount/cyclecount.api';
import { GetLockOrderEditsAfterDDL } from 'services/api/integrations/outboundsectionsetting/outboundsectionsetting.api';
import {
  getAllCarriersByCustomerId,
  getOrderCarrierservices,
  getShippingIntegrationCompanies,
} from 'services/api/integrations/shipment/shipment.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { DatagridStandard } from 'styles';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Typography,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
  Button,
  Checkbox,
  Switch,
  InputAdornment,
  Box,
  Divider,
} from '@mui/material';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';

import {
  documentTypes,
  signatureTypes,
  defaultOrderTypes,
  useWarehouseCustomerContext,
} from '..';

interface ISignatureType {
  value?: number;
  label?: string;
}

interface IItemMappingSku {
  dataGridId?: number;
  customerRuleManagementSkuMappingId?: number;
  itemId?: number;
  sku?: string;
  skuMapping?: string;
}
interface ICarrierSelection {
  dataGridId?: number;
  customerRuleManagementCarrierId?: number;
  carrierSelection?: {
    value?: number;
    label?: string;
  };
  carrierSelectionId?: number;
  carrierSelectionName?: string;
  carrierSelectionService?: {
    value?: number;
    label?: string;
  };
  carrierSelectionServiceId?: number;
  carrierSelectionServiceName?: string;
  carrierZipcode?: string;
  carrierAccountName?: string;
  carrierthirdPartyAccount?: string;
}

export interface IDropDownCarrier {
  carrierId?: number;
  carrierServiceId?: number;
  value?: number;
  label?: string;
  ruleManagementCarrierType?: CarrierTypeEnum;
}
export enum CarrierTypeEnum {
  ShippingAndCarrier = 0,
  CarrierType = 1,
  CarrierTypeWeight = 2,
  InsuranceOptionType = 3,
  InsuranceOptionTypeWeight = 4,
}
export enum CustomerType {
  Customer = 1,
  WarehouseCustomer = 2,
}

export interface IOrderAIRuleDetail {
  customerOrderSourceRuleId?: string;
  orderSource?: string;
  orderSourceDescription?: string;
  orderSourceLongDescription?: string;
  customerRuleManagementId?: string;
  signatureOption?: boolean;
  signatureOptionOrderValue?: string;
  signatureType?: {
    value?: number;
    label?: string;
  };
  signatureOptionOrderWeight?: string;
  signatureTypeWeight?: {
    value?: number;
    label?: string;
  };
  carrierSelection?: boolean;
  carrierSelectionOrderValue?: string;
  carrierSelectionOrderWeight?: string;

  insuranceOption?: boolean;
  insuranceOptionOrderValue?: string;
  insuranceOptionOrderWeight?: string;

  printCartonContentLabel?: boolean;
  printCartonContentLabelImage?: string;
  overrideShipFromAddress?: boolean;
  shipFromName?: string;
  shipFromAddress?: string;
  shipFromCity?: string;
  shipFromState?: string;
  shipFromZip?: string;
  shipFromCountry?: {
    value?: number;
    label?: string;
  };
  overrideReturnFromAddress?: boolean;
  returnName?: string;
  returnAddress?: string;
  returnCity?: string;
  returnState?: string;
  returnZip?: string;
  returnCountry: {
    value?: number;
    label?: string;
  };
  shippingDocumentSettings?: boolean;
  documentType?: {
    value?: number;
    label?: string;
  };
  autoholdAllOrders?: boolean;
  internationalOrders?: boolean;
  internationalOrdersDollarValue?: string;
  defaultOrderType?: {
    value?: number;
    label?: string;
  };
  defaultPackageType?: {
    value?: number;
    label?: string;
  };
  acceptReturns?: boolean;
  maxAllowedReturnDays?: string;
  overrideMaximumReturnDays?: boolean;
  acceptReceiveInWhole?: boolean;
  useAllClientCarriers?: boolean;
  shippingAndCarrier?: {
    value?: number;
    label?: string;
  };
  signatureOptionUseDefault?: boolean;
  carrierSelectionUseDefault?: boolean;
  insuranceOptionUseDefault?: boolean;
  printCartonContentLabelUseDefault?: boolean;
  shippingDocumentSettingsUseDefault?: boolean;
  defaultPackageTypeUseDefault?: boolean;
  ordersUseDefault?: boolean;
  returnOrdersUseDefault?: boolean;
  itemMappingUseDefault?: boolean;
  itemMappingSkus: IItemMappingSku[] | null;
  shippingDocuments: IDropDownCarrier[] | null;
  carrierSelections: ICarrierSelection[] | null;
  carrierSelectionServices: IDropDownCarrier[] | null;
}

interface INewRulesManagementInterface {
  customerType: CustomerType;
  formRules: any;
  setFormRules: any;
  formErrors?: any;
  queryStringId?: number;
  isViewForm?: boolean;
  IsOrderAIRules?: boolean;
  IsRulesManagementTab?: boolean;
  orderSourceRulesDataRow?: any;
}

export const NewRulesManagementInterface = ({
  customerType,
  formRules,
  setFormRules,
  queryStringId,
  isViewForm,
  formErrors,
  IsOrderAIRules,
  IsRulesManagementTab,
  orderSourceRulesDataRow,
}: INewRulesManagementInterface) => {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const {
    isRulesFormEditMode,
    countries,
    isShowRulesForm,
    skuMappingOption,
    // carrierTypesOption,
    shippoCarriers,
    setIsShowRulesForm,
    setIsManageClientDefault,
    handleAddOrderSourceOnlick,
    handleEditOrderSourceOnlick,
    handleAddEditRulesManagementOnClick,
    handleOnCarrierChange, // for Carrier onChange to generate Carrier Services
    handleOnCarrierChangeArrayList,
    handleOnDefaultPackageType, // Onload Default Package Type
    defaultPackgeTypeOption,
    carrierTypeServiceOptionShipping,
    carrierTypeServiceOptionCarrierType,
  } = useWarehouseCustomerContext();

  // CUSTOMER CONTEXT
  const {
    isRulesFormEditModeSettings,
    isShowRulesFormSettings,
    countriesSettings,
    skuMappingOptionSettings,
    carrierTypesOptionSettings,
    defaultPackgeTypeOptionSetting,
    carrierTypeServiceOptionCarrierTypeSetting,
    setIsManageClientDefaultSetting,
    handleOnDefaultPackageTypeSetting,
    handleAddOrderSourceOnlickSetting,
    handleEditOrderSourceOnlickSetting,
    handleOnCarrierChangeArrayListSetting,
    handleOnCarrierChangeSetting,
    handleSaveUpdateCustomerRulesManagement,
  } = useOrderSourceRuleSettingsContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  // ITEM MAPPING -----------------------------------
  const [isNewItemMapping, setIsNewItemMapping] = useState(false);
  const [countrySelectionOptions, setCountrySelectionOptions] = useState([]);
  const [skuMappingOptions, setSkuMappingOptions] = useState([]);

  const [itemsOption, setItemsOption] = useState([]);
  const [optionLocation, setOptionLocation] = useState([]);
  const [optionShippingIntegration, setOptionShippingIntegration] = useState(
    [],
  );
  const [lockOrderEditsAfterDDL, setLockOrderEditsAfterDDL] = useState<
    DropdownListItem[]
  >([]);

  const [carrierTypes, setCarrierTypes] = useState([]);
  const [carrierTypeServicesShipping, setCarrierTypeServicesShipping] =
    useState([]);
  const [carrierTypeServicesCarrierType, setCarrierTypeServicesCarrierType] =
    useState([]);

  const [defaultPackageOption, setDefaultPackageOption] = useState([]);

  const initialItemMapping: IItemMappingSku = {
    customerRuleManagementSkuMappingId: null,
    itemId: null,
    sku: '',
    skuMapping: '',
  };

  const [itemMappingDetail, setItemMappingDetail] =
    useState(initialItemMapping);
  const [formItemMappingErrors, setFormItemMappingErrors] = useState<any>([{}]);

  // CARRIER SELECTION -----------------------------------
  const [isNewOrEditCarrierSelection, setIsNewOrEditCarrierSelection] =
    useState(false);
  const initialCarrierSelection: ICarrierSelection = {
    customerRuleManagementCarrierId: null,
    carrierSelection: {
      value: null,
      label: '',
    },
    carrierSelectionService: {
      value: null,
      label: '',
    },
    carrierZipcode: '',
    carrierAccountName: '',
    carrierthirdPartyAccount: '',
  };

  const [carrierSelectionDetail, setCarrierSelectionDetail] = useState(
    initialCarrierSelection,
  );
  const [formCarrierSelectionErrors, setFormCarrierSelectionErrors] =
    useState<any>([{}]);

  // #region  MAIN FUNCTIONS

  const orderAIRulesColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            if (customerType === CustomerType.WarehouseCustomer) {
              handleEditOrderSourceOnlick(params.row);
            } else if (customerType === CustomerType.Customer) {
              handleEditOrderSourceOnlickSetting(params.row);
            }
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          // onClick={() => handleOpenConfirmationModal(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'orderSource',
      flex: 1,
      minWidth: 160,
      renderHeader: () => (
        <Typography fontWeight="bold">Order Rules</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.orderSource}
        </Typography>
      ),
    },
    {
      field: 'orderSourceDescription',
      flex: 1,
      minWidth: 160,
      renderHeader: () => (
        <Typography fontWeight="bold">Description</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.orderSourceDescription}
        </Typography>
      ),
    },
    {
      field: 'orderSourceLongDescription',
      flex: 1,
      minWidth: 200,
      renderHeader: () => (
        <Typography fontWeight="bold">Long Description</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.orderSourceLongDescription}
        </Typography>
      ),
    },
  ];

  const onLoadUItemsByCustomerData = async () => {
    try {
      return await getItemsByCustomerId(currentUser.Claim_CustomerId);
    } catch (err) {
      return err;
    }
  };

  const onLoadGetLocation = async () => {
    try {
      return await getCustomerBinsByFacilityId(
        currentLocationAndFacility.customerFacilityId,
      );
    } catch (err) {
      return err;
    }
  };

  const [imageDataText, setImageDataText] = useState(
    formRules?.printCartonContentLabelImage,
  );

  const onImageChange = (event) => {
    const MIN_FILE_SIZE = 1024; // 1MB

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size / 1024 > MIN_FILE_SIZE) {
        // eslint-disable-next-line no-alert
        window.alert('Please upload a file smaller than 1 MB');
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          const regex = /data:.*base64,/;
          setImageDataText(reader.result.toString().replace(regex, ''));

          // const data = {} as InventoryItemImageType;
          // data.base64Data = reader.result.toString();

          // const base64 = `${reader.result}`.split(';base64,').pop();
          // const File = {
          //   name: event.target.files[0].name,
          //   content: base64,
          //   size: event.target.files[0].size,
          // } as FileType;
          // data.file = File;

          setFormRules((prevState) => ({
            ...prevState,
            printCartonContentLabelImage: reader.result
              .toString()
              .replace(regex, ''),
          }));
        };
      }
    }
  };

  const handleFilterOption = (options: any[], filter) => {
    const result = options.filter(
      (r) =>
        r.sku.toLowerCase().includes(filter.inputValue.toLowerCase()) ||
        r.description.toLowerCase().includes(filter.inputValue.toLowerCase()),
    );
    return result;
  };

  const inputRef = React.useRef<HTMLInputElement>();
  const setTextInputRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };
  // #endregion

  // #region  ITEM MAPPPING ---------------------------------------------------
  const validateOnlickItemMapping = () => {
    const temp: any = {};
    const datas = [...formRules.itemMappingSkus];

    temp.itemId = itemMappingDetail?.itemId ? '' : 'This field is required';
    temp.skuMapping = itemMappingDetail?.skuMapping
      ? ''
      : 'This field is required';

    if (datas.length > 0) {
      const newValue = datas?.find(
        (row) =>
          row?.itemId === itemMappingDetail?.itemId &&
          row?.skuMapping === itemMappingDetail?.skuMapping,
      );

      temp.itemId = newValue ? 'Already set on Item Mapping' : '';
      temp.skuMapping = newValue ? 'Already set on Item Mapping' : '';
    }
    setFormItemMappingErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleCancelOnlickItemMapping = () => {
    setIsNewItemMapping(false);
    setItemMappingDetail(initialItemMapping);
    setFormItemMappingErrors({});
  };

  const handleEditOnlickItemMapping = (params) => {
    setItemMappingDetail(params);
    setIsNewItemMapping(true);
  };

  const handleDeleteOnlickItemMapping = (params) => {
    const datas = [...formRules.itemMappingSkus];
    const newValues = datas.filter((r) => r.dataGridId !== params.dataGridId);
    setFormRules((prev) => ({
      ...prev,
      itemMappingSkus: [...newValues],
    }));
  };

  const handleOnSaveEditItemMapping = async (e: any) => {
    e.preventDefault();

    if (!validateOnlickItemMapping()) {
      return;
    }

    const datas = [...formRules.itemMappingSkus];

    const newValue = datas?.find(
      (row) => row?.dataGridId === itemMappingDetail?.dataGridId,
    );
    if (newValue !== undefined && newValue !== null) {
      const newState = datas.map((obj) => {
        if (obj.dataGridId === itemMappingDetail?.dataGridId) {
          return {
            ...obj,
            customerRuleManagementSkuMappingId:
              itemMappingDetail?.customerRuleManagementSkuMappingId,
            itemId: itemMappingDetail?.itemId,
            sku: itemMappingDetail?.sku,
            skuMapping: itemMappingDetail?.skuMapping,
          };
        }
        return obj;
      });
      setFormRules((prev) => ({
        ...prev,
        itemMappingSkus: [...newState],
      }));
    } else {
      setFormRules((prev) => ({
        ...prev,
        itemMappingSkus: [...datas, itemMappingDetail],
      }));
    }
    handleCancelOnlickItemMapping();
  };

  const skuMappingColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEditOnlickItemMapping(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleDeleteOnlickItemMapping(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'sku',
      flex: 1,
      minWidth: 150,
      renderHeader: () => <Typography fontWeight="bold">SKU</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.sku}
        </Typography>
      ),
    },
    {
      field: 'skuMapping',
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <Typography fontWeight="bold">SKU Mapping</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.skuMapping}
        </Typography>
      ),
    },
  ];
  // #endregion

  // #region CARRIER SELECTION ---------------------------------------------------

  const handleEditOnClickCarrierSelection = (params) => {
    setCarrierSelectionDetail(params);

    if (customerType === CustomerType.Customer) {
      handleOnCarrierChangeSetting(params?.row?.carrierSelectionId);
    } else if (customerType === CustomerType.WarehouseCustomer) {
      handleOnCarrierChange(params?.row?.carrierSelectionId);
    }

    setIsNewOrEditCarrierSelection(true);
  };

  const handleCancelOnlickCarrierSelection = () => {
    setIsNewOrEditCarrierSelection(false);
    setCarrierSelectionDetail(initialCarrierSelection);
    setFormCarrierSelectionErrors({});
  };

  const validateOnclickCarrierSelection = () => {
    const temp: any = {};
    const datas = [...formRules.carrierSelections];

    temp.carrierSelection = carrierSelectionDetail.carrierSelection.value
      ? ''
      : 'This field is required';

    temp.carrierSelectionService = carrierSelectionDetail
      .carrierSelectionService.value
      ? ''
      : 'This field is required';

    if (datas.length > 0) {
      const newValue = datas?.find(
        (row) =>
          row?.carrierSelection?.value ===
            carrierSelectionDetail?.carrierSelection?.value &&
          row?.carrierSelectionService?.value ===
            carrierSelectionDetail?.carrierSelectionService?.value,
      );

      temp.carrierSelection = newValue
        ? 'Already set on Carrier Selection'
        : '';
      temp.carrierSelectionService = newValue
        ? 'Already set on Carrier Selection'
        : '';
    }

    setFormCarrierSelectionErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleOnSaveEditCarrierSelection = async (e: any) => {
    e.preventDefault();

    if (!validateOnclickCarrierSelection()) {
      return;
    }

    const datas = [...formRules.carrierSelections];

    const newValue = datas?.find(
      (row) => row?.dataGridId === carrierSelectionDetail?.dataGridId,
    );
    if (newValue !== undefined && newValue !== null) {
      const newState = datas.map((obj) => {
        if (obj.dataGridId === carrierSelectionDetail?.dataGridId) {
          return {
            ...obj,
            customerRuleManagementCarrierId:
              carrierSelectionDetail?.customerRuleManagementCarrierId,
            carrierSelection: carrierSelectionDetail?.carrierSelection,
            carrierSelectionId: carrierSelectionDetail.carrierSelectionId,
            carrierSelectionName: carrierSelectionDetail.carrierSelectionName,
            carrierSelectionService:
              carrierSelectionDetail?.carrierSelectionService,
            carrierSelectionServiceId:
              carrierSelectionDetail?.carrierSelectionServiceId,
            carrierSelectionServiceName:
              carrierSelectionDetail?.carrierSelectionServiceName,
            carrierZipcode: carrierSelectionDetail?.carrierZipcode,
            carrierAccountName: carrierSelectionDetail?.carrierAccountName,
            carrierthirdPartyAccount:
              carrierSelectionDetail?.carrierthirdPartyAccount,
          };
        }
        return obj;
      });
      setFormRules((prev) => ({
        ...prev,
        carrierSelections: [...newState],
      }));
    } else {
      setFormRules((prev) => ({
        ...prev,
        carrierSelections: [...datas, carrierSelectionDetail],
      }));
    }
    handleCancelOnlickCarrierSelection();
  };

  const handleDeleteOnClickCarrierSelection = (params) => {
    const datas = [...formRules.carrierSelections];
    const newValues = datas.filter((r) => r.dataGridId !== params.dataGridId);
    setFormRules((prev) => ({
      ...prev,
      carrierSelections: [...newValues],
    }));
  };

  const handleOnChangeCarrierSelection = async (inputCarrierId?: any) => {
    if (inputCarrierId) {
      const result = await getOrderCarrierservices(inputCarrierId);

      const setArray = result.map((c: any) => ({
        value: c.value,
        label: c.text,
        isShippingRate: c.isShippingRate,
        serviceCode: c.serviceCode,
      }));

      setCarrierTypeServicesShipping(setArray);
    } else {
      setCarrierTypeServicesShipping([]);
    }
    return null;
  };

  const carrierSelectionColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEditOnClickCarrierSelection(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleDeleteOnClickCarrierSelection(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'carrierSelectionName',
      flex: 1,
      minWidth: 130,
      renderHeader: () => <Typography fontWeight="bold">Carrier</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.carrierSelectionName}
        </Typography>
      ),
    },
    {
      field: 'carrierSelectionServiceName',
      flex: 1,
      minWidth: 130,
      renderHeader: () => (
        <Typography fontWeight="bold">Carrier Service</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.carrierSelectionServiceName}
        </Typography>
      ),
    },
    {
      field: 'carrierZipcode',
      flex: 1,
      minWidth: 90,
      renderHeader: () => <Typography fontWeight="bold">Zip Code</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.carrierZipcode}
        </Typography>
      ),
    },
    {
      field: 'carrierAccountName',
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <Typography fontWeight="bold">Account Name</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.carrierAccountName}
        </Typography>
      ),
    },
    {
      field: 'carrierthirdPartyAccount',
      flex: 1,
      minWidth: 145,
      renderHeader: () => (
        <Typography fontWeight="bold">3rd Party Account</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.carrierthirdPartyAccount}
        </Typography>
      ),
    },
  ];
  // #endregion

  // HANDLING CARRIER SERVICE BASED ON SELECTED CARRIERS -- then ship using
  useEffect(() => {
    if (customerType === CustomerType.Customer) {
      handleOnCarrierChangeArrayListSetting(formRules?.carrierSelections);
    } else if (customerType === CustomerType.WarehouseCustomer) {
      handleOnCarrierChangeArrayList(formRules?.carrierSelections);
    }
  }, [IsOrderAIRules, isShowRulesForm, formRules?.carrierSelections]);

  // useEffect(() => {
  //   if (formRules?.shippingAndCarrier) {
  //     if (customerType === CustomerType.Customer) {
  //       // TODO IN CUSTOMER PAGE
  //       setCarrierTypeServicesShipping(
  //         Array.isArray(shippoCarriers)
  //           ? shippoCarriers
  //               .filter((x) => x.active)
  //               .map((v) => ({
  //                 value: v.carrierId,
  //                 label: v.accountNickName,
  //               }))
  //           : [{ value: 0, label: '' }],
  //       );
  //     } else {
  //       setCarrierTypeServicesShipping(
  //         Array.isArray(carrierTypeServiceOptionShipping)
  //           ? carrierTypeServiceOptionShipping
  //           : [{ value: 0, label: '' }],
  //       );
  //     }
  //   } else {
  //     setCarrierTypeServicesShipping([]);
  //   }
  // }, [carrierTypeServiceOptionShipping]);

  // -- then ship using DROPDOWN
  useEffect(() => {
    if (
      formRules?.carrierSelections &&
      formRules?.carrierSelections.length > 0
    ) {
      if (customerType === CustomerType.Customer) {
        // TODO IN CUSTOMER PAGE
        setCarrierTypeServicesCarrierType(
          Array.isArray(carrierTypeServiceOptionCarrierTypeSetting)
            ? carrierTypeServiceOptionCarrierTypeSetting
            : [{ value: 0, label: '' }],
        );
      } else {
        setCarrierTypeServicesCarrierType(
          Array.isArray(carrierTypeServiceOptionCarrierType)
            ? carrierTypeServiceOptionCarrierType
            : [{ value: 0, label: '' }],
        );
      }
    } else {
      setCarrierTypeServicesCarrierType([]);
    }
  }, [
    carrierTypeServiceOptionCarrierType,
    carrierTypeServiceOptionCarrierTypeSetting,
  ]);

  useEffect(() => {
    if (customerType === CustomerType.WarehouseCustomer) {
      if (defaultPackgeTypeOption && defaultPackgeTypeOption.length > 0) {
        setDefaultPackageOption(
          Array.isArray(defaultPackgeTypeOption)
            ? defaultPackgeTypeOption
            : [{ value: 0, label: '' }],
        );
      }
    } else if (customerType === CustomerType.Customer)
      if (
        defaultPackgeTypeOptionSetting &&
        defaultPackgeTypeOptionSetting.length > 0
      )
        setDefaultPackageOption(
          Array.isArray(defaultPackgeTypeOptionSetting)
            ? defaultPackgeTypeOptionSetting
            : [{ value: 0, label: '' }],
        );
  }, [defaultPackgeTypeOption, defaultPackgeTypeOptionSetting]);
  // ----------------------------------------------------

  useEffect(() => {
    if (customerType === CustomerType.Customer) {
      setCountrySelectionOptions(
        Array.isArray(countriesSettings)
          ? countriesSettings.map((v) => ({
              value: v.countryId,
              label: v.name,
            }))
          : [{ value: 0, label: '' }],
      );
      setCarrierTypes(
        Array.isArray(carrierTypesOptionSettings)
          ? carrierTypesOptionSettings
          : [{ value: 0, label: '' }],
      );
    } else {
      setCountrySelectionOptions(
        Array.isArray(countries)
          ? countries.map((v) => ({
              value: v.countryId,
              label: v.name,
            }))
          : [{ value: 0, label: '' }],
      );
      setCarrierTypes(
        Array.isArray(shippoCarriers)
          ? shippoCarriers
              .filter((x) => x.active)
              .map((v) => ({
                value: v.carrierId,
                label: v.accountNickName,
              }))
          : [{ value: 0, label: '' }],
      );
    }

    const optionsItemsCustomer = onLoadUItemsByCustomerData();
    optionsItemsCustomer
      .then((opt) => {
        setItemsOption(
          opt.map((c: any) => ({
            value: c.itemId,
            label: c.sku,
          })),
        );
      })
      .catch((err) => {
        // console.log(err);
      });
    const optionsLocation = onLoadGetLocation();
    optionsLocation
      .then((opt) => {
        setOptionLocation(
          opt.map((c: any) => ({
            value: c.binId,
            label: c.name,
          })),
        );
      })
      .catch((err) => {
        // console.log(err);
      });

    getShippingIntegrationCompanies().then((res) => {
      setOptionShippingIntegration(
        res.map((c: any) => ({
          value: c.value,
          label: c.text,
        })),
      );
    });

    GetLockOrderEditsAfterDDL().then((res: DropdownListItem[]) => {
      setLockOrderEditsAfterDDL(res);
    });
  }, [currentUser.Claim_CustomerId]);

  useEffect(() => {
    if (customerType === CustomerType.Customer) {
      setSkuMappingOptions(
        Array.isArray(skuMappingOptionSettings)
          ? skuMappingOptionSettings.map((v) => ({
              sku: v.sku,
              itemId: v.itemId,
              description: v.description,
            }))
          : [{ value: 0, label: '' }],
      );
    } else {
      setSkuMappingOptions(
        Array.isArray(skuMappingOption)
          ? skuMappingOption.map((v) => ({
              sku: v.sku,
              itemId: v.itemId,
              description: v.description,
            }))
          : [{ value: 0, label: '' }],
      );
    }
  }, [skuMappingOption, currentUser.Claim_CustomerId]);

  useEffect(() => {
    if (isViewForm) {
      setIsShowRulesForm(false);
      setIsManageClientDefault(false);
    }
  }, [isViewForm]);

  return (
    <div>
      {IsOrderAIRules && !isShowRulesForm && !isShowRulesFormSettings ? (
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: '20px' }}
        >
          <MUIGrid item xs={4}>
            <Typography variant="subtitle1" fontWeight="bold">
              Order Rules
            </Typography>
          </MUIGrid>
          <MUIGrid
            item
            xs={8}
            sx={{ display: 'flex', justifyContent: 'right' }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ marginRight: '10px' }}
              onClick={() => {
                if (customerType === CustomerType.WarehouseCustomer) {
                  handleAddEditRulesManagementOnClick();
                } else if (customerType === CustomerType.Customer) {
                  setIsManageClientDefaultSetting(true);
                }
              }}
            >
              Manage Client Defaults
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                if (customerType === CustomerType.WarehouseCustomer) {
                  handleAddOrderSourceOnlick();
                } else if (customerType === CustomerType.Customer) {
                  handleAddOrderSourceOnlickSetting();
                }
              }}
            >
              Add Order Rule
            </Button>
          </MUIGrid>

          <MUIGrid item xs={12}>
            <DatagridStandard
              autoHeight
              rows={orderSourceRulesDataRow}
              density="compact"
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              columns={orderAIRulesColumns}
              rowsPerPageOptions={[15]}
              pageSize={15}
              hideFooterSelectedRowCount
              getRowId={() => Math.random()}
              hideFooter
            />
          </MUIGrid>
        </MUIGrid>
      ) : (
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: '20px', paddingBottom: '0px' }}
        >
          {IsOrderAIRules && (
            <MUIGrid item sm={12}>
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item sm={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      className="textNoWrap"
                      sx={{ paddingRight: '5px' }}
                    >
                      Order Rule*
                    </Typography>
                    <TextField
                      size="small"
                      sx={{
                        width: '40%',
                        backgroundColor: '#ffffff !important',
                      }}
                      placeholder=""
                      value={formRules?.orderSource}
                      InputProps={{
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      onChange={(e) => {
                        setFormRules((prev) => ({
                          ...prev,
                          orderSource: e.target.value,
                        }));
                      }}
                      {...(formErrors?.orderSource && {
                        error: true,
                        helperText: formErrors?.orderSource,
                      })}
                      required
                      {...(customerType === CustomerType.WarehouseCustomer &&
                        isRulesFormEditMode && {
                          disabled: true,
                        })}
                      {...(customerType === CustomerType.Customer &&
                        isRulesFormEditModeSettings && {
                          disabled: true,
                        })}
                    />
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      className="textNoWrap"
                      sx={{ padding: '8px' }}
                    >
                      Description*
                    </Typography>
                    <TextField
                      size="small"
                      sx={{
                        width: '50%',
                        backgroundColor: '#ffffff !important',
                      }}
                      placeholder=""
                      value={formRules?.orderSourceDescription}
                      InputProps={{
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      onChange={(e) => {
                        setFormRules((prev) => ({
                          ...prev,
                          orderSourceDescription: e.target.value,
                        }));
                      }}
                      {...(formErrors?.orderSourceDescription && {
                        error: true,
                        helperText: formErrors?.orderSourceDescription,
                      })}
                      required
                      disabled={isViewForm}
                    />
                  </Box>
                </MUIGrid>

                <MUIGrid
                  item
                  sm={12}
                  md={6}
                  sx={{ paddingLeft: '15px !important' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      className="textNoWrap"
                      sx={{ paddingRight: '10px' }}
                    >
                      Long Description
                    </Typography>
                    <TextField
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      placeholder=""
                      value={formRules?.orderSourceLongDescription}
                      InputProps={{
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      onChange={(e) => {
                        setFormRules((prev) => ({
                          ...prev,
                          orderSourceLongDescription: e.target.value,
                        }));
                      }}
                      disabled={isViewForm}
                    />
                  </Box>
                </MUIGrid>
              </MUIGrid>
              <Divider sx={{ mt: 2 }} />
            </MUIGrid>
          )}
          {!IsRulesManagementTab && (
            <>
              <MUIGrid item sm={12} md={6}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                    Pack & Ship
                  </Typography>
                  {IsOrderAIRules && (
                    <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                      Use Client Defaults
                    </Typography>
                  )}
                </Box>

                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    border: '0.5px solid #ebeaea',
                    padding: '15px !important',
                    marginBottom: 2,
                  }}
                >
                  {/*  Signature Options */}
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%' }}
                  >
                    <MUIGrid item xs={10}>
                      <Box sx={{ display: 'flex' }}>
                        <OrderRulesIcon name="signatureIcon" />
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              mr={2}
                            >
                              Require Signature
                            </Typography>

                            {!formRules?.signatureOptionUseDefault && (
                              <Switch
                                checked={formRules?.signatureOption}
                                onChange={(v) =>
                                  setFormRules((e) => ({
                                    ...e,
                                    signatureOption: v.target.checked,
                                  }))
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                                disabled={isViewForm}
                              />
                            )}
                          </Box>

                          <Typography variant="body2">
                            This section will allow you to require signature for
                            client shipments
                          </Typography>
                        </Box>
                      </Box>
                    </MUIGrid>
                    {IsOrderAIRules && (
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.signatureOptionUseDefault}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              signatureOptionUseDefault: v.target.checked,
                              signatureOption: false,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    )}
                    {formRules?.signatureOption && (
                      <>
                        <MUIGrid item xs={4} sx={{ placeSelf: 'center' }}>
                          <Typography variant="body2">
                            For order amount is more than
                          </Typography>
                        </MUIGrid>
                        <MUIGrid item xs={4}>
                          <Input
                            size="small"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            placeholder="Set order value threshold"
                            type="number"
                            value={formRules?.signatureOptionOrderValue}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            onChange={(v) =>
                              setFormRules((e) => ({
                                ...e,
                                signatureOptionOrderValue: v.target.value,
                              }))
                            }
                            disabled={isViewForm}
                          />
                        </MUIGrid>
                        <MUIGrid item xs={4}>
                          <Autocomplete
                            onChange={(
                              event: any,
                              newValue: ISignatureType,
                            ) => {
                              if (newValue !== null) {
                                setFormRules((e) => ({
                                  ...e,
                                  signatureType: newValue,
                                }));
                              } else {
                                setFormRules((e) => ({
                                  ...e,
                                  signatureType: null,
                                }));
                              }
                            }}
                            options={signatureTypes}
                            value={formRules?.signatureType} // {value: , label: }
                            getOptionLabel={(option: any) => option.label}
                            isOptionEqualToValue={(option, selected) =>
                              option.value === selected.value
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option.value}>
                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                autoComplete="off"
                                id="outlined-required"
                                label="Signature Type"
                                size="small"
                                sx={{ width: '100%' }}
                              />
                            )}
                            disabled={isViewForm}
                          />
                        </MUIGrid>

                        <MUIGrid item xs={4} sx={{ placeSelf: 'center' }}>
                          <Typography variant="body2">
                            For order weight is more than
                          </Typography>
                        </MUIGrid>
                        <MUIGrid item xs={4}>
                          <Input
                            size="small"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            placeholder="Set order weight threshold"
                            type="number"
                            value={formRules?.signatureOptionOrderWeight}
                            onChange={(v) =>
                              setFormRules((e) => ({
                                ...e,
                                signatureOptionOrderWeight: v.target.value,
                              }))
                            }
                            disabled={isViewForm}
                          />
                        </MUIGrid>
                        <MUIGrid item xs={4}>
                          <Autocomplete
                            onChange={(
                              event: any,
                              newValue: ISignatureType,
                            ) => {
                              if (newValue !== null) {
                                setFormRules((e) => ({
                                  ...e,
                                  signatureTypeWeight: newValue,
                                }));
                              } else {
                                setFormRules((e) => ({
                                  ...e,
                                  signatureTypeWeight: null,
                                }));
                              }
                            }}
                            id="controllable-states"
                            options={signatureTypes}
                            value={formRules?.signatureTypeWeight}
                            getOptionLabel={(option: any) => option.label}
                            isOptionEqualToValue={(option, selected) =>
                              option.value === selected.value
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option.value}>
                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete:
                                    autoCompleteTimeStamp().toString(),
                                }}
                                autoComplete="off"
                                id="outlined-required"
                                label="Signature Type"
                                size="small"
                                sx={{ width: '100%' }}
                              />
                            )}
                            disabled={isViewForm}
                          />
                        </MUIGrid>
                      </>
                    )}
                  </MUIGrid>

                  {/* Insurance Option ------------------------- from ORDER SOURCE */}
                  <MUIGrid
                    container
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%' }}
                  >
                    <MUIGrid item xs={10}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <OrderRulesIcon name="insuranceIcon" />
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          mr={2}
                        >
                          Require Insurance
                        </Typography>
                        {!formRules?.insuranceOptionUseDefault && (
                          <Switch
                            checked={formRules?.insuranceOption}
                            onChange={(v) =>
                              setFormRules((e) => ({
                                ...e,
                                insuranceOption: v.target.checked,
                              }))
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                            disabled={isViewForm}
                          />
                        )}
                      </Box>
                    </MUIGrid>

                    {IsOrderAIRules && (
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.insuranceOptionUseDefault}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              insuranceOptionUseDefault: v.target.checked,
                              insuranceOption: false,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    )}
                  </MUIGrid>
                  {/*  ------------------------- from ORDER SOURCE */}
                  {formRules?.insuranceOption && (
                    <MUIGrid
                      container
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%', alignItems: 'center' }}
                      mb={0.8}
                    >
                      <MUIGrid item xs={3}>
                        <Typography variant="body2">
                          For order amount is more than
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <Input
                          size="small"
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                          placeholder="Set order value threshold"
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          value={formRules?.insuranceOptionOrderValue}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              insuranceOptionOrderValue: v.target.value,
                            }))
                          }
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <Typography variant="body2">
                          For order weight is more than
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <Input
                          size="small"
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                          placeholder="Set order weight threshold"
                          type="number"
                          value={formRules?.insuranceOptionOrderWeight}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              insuranceOptionOrderWeight: v.target.value,
                            }))
                          }
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    </MUIGrid>
                  )}

                  {/* Carrier Selection */}
                  <MUIGrid
                    container
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%' }}
                  >
                    <MUIGrid item xs={10}>
                      <Box sx={{ display: 'flex' }}>
                        <OrderRulesIcon name="carrierIcon" />
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              mr={2}
                            >
                              Carrier Selection
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: 'small' }}
                          >
                            This section will allow you to specify a list of
                            carriers to be used if the amount is over the set
                            threshold.
                          </Typography>
                        </Box>
                      </Box>
                    </MUIGrid>

                    {IsOrderAIRules && (
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.carrierSelectionUseDefault}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              carrierSelectionUseDefault: v.target.checked,
                              carrierSelection: false,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    )}
                  </MUIGrid>
                  {/* THIRD ROW */}
                  {!formRules?.carrierSelectionUseDefault && (
                    <MUIGrid
                      container
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%', alignItems: 'center' }}
                      mb={0.8}
                      mt={0.3}
                    >
                      {isNewOrEditCarrierSelection ? (
                        <>
                          <MUIGrid item xs={6}>
                            <Autocomplete
                              disabled={isViewForm}
                              id="controllable-states"
                              options={carrierTypes}
                              value={
                                carrierSelectionDetail?.carrierSelection || {}
                              }
                              onChange={(event: any, newValue) => {
                                if (newValue !== null) {
                                  setCarrierSelectionDetail((e) => ({
                                    ...e,
                                    carrierSelection: newValue,
                                    carrierSelectionId: newValue.value,
                                    carrierSelectionName: newValue.label,
                                    carrierSelectionService: {
                                      value: null,
                                      label: '',
                                    },
                                    carrierSelectionServiceId: null,
                                    carrierSelectionServiceName: '',
                                    carrierZipcode: '',
                                    carrierAccountName: '',
                                    carrierthirdPartyAccount: '',
                                  }));
                                  handleOnChangeCarrierSelection(
                                    newValue.value,
                                  );
                                } else {
                                  setCarrierSelectionDetail((e) => ({
                                    ...e,
                                    carrierSelection: null,
                                    carrierSelectionId: null,
                                    carrierSelectionName: '',
                                    carrierSelectionService: {
                                      value: null,
                                      label: '',
                                    },
                                    carrierSelectionServiceId: null,
                                    carrierSelectionServiceName: '',
                                    carrierZipcode: '',
                                    carrierAccountName: '',
                                    carrierthirdPartyAccount: '',
                                  }));
                                  handleOnChangeCarrierSelection(null);
                                }
                              }}
                              getOptionLabel={(option: CountryType) =>
                                option.label
                              }
                              isOptionEqualToValue={(option, selected) =>
                                option.value === selected.value
                              }
                              renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete:
                                      autoCompleteTimeStamp().toString(),
                                  }}
                                  autoComplete="off"
                                  id="outlined-required"
                                  label="Carrier"
                                  size="small"
                                  sx={{ width: '100%', background: '#ffffff' }}
                                  required
                                  {...(formCarrierSelectionErrors.carrierSelection && {
                                    error: true,
                                    helperText:
                                      formCarrierSelectionErrors.carrierSelection,
                                  })}
                                />
                              )}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={6}>
                            <Autocomplete
                              id="controllable-states"
                              options={carrierTypeServicesShipping}
                              value={
                                carrierSelectionDetail?.carrierSelectionService ||
                                {}
                              }
                              onChange={(event: any, newValue) => {
                                if (newValue !== null) {
                                  setCarrierSelectionDetail((e) => ({
                                    ...e,
                                    carrierSelectionService: newValue,
                                    carrierSelectionServiceId: newValue.value,
                                    carrierSelectionServiceName: newValue.label,
                                  }));
                                } else {
                                  setCarrierSelectionDetail((e) => ({
                                    ...e,
                                    carrierSelectionService: null,
                                    carrierSelectionServiceId: null,
                                    carrierSelectionServiceName: null,
                                  }));
                                }
                              }}
                              getOptionLabel={(option: any) => option.label}
                              isOptionEqualToValue={(option, selected) =>
                                option.value === selected.value
                              }
                              renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete:
                                      autoCompleteTimeStamp().toString(),
                                  }}
                                  autoComplete="off"
                                  id="outlined-required"
                                  label="Carrier Services"
                                  size="small"
                                  sx={{ width: '100%' }}
                                  required
                                  {...(formCarrierSelectionErrors.carrierSelectionService && {
                                    error: true,
                                    helperText:
                                      formCarrierSelectionErrors.carrierSelectionService,
                                  })}
                                />
                              )}
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="ZipCode"
                              value={carrierSelectionDetail?.carrierZipcode}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setCarrierSelectionDetail((prev) => ({
                                  ...prev,
                                  carrierZipcode: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="Account Name"
                              value={carrierSelectionDetail?.carrierAccountName}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setCarrierSelectionDetail((prev) => ({
                                  ...prev,
                                  carrierAccountName: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="3rd Party Account"
                              value={
                                carrierSelectionDetail?.carrierthirdPartyAccount
                              }
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setCarrierSelectionDetail((prev) => ({
                                  ...prev,
                                  carrierthirdPartyAccount: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                          <MUIGrid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'right',
                            }}
                          >
                            <Button
                              variant="outlined"
                              size="medium"
                              sx={{
                                borderColor: 'rgb(5, 47, 255)',
                                color: 'rgb(5, 47, 255)',
                                textTransform: 'capitalize',
                                margin: '0px 10px',
                              }}
                              onClick={() => {
                                handleCancelOnlickCarrierSelection();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              size="medium"
                              sx={{ textTransform: 'capitalize' }}
                              onClick={(e) => {
                                handleOnSaveEditCarrierSelection(e);
                              }}
                            >
                              Save
                            </Button>
                          </MUIGrid>
                        </>
                      ) : (
                        <MUIGrid item xs={12} sx={{ textAlign: ' right' }}>
                          <Button
                            variant="contained"
                            size="medium"
                            sx={{ width: '30%', textTransform: 'capitalize' }}
                            onClick={() => {
                              setIsNewOrEditCarrierSelection(true);
                              setCarrierSelectionDetail((e) => ({
                                ...e,
                                dataGridId: Math.random(),
                              }));
                            }}
                          >
                            Select Carrier
                          </Button>
                        </MUIGrid>
                      )}
                      <MUIGrid item xs={12}>
                        <DatagridStandard
                          autoHeight
                          rows={formRules?.carrierSelections || []}
                          density="compact"
                          disableColumnFilter
                          disableColumnSelector
                          disableDensitySelector
                          disableColumnMenu
                          columns={carrierSelectionColumns}
                          rowsPerPageOptions={[5]}
                          pageSize={5}
                          hideFooterSelectedRowCount
                          getRowId={() => Math.random()}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={12}>
                        <Divider />
                      </MUIGrid>

                      <MUIGrid item xs={12}>
                        <MUIGrid
                          container
                          spacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ placeItems: 'center' }}
                        >
                          <MUIGrid item xs={4}>
                            <Typography variant="body2">
                              If order amount total is more than
                            </Typography>
                          </MUIGrid>
                          <MUIGrid item xs={2}>
                            <TextField
                              size="small"
                              sx={{
                                backgroundColor: '#ffffff !important',
                              }}
                              type="number"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={formRules?.carrierSelectionOrderValue}
                              onChange={(v) =>
                                setFormRules((e) => ({
                                  ...e,
                                  carrierSelectionOrderValue: v.target.value,
                                }))
                              }
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <Typography variant="body2">
                              OR Order Weight is more than lbs
                            </Typography>
                          </MUIGrid>
                          <MUIGrid item xs={2}>
                            <TextField
                              size="small"
                              sx={{
                                backgroundColor: '#ffffff !important',
                              }}
                              type="number"
                              value={formRules?.carrierSelectionOrderWeight}
                              onChange={(v) =>
                                setFormRules((e) => ({
                                  ...e,
                                  carrierSelectionOrderWeight: v.target.value,
                                }))
                              }
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={3}>
                            <Typography variant="body2">
                              then ship using
                            </Typography>
                          </MUIGrid>
                          <MUIGrid item xs={9}>
                            <Autocomplete
                              multiple
                              disableCloseOnSelect
                              options={carrierTypeServicesCarrierType}
                              value={formRules?.carrierSelectionServices || []}
                              onChange={(event: any, newValue) => {
                                if (newValue !== null) {
                                  setFormRules((e) => ({
                                    ...e,
                                    carrierSelectionServices: newValue,
                                  }));
                                } else {
                                  setFormRules((e) => ({
                                    ...e,
                                    carrierSelectionServices: null,
                                  }));
                                }
                              }}
                              id="controllable-states"
                              getOptionLabel={(option: any) => option.label}
                              isOptionEqualToValue={(option, selected) =>
                                option.value === selected.value
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete:
                                      autoCompleteTimeStamp().toString(),
                                  }}
                                  autoComplete="off"
                                  id="outlined-required"
                                  label="Carrier Services"
                                  size="small"
                                  sx={{ width: '100%' }}
                                />
                              )}
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                        </MUIGrid>
                      </MUIGrid>
                    </MUIGrid>
                  )}

                  {/* 4th ROW */}
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%' }}
                    mb={1}
                  >
                    <MUIGrid item xs={10}>
                      <Box sx={{ display: 'flex' }}>
                        <OrderRulesIcon name="printCartonIcon" />
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              mr={2}
                            >
                              Print Carton Content Label
                            </Typography>
                            {!formRules?.printCartonContentLabelUseDefault && (
                              <Switch
                                checked={formRules?.printCartonContentLabel}
                                onChange={(v) =>
                                  setFormRules((e) => ({
                                    ...e,
                                    printCartonContentLabel: v.target.checked,
                                  }))
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                                disabled={isViewForm}
                              />
                            )}
                          </Box>
                          <Typography variant="body2">
                            Determine whether to print the Carton Content Label
                            or not on shipment boxes.
                          </Typography>
                        </Box>
                      </Box>
                    </MUIGrid>
                    {IsOrderAIRules && (
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.printCartonContentLabelUseDefault}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              printCartonContentLabelUseDefault:
                                v.target.checked,
                              printCartonContentLabel: false,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    )}
                  </MUIGrid>

                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%' }}
                    mb={1}
                  >
                    <MUIGrid item xs={10}>
                      <Box sx={{ display: 'flex' }}>
                        <OrderRulesIcon name="shippingDocSettingIcon" />
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              mr={2}
                            >
                              Shipping Document Settings
                            </Typography>

                            {!formRules?.shippingDocumentSettingsUseDefault && (
                              <Switch
                                checked={formRules?.shippingDocumentSettings}
                                onChange={(v) =>
                                  setFormRules((e) => ({
                                    ...e,
                                    shippingDocumentSettings: v.target.checked,
                                  }))
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                                disabled={isViewForm}
                              />
                            )}
                          </Box>
                          <Typography variant="body2">
                            Determine whether or not to print a shipment
                            document.
                          </Typography>
                        </Box>
                      </Box>
                    </MUIGrid>
                    {IsOrderAIRules && (
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={
                            formRules?.shippingDocumentSettingsUseDefault
                          }
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              shippingDocumentSettingsUseDefault:
                                v.target.checked,
                              shippingDocumentSettings: false,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    )}
                  </MUIGrid>

                  {/*  ------------------------- from ORDER SOURCE */}
                  {formRules?.shippingDocumentSettings && (
                    <>
                      <MUIGrid
                        container
                        spacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ width: '100%' }}
                        mb={1}
                      >
                        <MUIGrid item xs={12}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Shipping Document Options
                          </Typography>
                        </MUIGrid>
                        <MUIGrid item xs={12}>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            id="checkboxes-tags-demo"
                            value={formRules?.shippingDocuments}
                            onChange={(event: any, newValue) => {
                              if (newValue !== null) {
                                setFormRules((e) => ({
                                  ...e,
                                  shippingDocuments: newValue,
                                }));
                              } else {
                                setFormRules((e) => ({
                                  ...e,
                                  shippingDocuments: null,
                                }));
                              }
                            }}
                            options={documentTypes}
                            getOptionLabel={(option: any) => option.label}
                            isOptionEqualToValue={(option, selected) =>
                              option.value === selected.value
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete:
                                    autoCompleteTimeStamp().toString(),
                                }}
                                autoComplete="off"
                                label="Shipping Documents"
                                size="small"
                              />
                            )}
                            disabled={isViewForm}
                          />
                        </MUIGrid>
                        <MUIGrid item xs={4}>
                          <Button
                            sx={{
                              height: '90px',
                              maxWidth: '100%',
                              width: '100px',
                              border: '2px dashed #4171ff !important',
                              backgroundColor: 'rgb(216 232 255) !important',
                              borderRadius: '5px !important',
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignContent: 'center',
                            }}
                            size="medium"
                            variant="contained"
                            component="label"
                            disabled={isViewForm}
                          >
                            <UploadFileIcon
                              sx={{
                                fontSize: '40px',
                                color: 'black !important',
                                mb: 1,
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: '7.5px',
                                color: 'black !important',
                              }}
                              fontWeight="bold"
                            >
                              Upload Logo
                            </Typography>
                            <input
                              hidden
                              type="file"
                              onChange={onImageChange}
                              className="filetype"
                              accept="image/png, image/jpeg"
                            />
                          </Button>
                        </MUIGrid>
                        <MUIGrid item xs={8}>
                          {formRules?.printCartonContentLabelImage ? (
                            <input
                              disabled
                              type="image"
                              src={`data:image/jpeg;base64,${formRules?.printCartonContentLabelImage}`}
                              alt="preview image"
                              width="90"
                              accept="image/*"
                            />
                          ) : (
                            <>
                              <Typography fontWeight="bold" variant="subtitle2">
                                Upload Logo Image
                              </Typography>
                              <Typography
                                sx={{ fontSize: 'x-small', color: 'red' }}
                              >
                                (Only JPG & PNG)
                              </Typography>
                            </>
                          )}
                        </MUIGrid>
                      </MUIGrid>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          mr={2}
                        >
                          Override Ship From Address
                        </Typography>
                        <Switch
                          checked={formRules?.overrideShipFromAddress}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              overrideShipFromAddress: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </Box>

                      {formRules?.overrideShipFromAddress && (
                        <MUIGrid
                          container
                          spacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ width: '100%' }}
                          mb={1}
                        >
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="Name"
                              value={formRules?.shipFromName}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  shipFromName: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="Address"
                              value={formRules?.shipFromAddress}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  shipFromAddress: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="City"
                              value={formRules?.shipFromCity}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  shipFromCity: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="State"
                              value={formRules?.shipFromState}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  shipFromState: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="Zip Code"
                              value={formRules?.shipFromZip}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  shipFromZip: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <Autocomplete
                              disableClearable
                              disabled={isViewForm}
                              id="controllable-states"
                              options={countrySelectionOptions}
                              getOptionLabel={(option: CountryType) =>
                                option.label
                              }
                              value={formRules?.shipFromCountry}
                              isOptionEqualToValue={(option, selected) =>
                                option.value === selected.value
                              }
                              renderOption={(props, option) => (
                                <li
                                  data-name="shipFrom"
                                  data-state="country"
                                  {...props}
                                >
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete:
                                      autoCompleteTimeStamp().toString(),
                                  }}
                                  autoComplete="off"
                                  id="outlined-required"
                                  label="Country"
                                  size="small"
                                  sx={{ width: '100%', background: '#ffffff' }}
                                  required
                                />
                              )}
                              onChange={(event: any, newValue: CountryType) => {
                                if (newValue !== null) {
                                  setFormRules((e) => ({
                                    ...e,
                                    shipFromCountry: newValue,
                                  }));
                                } else {
                                  setFormRules((e) => ({
                                    ...e,
                                    shipFromCountry: null,
                                  }));
                                }
                              }}
                            />
                          </MUIGrid>
                        </MUIGrid>
                      )}
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          mr={2}
                        >
                          Override Return To Address
                        </Typography>
                        <Switch
                          checked={formRules?.overrideReturnFromAddress}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              overrideReturnFromAddress: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </Box>

                      {formRules?.overrideReturnFromAddress && (
                        <MUIGrid
                          container
                          spacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ width: '100%' }}
                          mb={1}
                        >
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              placeholder="Name"
                              label={formRules?.returnName}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  returnName: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="Address"
                              value={formRules?.returnAddress}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  returnAddress: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="City"
                              value={formRules?.returnCity}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  returnCity: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="State"
                              value={formRules?.returnState}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  returnState: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="Zip Code"
                              value={formRules?.returnZip}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setFormRules((prev) => ({
                                  ...prev,
                                  returnZip: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              required
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <Autocomplete
                              disableClearable
                              disabled={isViewForm}
                              id="controllable-states"
                              options={countrySelectionOptions}
                              getOptionLabel={(option: CountryType) =>
                                option.label
                              }
                              value={formRules?.returnCountry}
                              isOptionEqualToValue={(option, selected) =>
                                option.value === selected.value
                              }
                              renderOption={(props, option) => (
                                <li
                                  data-name="shipFrom"
                                  data-state="country"
                                  {...props}
                                >
                                  {option.label}
                                </li>
                              )}
                              onChange={(event: any, newValue: CountryType) => {
                                if (newValue !== null) {
                                  setFormRules((e) => ({
                                    ...e,
                                    returnCountry: newValue,
                                  }));
                                } else {
                                  setFormRules((e) => ({
                                    ...e,
                                    returnCountry: null,
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete:
                                      autoCompleteTimeStamp().toString(),
                                  }}
                                  autoComplete="off"
                                  id="outlined-required"
                                  label="Country"
                                  size="small"
                                  sx={{ width: '100%', background: '#ffffff' }}
                                  required
                                />
                              )}
                            />
                          </MUIGrid>
                        </MUIGrid>
                      )}
                    </>
                  )}
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%' }}
                    mb={1}
                  >
                    <MUIGrid item xs={10}>
                      <Box sx={{ display: 'flex' }}>
                        <OrderRulesIcon name="defaultPackageIcon" />
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          mr={2}
                        >
                          Default Package Type
                        </Typography>
                      </Box>
                    </MUIGrid>

                    {IsOrderAIRules && (
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.defaultPackageTypeUseDefault}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              defaultPackageTypeUseDefault: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    )}
                    {!formRules?.defaultPackageTypeUseDefault && (
                      <MUIGrid item xs={12}>
                        <Autocomplete
                          options={defaultPackageOption}
                          value={formRules?.defaultPackageType}
                          onChange={(event: any, newValue: ISignatureType) => {
                            if (newValue !== null) {
                              setFormRules((e) => ({
                                ...e,
                                defaultPackageType: newValue,
                              }));
                            } else {
                              setFormRules((e) => ({
                                ...e,
                                defaultPackageType: null,
                              }));
                            }
                          }}
                          id="controllable-states"
                          getOptionLabel={(option: any) => option.label}
                          isOptionEqualToValue={(option, selected) =>
                            option.value === selected.value
                          }
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Box display="flex">
                                <Box display="flex" flexDirection="column">
                                  <Typography color="text.primary">
                                    {option.label}
                                  </Typography>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {option?.dimensions}
                                  </Typography>
                                </Box>
                              </Box>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              autoComplete="off"
                              id="outlined-required"
                              label="Default Package"
                              size="small"
                            />
                          )}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    )}
                  </MUIGrid>
                </Card>
              </MUIGrid>
              <MUIGrid
                item
                sm={12}
                md={6}
                sx={{ paddingLeft: '1% !important' }}
              >
                {IsOrderAIRules && (
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                      Use Client Defaults
                    </Typography>
                  </Box>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1" fontWeight="bold" mr={2}>
                    Orders
                  </Typography>
                  {IsOrderAIRules && (
                    <Switch
                      checked={formRules?.ordersUseDefault}
                      onChange={(v) =>
                        setFormRules((e) => ({
                          ...e,
                          ordersUseDefault: v.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={isViewForm}
                    />
                  )}
                </Box>
                {!formRules?.ordersUseDefault && (
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      border: '0.5px solid #ebeaea',
                      padding: '15px !important',
                      marginBottom: 2,
                    }}
                  >
                    {/* FIRST ROW */}
                    <MUIGrid
                      container
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%' }}
                    >
                      <MUIGrid item xs={10}>
                        <Box sx={{ display: 'flex' }}>
                          <OrderRulesIcon name="autoHoldIcon" />
                          <Typography variant="subtitle1" fontWeight="bold">
                            Auto-hold All Orders
                          </Typography>
                        </Box>
                      </MUIGrid>
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.autoholdAllOrders}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              autoholdAllOrders: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    </MUIGrid>
                    <MUIGrid
                      container
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%' }}
                    >
                      <MUIGrid item xs={10}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Auto-hold International Orders
                        </Typography>
                        <Typography variant="body2">
                          Set a dollar value to hold a new international order,
                          automatically, for review.
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.internationalOrders}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              internationalOrders: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                      {formRules?.internationalOrders && (
                        <MUIGrid item xs={4}>
                          <Input
                            size="small"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            placeholder="Set Dollar value"
                            type="number"
                            value={formRules?.internationalOrdersDollarValue}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              if (e.target.value < 0) {
                                e.target.value = 0;
                              }
                              const validateDecimal = decimalValidationHelper(
                                e.target.value,
                              );
                              setFormRules((prev) => ({
                                ...prev,
                                internationalOrdersDollarValue: validateDecimal,
                              }));
                            }}
                            disabled={isViewForm}
                          />
                        </MUIGrid>
                      )}
                    </MUIGrid>

                    <MUIGrid
                      container
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%', alignItems: 'center' }}
                    >
                      <MUIGrid item xs={5}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Default Order Type
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={7}>
                        <Autocomplete
                          disableClearable
                          disabled={isViewForm}
                          id="controllable-states"
                          options={defaultOrderTypes}
                          getOptionLabel={(option: CountryType) => option.label}
                          value={formRules?.defaultOrderType}
                          isOptionEqualToValue={(option, selected) =>
                            option.value === selected.value
                          }
                          renderOption={(props, option) => (
                            <li
                              data-name="defaultOrderType"
                              data-state="orderType"
                              {...props}
                            >
                              {option.label}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              autoComplete="off"
                              id="outlined-required"
                              size="small"
                              sx={{ width: '100%', background: '#ffffff' }}
                            />
                          )}
                          onChange={(event: any, newValue: CountryType) => {
                            if (newValue !== null) {
                              setFormRules((e) => ({
                                ...e,
                                defaultOrderType: newValue,
                              }));
                            }
                          }}
                        />
                      </MUIGrid>
                    </MUIGrid>
                  </Card>
                )}
                <Box sx={{ display: 'flex' }}>
                  <OrderRulesIcon name="returnOrdersIcon" />
                  <Box>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography variant="subtitle1" fontWeight="bold" mr={2}>
                        Return Orders
                      </Typography>

                      {IsOrderAIRules && (
                        <Switch
                          checked={formRules?.returnOrdersUseDefault}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              returnOrdersUseDefault: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      )}
                    </Box>

                    <Typography variant="body2" mb={1}>
                      Establish rules for client returns.
                    </Typography>
                  </Box>
                </Box>

                {!formRules?.returnOrdersUseDefault && (
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      border: '0.5px solid #ebeaea',
                      padding: '15px !important',
                      marginBottom: 2,
                      minHeight: '0px !important',
                    }}
                  >
                    <MUIGrid
                      container
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%', alignItems: 'center' }}
                    >
                      <MUIGrid item xs={10}>
                        <Box sx={{ display: 'flex' }}>
                          <OrderRulesIcon name="acceptReturnsIcon" />
                          <Typography variant="subtitle1" fontWeight="bold">
                            Accept Returns
                          </Typography>
                        </Box>
                      </MUIGrid>
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.acceptReturns}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              acceptReturns: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    </MUIGrid>
                    {formRules?.acceptReturns && (
                      <>
                        <MUIGrid
                          container
                          spacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ width: '100%' }}
                        >
                          <MUIGrid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="subtitle1" fontWeight="bold">
                              Max allowed return days
                            </Typography>
                            <TextField
                              size="small"
                              sx={{
                                width: '8% !important',
                                backgroundColor: '#ffffff !important',
                              }}
                              placeholder=""
                              value={formRules?.maxAllowedReturnDays}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                let validateDecimal = decimalValidationHelper(
                                  e.target.value,
                                );

                                if (Number(validateDecimal) < 0) {
                                  validateDecimal = '0';
                                }

                                setFormRules((prev) => ({
                                  ...prev,
                                  maxAllowedReturnDays: validateDecimal,
                                }));
                              }}
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                        </MUIGrid>
                        <MUIGrid
                          container
                          spacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ width: '100%', alignItems: 'center' }}
                        >
                          <MUIGrid item xs={10}>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Override Maximum return days
                            </Typography>
                          </MUIGrid>
                          <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                            <Switch
                              checked={formRules?.overrideMaximumReturnDays}
                              onChange={(v) =>
                                setFormRules((e) => ({
                                  ...e,
                                  overrideMaximumReturnDays: v.target.checked,
                                }))
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                        </MUIGrid>
                        <MUIGrid
                          container
                          spacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ width: '100%', alignItems: 'center' }}
                          mb={2}
                        >
                          <MUIGrid item xs={10}>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Accept receive in whole
                            </Typography>
                          </MUIGrid>
                          <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                            <Switch
                              checked={formRules?.acceptReceiveInWhole}
                              onChange={(v) =>
                                setFormRules((e) => ({
                                  ...e,
                                  acceptReceiveInWhole: v.target.checked,
                                }))
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                              disabled={isViewForm}
                            />
                          </MUIGrid>
                        </MUIGrid>
                      </>
                    )}
                  </Card>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex' }}>
                    <OrderRulesIcon name="itemsMappingIcon" />
                    <Typography variant="subtitle1" fontWeight="bold" mr={2}>
                      Item Mappings
                    </Typography>
                  </Box>
                  {IsOrderAIRules && (
                    <Switch
                      checked={formRules?.itemMappingUseDefault}
                      onChange={(v) =>
                        setFormRules((e) => ({
                          ...e,
                          itemMappingUseDefault: v.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={isViewForm}
                    />
                  )}
                </Box>

                {!formRules?.itemMappingUseDefault && (
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      border: '0.5px solid #ebeaea',
                      padding: '15px !important',
                      marginBottom: 2,
                      minHeight: '0px !important',
                    }}
                  >
                    <MUIGrid
                      container
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%', alignItems: 'center' }}
                    >
                      {isNewItemMapping ? (
                        <>
                          <MUIGrid item xs={4.5}>
                            <Autocomplete
                              disabled={isViewForm}
                              id="controllable-states"
                              options={skuMappingOptions}
                              getOptionLabel={(option: any) => option.sku}
                              value={itemMappingDetail}
                              isOptionEqualToValue={(option, selected) =>
                                option.value === selected.value
                              }
                              filterOptions={handleFilterOption}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  key={option?.itemId}
                                  data-description={option?.description}
                                  data-itemid={option?.itemId}
                                >
                                  <div className="td tw-140">{option?.sku}</div>
                                  <div className="td">
                                    {option?.description}
                                  </div>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  inputRef={setTextInputRef}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                  id="outlined-required"
                                  label="SKU"
                                  size="small"
                                  sx={{ width: '100%' }}
                                  {...(formItemMappingErrors.itemId && {
                                    error: true,
                                    helperText: formItemMappingErrors.itemId,
                                  })}
                                />
                              )}
                              PopperComponent={AutoCompletePopper}
                              PaperComponent={AutoCompletePaper}
                              onChange={(event: any, newValue: any) => {
                                if (newValue !== null) {
                                  setItemMappingDetail((e) => ({
                                    ...e,
                                    itemId: newValue.itemId,
                                    sku: newValue.sku,
                                  }));
                                } else {
                                  setItemMappingDetail((e) => ({
                                    ...e,
                                    itemId: null,
                                    sku: '',
                                  }));
                                }
                              }}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4.5}>
                            <TextField
                              size="small"
                              sx={{
                                width: '100%',
                                backgroundColor: '#ffffff !important',
                              }}
                              label="SKU Mapping"
                              value={itemMappingDetail?.skuMapping}
                              InputProps={{
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              onChange={(e) => {
                                setItemMappingDetail((prev) => ({
                                  ...prev,
                                  skuMapping: e.target.value,
                                }));
                              }}
                              disabled={isViewForm}
                              {...(formItemMappingErrors.skuMapping && {
                                error: true,
                                helperText: formItemMappingErrors.skuMapping,
                              })}
                            />
                          </MUIGrid>
                          <MUIGrid
                            item
                            xs={3}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-around',
                            }}
                          >
                            <Button
                              variant="outlined"
                              size="medium"
                              sx={{
                                borderColor: 'rgb(5, 47, 255)',
                                color: 'rgb(5, 47, 255)',
                                textTransform: 'capitalize',
                                margin: '0px 10px',
                              }}
                              onClick={() => {
                                handleCancelOnlickItemMapping();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              size="medium"
                              sx={{ textTransform: 'capitalize' }}
                              onClick={(e) => {
                                handleOnSaveEditItemMapping(e);
                              }}
                            >
                              Save
                            </Button>
                          </MUIGrid>
                        </>
                      ) : (
                        <MUIGrid item xs={12} sx={{ textAlign: ' right' }}>
                          <Button
                            variant="contained"
                            size="medium"
                            sx={{ width: '30%', textTransform: 'capitalize' }}
                            onClick={() => {
                              setIsNewItemMapping(true);
                              setItemMappingDetail((e) => ({
                                ...e,
                                dataGridId: Math.random(),
                              }));
                            }}
                          >
                            Add Mapping
                          </Button>
                        </MUIGrid>
                      )}
                      <MUIGrid item xs={12}>
                        <DatagridStandard
                          autoHeight
                          rows={formRules?.itemMappingSkus || []}
                          density="compact"
                          disableColumnFilter
                          disableColumnSelector
                          disableDensitySelector
                          disableColumnMenu
                          columns={skuMappingColumns}
                          rowsPerPageOptions={[5]}
                          pageSize={5}
                          hideFooterSelectedRowCount
                          getRowId={() => Math.random()}
                        />
                      </MUIGrid>
                    </MUIGrid>
                  </Card>
                )}
              </MUIGrid>
            </>
          )}
        </MUIGrid>
      )}

      {IsRulesManagementTab && (
        <MUIGrid item sm={12}>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: '20px', paddingBottom: '0px' }}
          >
            <MUIGrid item sm={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                Inbound Settings
              </Typography>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  border: '0.5px solid #ebeaea',
                  padding: '15px !important',
                }}
              >
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ width: '100%' }}
                  mb={1}
                >
                  <MUIGrid item xs={5}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Received To Location
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={7}>
                    <Autocomplete
                      onChange={(event: any, newValue: ISignatureType) => {
                        if (newValue !== null) {
                          setFormRules((e) => ({
                            ...e,
                            defaultReceivedToLocation: newValue,
                          }));
                        } else {
                          setFormRules((e) => ({
                            ...e,
                            defaultReceivedToLocation: null,
                          }));
                        }
                      }}
                      id="controllable-states"
                      options={optionLocation}
                      value={formRules?.defaultReceivedToLocation}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined-required"
                          label="Default received to location"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                      disabled={
                        isViewForm ||
                        (customerType === CustomerType.WarehouseCustomer &&
                          !handleUserPermissionAllow(
                            UserPermissionEnum.Client_Rules_Edit,
                          ))
                      }
                    />
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ width: '100%' }}
                >
                  <MUIGrid item xs={10}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Require Quality Control
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                    <Switch
                      checked={formRules?.requireQualityControl}
                      onChange={(v) =>
                        setFormRules((e) => ({
                          ...e,
                          requireQualityControl: v.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={
                        isViewForm ||
                        (customerType === CustomerType.WarehouseCustomer &&
                          !handleUserPermissionAllow(
                            UserPermissionEnum.Client_Rules_Edit,
                          ))
                      }
                    />
                  </MUIGrid>
                </MUIGrid>
                {formRules?.requireQualityControl && (
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%' }}
                  >
                    <MUIGrid item xs={12}>
                      <Autocomplete
                        multiple
                        size="small"
                        id="checkboxes-tags-demo"
                        options={itemsOption}
                        value={formRules?.qualityControlOnItems}
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        )}
                        onChange={(event: any, newValue) => {
                          if (newValue !== null) {
                            setFormRules((e) => ({
                              ...e,
                              qualityControlOnItems: newValue,
                            }));
                          } else {
                            setFormRules((e) => ({
                              ...e,
                              qualityControlOnItems: [],
                            }));
                          }
                        }}
                        sx={{ width: '100%' }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              '& .MuiInputBase-root': {
                                background: 'white',
                              },
                            }}
                            {...params}
                            label="Quality Control On Items"
                          />
                        )}
                        disabled={
                          isViewForm ||
                          (customerType === CustomerType.WarehouseCustomer &&
                            !handleUserPermissionAllow(
                              UserPermissionEnum.Client_Rules_Edit,
                            ))
                        }
                      />
                    </MUIGrid>
                  </MUIGrid>
                )}
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ width: '100%' }}
                >
                  <MUIGrid item xs={10}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Must Confirm Each Receipt Line Before Put Away
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                    <Switch
                      checked={formRules?.isConfirmReceiptLineItem}
                      onChange={(v) =>
                        setFormRules((e) => ({
                          ...e,
                          isConfirmReceiptLineItem: v.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={
                        isViewForm ||
                        (customerType === CustomerType.WarehouseCustomer &&
                          !handleUserPermissionAllow(
                            UserPermissionEnum.Client_Rules_Edit,
                          ))
                      }
                    />
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ width: '100%' }}
                >
                  <MUIGrid item xs={10}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Allow Multiple Pallets In One Location
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                    <Switch
                      checked={formRules?.isAllowMultiplePalletsInOneLocation}
                      onChange={(v) =>
                        setFormRules((e) => ({
                          ...e,
                          isAllowMultiplePalletsInOneLocation: v.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={
                        isViewForm ||
                        (customerType === CustomerType.WarehouseCustomer &&
                          !handleUserPermissionAllow(
                            UserPermissionEnum.Client_Rules_Edit,
                          ))
                      }
                    />
                  </MUIGrid>
                </MUIGrid>
              </Card>
              <Typography variant="subtitle1" fontWeight="bold" mb={1} mt={1}>
                Inventory
              </Typography>

              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  border: '0.5px solid #ebeaea',
                  padding: '15px !important',
                  minHeight: '0px !important',
                }}
              >
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ width: '100%', alignItems: 'center' }}
                >
                  <MUIGrid item xs={10}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Send Alert When Low Inventory
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                    <Switch
                      checked={formRules?.isSendAlertLowInventory}
                      onChange={(v) =>
                        setFormRules((e) => ({
                          ...e,
                          isSendAlertLowInventory: v.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={
                        isViewForm ||
                        (customerType === CustomerType.WarehouseCustomer &&
                          !handleUserPermissionAllow(
                            UserPermissionEnum.Client_Rules_Edit,
                          ))
                      }
                    />
                  </MUIGrid>
                </MUIGrid>

                {formRules?.isSendAlertLowInventory && (
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%', alignItems: 'center' }}
                  >
                    <MUIGrid item xs={4}>
                      <TextField
                        size="small"
                        label="Email"
                        value={formRules?.alertPrimaryEmail}
                        name="alertPrimaryEmail"
                        InputProps={{
                          autoComplete: 'off',
                        }}
                        onChange={(v) =>
                          setFormRules((prev) => ({
                            ...prev,
                            alertPrimaryEmail: v.target.value,
                          }))
                        }
                        required
                        {...(formErrors?.alertPrimaryEmail && {
                          error: true,
                          helperText: formErrors?.alertPrimaryEmail,
                        })}
                        {...(formErrors?.alertPrimaryEmail && {
                          error: true,
                          helperText: formErrors?.alertPrimaryEmail,
                        })}
                        sx={{ marginRight: '10px' }}
                        disabled={
                          isViewForm ||
                          (customerType === CustomerType.WarehouseCustomer &&
                            !handleUserPermissionAllow(
                              UserPermissionEnum.Client_Rules_Edit,
                            ))
                        }
                      />
                    </MUIGrid>
                    <MUIGrid item xs={8}>
                      <TextField
                        size="small"
                        label="Emails CC"
                        value={formRules?.alertEmailsCC}
                        name="alertEmailsCC"
                        InputProps={{
                          autoComplete: 'off',
                        }}
                        onChange={(v) =>
                          setFormRules((prev) => ({
                            ...prev,
                            alertEmailsCC: v.target.value,
                          }))
                        }
                        sx={{ width: '100%' }}
                        disabled={
                          isViewForm ||
                          (customerType === CustomerType.WarehouseCustomer &&
                            !handleUserPermissionAllow(
                              UserPermissionEnum.Client_Rules_Edit,
                            ))
                        }
                      />
                    </MUIGrid>
                  </MUIGrid>
                )}

                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ width: '100%', alignItems: 'center' }}
                >
                  <MUIGrid item xs={10}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Auto-Create Purchase Order when Low Inventory
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                    <Switch
                      checked={formRules?.isAutoCreatePurchaseOrderLowInventory}
                      onChange={(v) =>
                        setFormRules((e) => ({
                          ...e,
                          isAutoCreatePurchaseOrderLowInventory:
                            v.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={
                        isViewForm ||
                        (customerType === CustomerType.WarehouseCustomer &&
                          !handleUserPermissionAllow(
                            UserPermissionEnum.Client_Rules_Edit,
                          ))
                      }
                    />
                  </MUIGrid>
                </MUIGrid>
              </Card>
            </MUIGrid>
            <MUIGrid item sm={12} md={6}>
              <>
                {customerType === CustomerType.Customer && (
                  <MUIGrid item xs={12} sx={{ paddingBottom: '20px' }}>
                    <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                      Shipping Integration
                    </Typography>
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        border: '0.5px solid #ebeaea',
                        padding: '15px !important',
                      }}
                    >
                      <MUIGrid
                        container
                        spacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ width: '100%', alignItems: 'center' }}
                      >
                        <MUIGrid item xs={5}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Integration Method
                          </Typography>
                        </MUIGrid>
                        <MUIGrid item xs={7}>
                          <Autocomplete
                            onChange={(
                              event: any,
                              newValue: ISignatureType,
                            ) => {
                              if (newValue !== null) {
                                setFormRules((e) => ({
                                  ...e,
                                  shippingIntegration: newValue,
                                }));
                              } else {
                                setFormRules((e) => ({
                                  ...e,
                                  shippingIntegration: null,
                                }));
                              }
                            }}
                            id="controllable-states"
                            options={optionShippingIntegration}
                            value={formRules?.shippingIntegration}
                            isOptionEqualToValue={(option, selected) =>
                              option.value === selected.value
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option.value}>
                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete:
                                    autoCompleteTimeStamp().toString(),
                                }}
                                autoComplete="off"
                                id="outlined-required"
                                label="Integration Method"
                                size="small"
                                sx={{ width: '100%' }}
                              />
                            )}
                            disabled={isViewForm}
                          />
                        </MUIGrid>
                      </MUIGrid>
                    </Card>
                  </MUIGrid>
                )}

                <MUIGrid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                    Outbound Settings
                  </Typography>

                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      border: '0.5px solid #ebeaea',
                      padding: '15px !important',
                    }}
                  >
                    {/* FIRST ROW */}
                    <MUIGrid
                      container
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%', alignItems: 'center' }}
                    >
                      <MUIGrid item xs={10}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Require Approval Before Picking
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.requireApprovalBeforePicking}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              requireApprovalBeforePicking: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={
                            isViewForm ||
                            (customerType === CustomerType.WarehouseCustomer &&
                              !handleUserPermissionAllow(
                                UserPermissionEnum.Client_Rules_Edit,
                              ))
                          }
                        />
                      </MUIGrid>
                      <MUIGrid item xs={10}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Pack And Ship Require Packing Slip
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.packAndShipRequirePackingSlip}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              packAndShipRequirePackingSlip: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={
                            isViewForm ||
                            (customerType === CustomerType.WarehouseCustomer &&
                              !handleUserPermissionAllow(
                                UserPermissionEnum.Client_Rules_Edit,
                              ))
                          }
                        />
                      </MUIGrid>
                      <MUIGrid item xs={10}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Complete Orders After Pick Approval
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.completeOrdersAfterPickApproval}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              completeOrdersAfterPickApproval: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={
                            isViewForm ||
                            (customerType === CustomerType.WarehouseCustomer &&
                              !handleUserPermissionAllow(
                                UserPermissionEnum.Client_Rules_Edit,
                              ))
                          }
                        />
                      </MUIGrid>
                      <MUIGrid item xs={10}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Is Using Tote
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={2} sx={{ textAlign: 'right' }}>
                        <Switch
                          checked={formRules?.isUsingTote}
                          onChange={(v) =>
                            setFormRules((e) => ({
                              ...e,
                              isUsingTote: v.target.checked,
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={
                            isViewForm ||
                            (customerType === CustomerType.WarehouseCustomer &&
                              !handleUserPermissionAllow(
                                UserPermissionEnum.Client_Rules_Edit,
                              ))
                          }
                        />
                      </MUIGrid>
                      {formRules?.isUsingTote && (
                        <MUIGrid item xs={8}>
                          <Input
                            size="small"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            placeholder="No. of totes"
                            type="number"
                            value={formRules?.noOfTotes}
                            onChange={(v) =>
                              setFormRules((e) => ({
                                ...e,
                                noOfTotes: v.target.value,
                              }))
                            }
                            disabled={
                              isViewForm ||
                              (customerType ===
                                CustomerType.WarehouseCustomer &&
                                !handleUserPermissionAllow(
                                  UserPermissionEnum.Client_Rules_Edit,
                                ))
                            }
                          />
                        </MUIGrid>
                      )}
                      <MUIGrid item xs={5}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Lock Order Edits After
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={7}>
                        <Autocomplete
                          disableClearable
                          value={formRules?.lockOrderEditsAfter}
                          onChange={(
                            event: any,
                            newValue: DropdownListItem,
                          ) => {
                            if (newValue !== null) {
                              setFormRules((e) => ({
                                ...e,
                                lockOrderEditsAfter: newValue,
                              }));
                            } else {
                              setFormRules((e) => ({
                                ...e,
                                lockOrderEditsAfter: null,
                              }));
                            }
                          }}
                          id="controllable-states"
                          options={lockOrderEditsAfterDDL}
                          getOptionLabel={(option: DropdownListItem) =>
                            option.text
                          }
                          isOptionEqualToValue={(option, selected) =>
                            option.value === selected.value
                          }
                          renderOption={(props, option: DropdownListItem) => (
                            <li {...props} key={option.value}>
                              {option.text}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              autoComplete="off"
                              id="outlined-required"
                              label="Lock Order Edits After"
                              size="small"
                              sx={{ width: '100%' }}
                            />
                          )}
                          disabled={isViewForm}
                        />
                      </MUIGrid>
                    </MUIGrid>
                  </Card>
                </MUIGrid>
              </>
            </MUIGrid>

            {customerType === CustomerType.Customer && (
              <MUIGrid item sm={12} marginBottom={2}>
                <Button
                  sx={{ display: 'flex', width: '10%' }}
                  variant="contained"
                  size="small"
                  onClick={() => handleSaveUpdateCustomerRulesManagement()}
                >
                  SAVE
                </Button>
              </MUIGrid>
            )}

            <MUIGrid item sm={12}>
              <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                Custom Rules
              </Typography>

              <OrderProcessingRulesDetailsWithoutHeader
                customerType={customerType}
              />
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
      )}
    </div>
  );
};
